<template>
  <html>
  <div class="map" id="map_container">
  </div>

  <div id="bigdiov">
    <el-select filterable v-model="deviceTypeChecked" placeholder="请选择设备类型" class="serachinput" collapse-tags multiple @change="checkedValue($event)">
      <el-option v-for="item in deviceType" :label="item.name" :value="item.id">
      </el-option>
    </el-select>
    <!-- </bm-control> -->
    <!-- </baidu-map> -->
    <el-dialog :title="staticAttributes.typeName" :visible.sync="dialogVisible" width="35%">
      <div style="height: 35vh; overflow: scroll;">
        <el-tabs v-model="activeName">
          <!-- 静态设备属性 -->
          <el-tab-pane label="基本信息" name="first">
            <!-- <dv-decoration-2 style="width:100%;height:2px;" /> -->
            <!-- <dv-decoration-10
              style="width: 100%; height: 5px; margin-bottom: 10px"
            /> -->
            <div class="attributeOfstatic">
              <div class="content1">设备编号 ：</div>
              <div class="content2">{{ staticAttributes.id }}</div>
            </div>

            <div class="attributeOfstatic">
              <div class="valueBox1">
                <div v-for="item in staticAttributesName">
                  {{ item=="绑定公司"?"所属单位 ：":item +' ：' }}
                </div>
              </div>
              <div class="valueBox2">
                <div v-for="item in staticAttributesValue">
                  {{ item}}
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 动态设备属性 -->
          <el-tab-pane label="设备详情" name="second">

            <div v-if="port == '智慧灭火控制终端'" class="device-cont">
              <div class="btn-box">
                <el-button type="primary" size="small">复 位</el-button>
                <el-button type="primary" size="small">消 音</el-button>
              </div>

              <div v-for="item in deeviceList" class="dev-box" @click="deviceItem(item)">
                <div class="device-img">
                  <img src="@/assets/img/device.png" alt="">
                  <!-- <i class="el-icon-coin device-img"></i> -->
                </div>
                <div class="device-name">
                  <div>{{item.name}}</div>
                  <el-tag :title="item.aaa" :class="item.status=='启动'?'tag':''" @click="deviceStatus(item)" effect="dark" size="mini" :type="item.status=='正常'?'success':item.status=='故障'?'warning':item.status=='报警'?'danger':''">{{item.status}}</el-tag>
                </div>
              </div>

            </div>

            <div v-else v-for="(item, index) in dynamicProperties">
              <span class="item-box1">{{ item.id }}：</span>
              <span class="item-box2">{{
                  item.value == "" ? "正常" : item.value
                }}</span>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

  </div>

  </html>

</template>
<script>
import jQuery from "jquery";
import BaseData from "@/assets/config/BaseData";
import deviceTypeAboutMap from "@/api/manageApi/DeviceTypeAboutMap.js";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
// import styleJson from "../../../../utils/styleJson";
import deviceTypeInfo from "@/api/manageApi/DeviceTypeManagement";
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  components: {},
  data() {
    return {
      isDetails: false,
      activeName: "first",

      deeviceList: [{
        name: '设备2',
        status: '故障',
        aaa: '45464',
      }, {
        name: '设备3',
        status: '报警',
        aaa: '45464',
      }, {
        name: '设备4',
        status: '故障',
      }, {
        name: '设备5',
        status: '报警',
      }, {
        name: '设备6',
        status: '正常',
      }, {
        name: '设备9',
        status: '故障',
      }],



      locationAddress: "北京市",
      staticAttributesName: [],
      staticAttributesValue: [],
      port: "",
      marker: "",
      baseUrl: BaseData.baseUrl,
      center: { lng: 117.40028, lat: 38.992233 },
      companyNameById: {},
      systemNameById: {},

      dialogVisible: false,
      zoom: 15,
      // 所有设备类型
      deviceType: [],
      deviceTypeChecked: "",
      // 坐标点
      markersOfDevice: [],
      // 坐标对应的设备 id
      deviceId: "",
      typeId: "",

      // 地图风格
    //   mapStyle: styleJson,

      // 静态属性
      staticAttributes: [],
      // 动态属性
      dynamicProperties: [],
      dynamicProperties1: [], // 设备详情显示

      deviceInfoDate2: [],
      map: {},
      view: {},
      deviceiconUrlDate: [],
      layerDeviceIcon: {},
      iconBaseUrl: BaseData.baseUrl,
      jishu: 0,
      qkjishu: false,
    };
  },
  created() {
    this.getAddedDeviceInfoList();
    this.queryMapsEquipment();
  },

  methods: {
    // 点击的当前设备
    deviceItem(item) {
      this.isDetails = true;
      console.log(item.name);

    },
    onclicMap() {
      var that = this;
      

      // --- 添加行政区划 ---
      var dist = new BMapGL.DistrictLayer({
          
        name: "(" + this.locationAddress + ")",
        kind: 1,
        fillColor: "#618bf8",
        fillOpacity: 1,
        strokeColor: "#daeafa",
        viewport: true,
      });
      this.map.addDistrictLayer(dist);

      // --- 行政区划添加鼠标事件 ---
      dist.addEventListener("mouseover", function (e) {
        e.currentTarget.setFillColor("#9169db");
      });
      dist.addEventListener("mouseout", function (e) {
        e.currentTarget.setFillColor(e.currentTarget.style.fillColor);
      });
      dist.addEventListener("onclick", function (e) {
        this.map.clearOverlays();
        this.map.panTo(e.latLng);
        that.requestLocal(e.latLng);
      });
    },

    // 恢复地图三级
    mapHuifu(e) {
      var that = this;
      this.jishu = 0;
      this.map.clearOverlays();
      this.map.panTo(e["latlng"]);
      that.requestLocal(e["latlng"]);
    },
    requestLocal(lola) {
      let that = this;
      jQuery.ajax({
        method: "get",
        async: "true",
        url:
          "https://api.map.baidu.com/geoconv/v1/?coords=" +
          lola.lng +
          "," +
          lola.lat +
          "&from=5&to=6&ak=KxdYSrrPawChQDBqYjxuqNOyISTVfgO2" +
          "&callback=callback",
        dataType: "jsonp",
        jsonp: "callbackparam",
        jsonpCallback: "callback",
        success: (data) => {
          jQuery.ajax({
            method: "get",
            async: "true",
            url:
              "https://api.map.baidu.com/?qt=rgc&x=" +
              data.result[0].x +
              "&y=" +
              data.result[0].y +
              "&dis_poi=100&poi_num=10&latest_admin=1&ie=utf-8&oue=1&fromproduct=jsapi&v=2.1&res=api&callback=BMap._rd._cbk6105&ak=E4805d16520de693a3fe707cdc962045&seckey=8aL93eTx4i6iqfzMcAwh5QC7EIvLlCsR5jTglV0OElk%3D%2CTQTqqpNJH_7rVugmpO_geAP4OUF1qax3myIrwTKywkHSFO08TXS1sXJa2zKkfJMevNwE3oCDFzwp2U5b-tSml8ZTkc3Cla1BbsSiy0JRyhZRb6JfJrY4cIM7ZQBISvuJzTLQESBvFdjF-1IoGldpD9MJNTAePfy4H_yy1jmTyAGJSMAWU1OKSzM5iB65SdqZ" +
              "&callback=callback",
            dataType: "jsonp",
            jsonp: "callbackparam",
            jsonpCallback: "callback",
            success: (data) => {
              this.jishu = this.jishu + 1;

              if (this.jishu == 1) {
                that.locationAddress = data.content.address_detail.province;
              } else if (this.jishu == 2) {
                that.locationAddress = data.content.address_detail.city;
              } else {
                that.locationAddress = data.content.address_detail.district;
              }
              this.onclicMap();
            },
          });
        },
      });
    },

    initMap() {
      var that = this;

      this.map = initMap({
        tilt: 0,
        heading: 0,
        center: [109.792816, 27.702774],
        zoom: 5,
        style: mystyle6,
        skyColors: [
          // 地面颜色
          "rgba(226, 237, 248, 0)",
          // 天空颜色
          "rgba(186, 211, 252, 1)",
        ],
      });

      this.map.addEventListener("rightclick", function (e) {
        that.mapHuifu(e);
      });

      this.view = new mapvgl.View({
        map: this.map,
      });

      // 刚加载对话框的数据 现在加载图标数据
      this.layerDeviceIcon = new mapvgl.ClusterLayer({
        minSize: 25,
        maxSize: 40,
        clusterRadius: 200,
        showText: true,
        maxZoom: 19,
        minZoom: 4,
        gradient: {
          0.0: "rgb(50, 50, 256)",
          0.1: "rgb(50, 250, 56)",
          0.5: "rgb(250, 250, 56)",
          1.0: "rgb(250, 50, 56)",
        },
        textOptions: {
          fontSize: 12,
          color: "white",
          format: function (count) {
            return count;
          },
        },
        iconOptions: {
          width: 30,
          height: 30,
          icon: "images/icon.png",
        },
        enablePicked: true,
        onClick: (e) => {
          // 点击事件
          if (
            e.dataItem != null &&
            e.dataItem.geometry.type == "Point" &&
            e.dataItem.properties != undefined &&
            e.dataItem.properties.deviceId != null
          ) {
            this.markerClicked({
              typeName: e.dataItem.properties.typeName,
              deviceId: e.dataItem.properties.deviceId,
              typeId: e.dataItem.properties.typeId,
            });
          }
        },
      });
      this.view.addLayer(this.layerDeviceIcon);
      this.layerDeviceIcon.setData(this.deviceiconUrlDate);
    },

    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {};
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName) {
          this.systemNameById[this.permissionsSystemName[i].id] =
            this.permissionsSystemName[i].bigSystemName;
        }
      });
    },

    // 查询设备信息
    getAddedDeviceInfoList() {
      // 设备类型
      deviceTypeInfo.getDeviceTypeList(1, 999, { name: "" }).then((res) => {
        this.deviceType = res.data.data.rows;
      });
    },

    // 查询所有设备
    queryMapsEquipment() {
      deviceTypeAboutMap.getAllDeviceTypeAboutMap().then((res) => {
        this.markersOfDevice = res.data.data.list;
        console.log(this.markersOfDevice);
        for (let i in this.markersOfDevice) {
          if (i == 0) {
            this.requestLocal({
              lng: this.markersOfDevice[i].lng,
              lat: this.markersOfDevice[i].lat,
            });
          }
          this.deviceiconUrlDate.push({
            geometry: {
              type: "Point",
              coordinates: [
                this.markersOfDevice[i].lng,
                this.markersOfDevice[i].lat,
              ],
            },
            properties: {
              icon: this.iconBaseUrl + this.markersOfDevice[i]["iconurl"],
              deviceId: this.markersOfDevice[i]["deviceId"],
              typeName: this.markersOfDevice[i]["typeName"],
              typeId: this.markersOfDevice[i]["typeId"],
            },
          });
        }

        this.initMap();
      });
    },

    // 选择设备类型
    checkedValue(item) {
        console.log(item);
      this.markersOfDevice = [];
      if (item.length == 0) {
        this.queryMapsEquipment();
      }
      deviceTypeAboutMap.getDeviceTypeAboutMap(item).then((res) => {
        this.markersOfDevice = res.data.data.list;
      });
    },

    // 点击坐标弹出对应 id
    markerClicked(marker) {
      console.log(marker);
      this.port = marker.typeName;
      this.deviceId = marker.deviceId;
      this.typeId = marker.typeId;

      // 静态属性
      deviceInfoManagement.getDeviceInfoById(this.deviceId).then((res) => {
        this.staticAttributes = res.data.data.deviceInfoVo;

        this.staticAttributesName = [];
        this.staticAttributesValue = [];

        for (let i = 0; i < this.staticAttributes.name.length; i++) {
          if (this.staticAttributes.name[i] == "安装位置") {
            this.staticAttributesName.push(this.staticAttributes.name[i]);
            this.staticAttributesValue.push(this.staticAttributes.value[i]);
          }
        }
        // this.staticAttributesName = this.staticAttributes.name;
        // this.staticAttributesValue = this.staticAttributes.value[0];
      });
      // 动态属性
      deviceInfoManagement
        .getDeviceInfoValueByDeviceId(this.deviceId)
        .then((res) => {
          this.dynamicProperties = res.data.data.list;

          this.dynamicProperties1 = [];
          if (this.staticAttributes.typeName == "智慧灭火控制终端") {
            let ygList = [
              { name: "火警", color: "1" },
              { name: "故障", color: "1" },
            ];
            let sbList = [
              { name: "报警", color: "1" },
              { name: "故障", color: "1" },
            ];
            let sgList = [{ name: "故障", color: "1" }];
            let typeMap = new Map();
            typeMap.set("烟感", ygList);
            typeMap.set("温感", ygList);
            typeMap.set("手报", sbList);
            typeMap.set("输出", sgList);
            typeMap.set("声光", sgList);

            for (let i = 1; i < 9; i++) {
              let deviceType = "";
              let deviceStatus = "";
              for (let j = 0; j < this.dynamicProperties.length; j++) {
                if (this.dynamicProperties[j].id == i + "号设备类型") {
                  deviceType = this.dynamicProperties[j].value;
                }
                if (this.dynamicProperties[j].id == i + "号设备状态") {
                  deviceStatus = this.dynamicProperties[j].value;
                }
              }
              if (
                deviceType != "" &&
                deviceStatus != "" &&
                deviceType != null &&
                deviceStatus != null
              ) {
                let a = JSON.parse(JSON.stringify(typeMap.get(deviceType)));
                for (const i in typeMap.get(deviceType)) {
                  if (
                    (typeMap.get(deviceType)[i].name == "报警" ||
                      typeMap.get(deviceType)[i].name == "火警") &&
                    deviceStatus.indexOf("警") != -1 &&
                    deviceStatus.indexOf("解除") == -1
                  ) {
                    a[i].color = 2;
                  } else if (
                    typeMap.get(deviceType)[i].name == "故障" &&
                    deviceStatus.indexOf("故障") != -1 &&
                    deviceStatus.indexOf("解除") == -1
                  ) {
                    a[i].color = 3;
                  }
                }
                let obj = {};
                obj.name = i + "号" + deviceType;
                obj.value = a;
                this.dynamicProperties1.push(obj);
              }
            }
          }
        });
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100vw;
  height: 100vh;
}
.serachinput {
  position: absolute;
  top: 10vh;
  left: 27vw;
  z-index: 999;
  width: 280px;
  //   margin: 10vh 0 0 27vw;
}

// .container-box {
//   font-size: 16px;
//   // margin: 15px;
//   padding: 0 10px 30px 10px;
//   // border-bottom: 1px dashed #ccc;
//   color: #73e5bf;
// }
// .content {
//   width: 100%;

//   .con-title {
//     width: 100%;
//     text-align: center;
//     font-size: 18px;
//     color: #73e5bf;
//     letter-spacing: 2px;
//   }

//   .con-status {
//     width: 85%;
//     font-size: 16px;
//     text-align: center;
//     color: #73e5bf;

//     .item {
//       display: inline-block;
//       width: 16px;
//       height: 16px;
//       margin-left: 10px;
//       line-height: 30px;
//       border-radius: 50%;
//     }
//   }
//   .green {
//     background: rgb(11, 248, 11);
//   }
//   .red {
//     background: rgb(247, 24, 24);
//   }
//   .orange {
//     background: rgb(253, 216, 4);
//   }
// }

// 状态描述
// .describe {
//   width: 38%;
//   height: 30px;
//   line-height: 30px;
//   // box-shadow: -1px -1px 1px #053123;
//   // overflow: hidden;
//   box-sizing: border-box;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   color: #fff;
//   padding-left: 10px;
//   .cycle {
//     display: inline-block;
//     width: 10px;
//     height: 10px;
//     font-size: 18px;
//     margin-right: 3px;
//     border-radius: 50%;
//   }
//   .cycle:nth-child(1) {
//     background: rgb(11, 248, 11);
//   }
//   .cycle:nth-child(2) {
//     background: rgb(253, 216, 4);
//   }
//   .cycle:nth-child(3) {
//     background: rgb(247, 24, 24);
//   }
// }
.item-box1 {
  display: inline-block;
  width: 35%;
  padding: 10px 0;
  font-size: 16px;
  color: #73e5bf;
}
.item-box2 {
  display: inline-block;
  padding: 10px 0;
  width: 65%;
  font-size: 16px;
  color: #73e5bf;
}

/deep/.anchorBL {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

/deep/.el-dialog,
.el-pager li {
  background: rgba(0, 0, 0, 0.7) !important;
  background-image: url(../../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
}
/deep/.el-dialog__title {
  color: rgb(175, 238, 247);
  letter-spacing: 3px;
  font-size: 22px;
  // display: none;
}
// .location {
//   width: 100%;
//   text-align: center;
//   .item2 {
//     color: #73e5bf;
//     font-size: 16px;
//     text-align: center;
//     font-weight: 500;
//     text-align: center;
//   }
// }
// .device-title {
//   width: 100%;
//   padding: 10px 0;
//   font-size: 22px;
//   color: rgb(175, 238, 247);
//   position: absolute;
//   top: 0px;
//   text-align: center;
// }

// ***********************
// 静态属性样式
.attributeOfstatic {
  display: flex;
  padding: 0 20px;

  .content1 {
    width: 35%;
    font-size: 18px;
    padding: 10px 0px;
    color: #73e5bf;
  }
  .content2 {
    width: 65%;
    font-size: 18px;
    color: #73e5bf;
    padding: 10px 5px;
  }

  .valueBox1 {
    font-size: 18px;
    width: 35%;
    color: #73e5bf;
    div {
      padding: 10px 0;
    }
  }
  .valueBox2 {
    font-size: 18px;
    width: 65%;
    color: #73e5bf;
    div {
      padding: 10px 0;
    }
  }
}
.device-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .btn-box {
    width: 100%;
    text-align: right;
    margin: 0% 8% 3% 0;
    //   border: 1px solid red;
    // position: absolute;
    // bottom: 0px;
    // right: 0px;
  }
  .dev-box {
    width: 30%;
    cursor: pointer;

    // border: 1px solid red;
    box-sizing: border-box;
    margin: 10px 10px 10px 10px;
    display: flex;
    // border: 1px solid pink;
    padding: 10px;
    // background: rgba(69, 101, 124, 0.8);
    border-radius: 5px;

    .device-img img {
      width: 60px;
      height: 65px;
      margin: 0 15px;
    }

    .device-name {
      div {
        color: #fff;
        font-size: 16px;
        margin: 10px 0 10px 0;
      }
      .tag {
        cursor: pointer;
        // width: 60px;
        // height: 30px;
        // box-shadow: 2px 2px #aaa;
        // background: #efefef;
        // background: rgb(173, 243, 166);
        // letter-spacing: 1px;
        // border: none;
        // color: #000;
      }
      .tag:active {
        // box-shadow: 2px 2px #666;
        background: #fff;
        color: #000;
      }
    }
  }
}
/deep/img {
  width: 45px;
  height: 45px;
}
/deep/.el-dialog__body {
  padding: 0px 20px;
}
/deep/.el-tabs__item.is-active {
  font-size: 20px;
  color: rgb(73, 243, 220);
}
/deep/.el-tabs__item {
  font-size: 20px;
  color: rgb(180, 230, 238);
  height: 35px;
  line-height: 30px;
}
/deep/.el-tabs__nav-wrap::after {
  background: none;
}
/deep/.el-tabs__active-bar {
  background: rgba(16, 215, 241, 0.7);
  // display: none;
}

// .details {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 100px;
//   height: 100px;
//   background: red;

//   z-index: 9999;
// }
/deep/.el-drawer{
  background: rgba(0,0,0,0.5);
    //   background: rgba(0, 0, 0, 0.7) !important;
  background-image: url(../../../assets/images/panel.png) !important;
  background-size: 100% 100% !important;
}
/deep/.el-drawer.btt, .el-drawer.ttb{
    width: 40%;
    left: 30%;
    height: 35% !important;
}
/deep/.el-drawer__header{
    font-size: 24px;
    color: #fff;

}
</style>
<template>
  <div id="aapp">
    <!-- <div class="rc1-header">设备分类</div> -->
    <div class="rc1-chart-container">
      <dv-capsule-chart class="right" :config="config" />
    </div>
  </div>

</template>

<script>
import deviceCachValue from "@/api/manageApi/DeviceCachValue";

export default {
  name: "RightChart1",
  props: ['alarmList'],
  data() {
    return {
      config: {
        data: [{
          name: "报警",
          value: this.alarmList[0].value,
        },
        {
          name: "故障",
          value: this.alarmList[1].value,
        },
        {
          name: "失联",
          value: this.alarmList[2].value,
        }
          // , {
          //   name: "其它设备",
          //   value: 4,
          // }
        ],
        unit: "个",
      },
    };
  },
  created() {

    console.log(this.alarmList);
    
  },
  mounted() {
    this.getDevicenumber();
  },
  methods: {
    async getDevicenumber() {
      const { data: res } = await deviceCachValue.getDevicenumber()
      console.log(res);
      this.config.data[0].value = res.data.YGNumber;
      this.config.data[1].value = res.data.HZNumber;
      this.config.data[2].value = res.data.SPNumber;
      this.config.data[3].value = res.data.QTNumber;
      console.log(this.config);
    },
  }
};
</script>

<style lang="less">
.rc1-header {
  //   padding: 6% 0 2% 4%;
  //   font-size: 18px;
  //   color: aqua;
}
.rc1-chart-container {
  margin: 20px 0 0 0;
  height: 250px;
  //   flex: 1;
  //   display: flex;
}
.number {
  //   font-size: 34px;
  //   color: #096dd9;
  //   font-weight: bold;
  //   margin-bottom: 30px;
}
.right {
  //   flex: 1;
  //   padding-bottom: 20px;
  //   padding-right: 20px;
  //   box-sizing: border-box;
}
.dv-capsule-chart .label-column div {
  height: 35px;
  line-height: 35px;
  margin-left: 40px;
  font-size: 18px;
  color: rgb(167, 240, 215);
}
</style>
import * as THREE from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js'

import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';

import {
  CSS2LabelRenderer
} from '@/components/three/threejs/CSS2DRenderer.js'

// import {
//   RGBELoader
// } from "three/examples/jsm/loaders/RGBELoader"

// 加载进度条
import {
  ColladaLoader
} from 'three/examples/jsm/loaders/ColladaLoader.js';

// import {
//   CSS2LabelRenderer
// } from './CSS2DRenderer.js'

// 导入动画库
import gsap from "gsap"

// 导入 dat.gui
// import * as dat from "dat.gui"





// // 加载文件
// let loaderFile = new ColladaLoader();
// // 加载器加载模型文件
// loaderFile.load('./three/image/村庄.hdr', onLoad, onProgress);
// // // 加载完成的回调函数
// function onLoad(Object3D) {
//   // 加载器解析文件
//   scene.add(Object3D)
// }
// // 加载过程回调函数-可以获得加载进度
// function onProgress(xhr) {
//   // 后台打印查看模型文件加载进度

//   // console.log('加载完成的百分比' + (xhr.loaded / xhr.total * 100) + '%');
//   let loadNum = null
//   loadNum = (xhr.loaded / xhr.total * 100).toFixed(2) + '%'
//   return loadNum
// }

// let loadNum = null
// let loaderFile = new ColladaLoader();
// loaderFile.load('three/image/rold.hdr', Object3D => {
//   scene.add(Object3D)
// }, xhr => {
//   console.log('加载完成的百分比' + (xhr.loaded / xhr.total * 100) + '%');
//   loadNum = (xhr.loaded / xhr.total * 100).toFixed(2) + '%'
//   return loadNum
// })
// console.log(loaderFile);

// 加载 hdr 环境
// const rgbaLoader = new RGBELoader()
// rgbaLoader.loadAsync('three/image/rold.hdr').then((texture) => {
//   texture.mapping = THREE.EquirectangularReflectionMapping
//   scene.background = texture
//   scene.environment = texture // 物体折射环境
//   render()
// })


// 1、初始化场景
const scene = new THREE.Scene();

// const camera = new THREE.PerspectiveCamera(
//   75,
//   window.innerWidth / window.innerHeight,
//   0.1,
//   10000
// );
const camera = new THREE.PerspectiveCamera(
  70,
  window.innerWidth / window.innerHeight,
  0.1,
  100000
);


// // 3、设置相机位置  (x, y, z)
// let cameraPosition = 

// camera.position.set(-120, 50, 100)
// camera.position.set(-300, 50, -10)

// scene.add(cameraPosition);

// let mesh = null;
// let group = new THREE.Group()
// // 导入 3D 模型
// var loader = new GLTFLoader();
// loader.load('three/mesh/六公司(1).glb', gltf => {
//   // console.log(gltf);
//   mesh = gltf.scene.children

//   group.add(gltf.scene)
//   scene.add(gltf.scene);
// })

//

// // 设置环境光
const light = new THREE.AmbientLight(0xffffff, 0.1); // soft white light
scene.add(light);
// // 直线光  
const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
// directionalLight.position.set(10, 10, 10)
directionalLight.position.set(-120, 100, 100)
scene.add(directionalLight);


// 初始化渲染器
const renderer = new THREE.WebGLRenderer({
  antialias: true, //抗锯齿
  logarithmicDepthBuffer:true,//对数深度缓冲区域

});
// 开启HiDPI设置
// renderer.setClearColor(new THREE.Color(0x000000));
// renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
// 处理颜色失真
renderer.outputEncoding = THREE.sRGBEncoding;



// 设置渲染的尺寸大小
renderer.setSize(window.innerWidth, window.innerHeight)

// 将webGL渲染的canvas内容添加到body上
// document.body.appendChild(renderer.domElement);


// 创建轨道控制器
const controls = new OrbitControls(camera, renderer.domElement)
// controls.maxAzimuthAngle = Math.PI * 0.5;
// controls.minAzimuthAngle = Math.PI * 0.1;

controls.maxPolarAngle = Math.PI * 0.45;
controls.minPolarAngle = Math.PI * 0.1;
controls.enablePan = true
// controls.hylMovePanY = false; //关闭垂直平移相机
// controls.hylMovePanX = true; // 开启水平移动相机

controls.autoRotate = false
// 设置水平移动
controls.screenSpacePanning = false

// 缩放速度
controls.zoomSpeed = 1

controls.maxZoom = 500

//是否可以缩放 
controls.enableZoom = true;
//是否开启右键拖拽 
controls.enablePan = true;
controls.autoRotateSpeed = 0.3

// //设置相机距离原点的最远距离 
// controls.minDistance = 200; 
// //设置相机距离原点的最远距离 
// controls.maxDistance = 600; 


// 阻尼
// controls.enableDamping = true


// 渲染函数
function render() {
  // 阻尼渲染更新函数
  // 滑动时有过度效果
  // controls.enableDamping = true
  controls.update()

  renderer.render(scene, camera);
  CSS2LabelRenderer.render(scene, camera); //执行渲染HTML标签
  // 渲染下一帧的时候调用render函数
  requestAnimationFrame(render)
}
render()

// 添加坐标轴辅助器
// const axesHelper = new THREE.AxesHelper(6000);
// scene.add(axesHelper)


export {
  renderer,
  camera,
  scene,
  controls,
  // mesh,
  // loadNum,
  // cameraPosition,
};
<template>
  <div id="app">
    <div id="main" class="box"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: ['dataList'],
  data() {
    return {
      dataArr: this.dataList,
    };
  },

  created() {

    console.log(this.dataList);
  },

  mounted() {
    setTimeout(() => {
      this.getCharts()
    }, 500)
  },

  methods: {
    getCharts() {

      var chartDom = document.getElementById('main');
      console.log(chartDom);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: 'category',
          data: this.dataArr,
          axisLine: {
            lineStyle: {
              color: '#fff',
            }
          },
          data: ['设备总数', '报警数', '故障数', '失联数']
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: '#fff',
            }
          },
        },
        series: [
          {
            data: this.dataArr,
            // data: [120, 200, 150, 80],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.5)'
            },

            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = ['#bac8ff', '#ffa8a8', '#ffec99', '#dee2e6'];
                  return colorList[params.dataIndex]
                }
              }
            }

          }
        ]
      };

      option && myChart.setOption(option, true);
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 260px;
  //   .title {
  //     padding: 6% 0 2% 4%;
  //     font-size: 18px;
  //     color: aqua;
  //   }
  //   .chart {
  //     width: 100%;
  //     height: 80%;
  //   }
}
</style>
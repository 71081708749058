<template>
  <div id="map">
    <baidu-map @ready="createMap" :zoom="zoom" class="map" :center="locations" :pinch-to-zoom="true" :dragging="true" :scroll-wheel-zoom="true">
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_PAN"></bm-navigation>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_LARGE" :offset="{width: 10,height:60}"></bm-navigation>
      <!-- 地图类型 -->
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
      <!-- 缩略图 -->
      <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
      <!-- 行政区划   fillColor="lightgreen"-->
      <bm-boundary :name="locations" :strokeWeight="3" strokeColor="#000" fillColor=""></bm-boundary>
      <bml-marker-clusterer :averageCenter="true">
        <bm-marker :animation="marker.status=='正常'?'':'BMAP_ANIMATION_BOUNCE'" :id="index" :title="marker.typeName" class="marks" ref="marks" @click="markerClicked(marker)" v-for="(marker,index) of markersOfDevice" :position="{ lng: marker.lng, lat: marker.lat }" :icon="{url:baseUrl + marker.iconurl, size: {width: 45, height: 45}}">
          <bm-label :content=" marker.status==undefined?marker.typeName:marker.typeName+'---'+marker.status" :offset="{width: 0, height: 50}" />
        </bm-marker>
        <!-- :icon="{url:baseUrl + marker.iconurl, size: {width: 45, height: 45}}" -->
      </bml-marker-clusterer>
      <!-- <bm-control>
        <el-select v-model="deviceTypeChecked" placeholder="请选择设备类型" class="serachinput" collapse-tags multiple @change="checkedValue($event)">
          <el-option v-for="item in deviceType" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </bm-control> -->

      <!-- 设备信息 -->
      <dv-border-box-9 class="info-box" v-if="showDeviceInfo">
        <div class="model-top">
          <span class="title">{{deviceInfo.typeName}}</span>
          <dv-decoration-3 style="width:260px;height:10px;margin-top:10px" />
          <span class="el-icon-close close" @click="closeInfo"></span>
        </div>
        <div class="model-content">
          <span @click="baseInfo" ref="check1" style="color:#81ecec;font-size:20px">基本信息</span>
          <i>&nbsp;&nbsp;&nbsp;</i>
          <span @click="deviceDetails" ref="check2" style="color:#dff9fb;font-size:20px">设备详情</span>
          <hr>
          <!-- 基本信息 -->
          <div v-if="show1" class="show-info">
            <div style="height:350px;overflow:scroll;">
              <div class="deviceStatus">
                <el-button v-if="typeId == 'IntelligentFireControlTerminal'" type="primary" size="mini" style="margin:0 10px">
                  <i class="icon-iotechxinhao iconfont"> </i>
                  <span> 信号值：</span>
                  <span> {{dynaVal.signalVal}}</span>
                </el-button>
                <el-button style="letter-spacing: 3px;" effect="dark" size="mini" :type="deviceInfo.status=='报警'?'danger':
                     deviceInfo.status=='故障'?'warning':
                     deviceInfo.status=='失联'?'info':
                     deviceInfo.status=='正常'?'success':''">{{ deviceInfo.status }}</el-button>
              </div>
              <div class="attributeOfstatic">
                <div class="content1">设备编号 </div>
                <div class="content2">{{ deviceInfo.deviceId }}</div>
              </div>
              <div v-if="typeId == 'IntelligentFireControlTerminal'&&dynaVal.CCIDVal!=''&&dynaVal.IMSIVal!=''">
                <div class="attributeOfstatic">
                  <div class="content1">CCID </div>
                  <div class="content2">{{ dynaVal.CCIDVal }}</div>
                </div>
                <div class="attributeOfstatic">
                  <div class="content1">IMSI </div>
                  <div class="content2">{{ dynaVal.IMSIVal }}</div>
                </div>
              </div>
              <div class="attributeOfstatic" v-for="(item,index) in deviceInfo.nameValues" :key="index" v-if="item.name.indexOf('绑定视频')==-1&&item.name!='设备ID'&&item.name!='设备图片'">
                <div class="content1">{{item.name=='所属系统'?'系统':item.name=='绑定公司'?'公司':item.name}}</div>
                <div class="content2">{{ item.value }}</div>
              </div>
            </div>
          </div>
          <!-- 设备信息 -->
          <div v-if="show2" class="show-info">
            <EquipmentDetails :typeId="typeId" :deviceId="deviceId" :deviceSta="deviceSta" :dynaVal="dynaVal" />
          </div>
        </div>
      </dv-border-box-9>

      <!-- 公司信息 -->
      <dv-border-box-9 class="info-box" v-if="showCompanyInfo">
        <div class="model-top">
          <span class="title">单位信息</span>
          <dv-decoration-3 style="width:260px;height:10px;margin-top:10px" />
          <span class="el-icon-close close" @click="closeInfo"></span>
        </div>
        <div class="cpmpany-info">
          <div class="content">
            <!-- 图片 -->
            <div class="img-content">
              <dv-border-box-2 :color="['#0bc8ef', '#748ffc']">
                <div>
                  <el-image class="image" :src="imgurl" :preview-src-list="srcList" title="点击查看大图">
                    <div slot="error" class="img-error">
                      <div class="img-error">图片未上传!</div>
                    </div>
                  </el-image>
                </div>
              </dv-border-box-2>
              <div class="msgInfo" :color="['#0bc8ef', '#748ffc']">
                <div class="title" style="margin-top:10px">主要负责人</div>
                <div class="attrib">责任人姓名：&nbsp;&nbsp;{{companyInfo.zrname==null?'暂无':companyInfo.zrname}}</div>
                <div class="attrib">责任人电话：&nbsp;&nbsp;{{companyInfo.zrphone==null?'暂无':companyInfo.zrphone}}</div>
                <div class="attrib">管理人姓名：&nbsp;&nbsp;{{companyInfo.glname==null?'暂无':companyInfo.glname}}</div>
                <div class="attrib">管理人电话：&nbsp;&nbsp;{{companyInfo.glphone==null?'暂无':companyInfo.glphone }}</div>
              </div>
            </div>
            <!-- 信息 -->
            <div class="info-content" :color="['#0bc8ef', '#748ffc']">
              <div class="item" @click="lookMore('设备详情')" title="点击查看告警详情">
                <div>设备总数：{{allList}} 个</div>
                <div>报警：{{alarmList}} 个</div>
                <div>故障：{{faultList}} 个</div>
                <div>失联：{{lostList}} 个</div>
              </div>
              <dv-decoration-6 style="width:250px;height:10px;margin-bottom:10px" />
              <div class="info">
                <div style="height:110px">
                  <div class="title">公司信息</div>
                  <div class="attrib">公司名称：&nbsp;&nbsp;{{companyInfo.ciId==null?'暂无':companyInfo.ciId}}</div>
                  <div class="attrib">公司地址：&nbsp;&nbsp;{{companyInfo.address==null?'暂无':companyInfo.address}}</div>
                </div>
                <dv-border-box-2 style="height:220px" :color="['#0bc8ef', '#748ffc']">
                  <RightTop :dataList="dataList" />
                </dv-border-box-2>
                <el-button plain type="success" size="mini" class="btn" @click="lookMore('查看设备')">设备详情</el-button>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-9>

    </baidu-map>

    <div class="back" @click="close">
      <el-button type="primary" size="small">关闭</el-button>
    </div>
    <div class="fullScreen" v-if="showDeviceInfo"></div>
    <div class="fullScreen" v-if="showCompanyInfo"></div>
  </div>
</template>
<script>
import Command from '@/api/mapBuidingApi/Command'

import RightTop from '@/components/cloudPlatform/Right/RightTop'

import * as echarts from 'echarts';
// 公司點位
import CompanyMessage from '@/api/managementApi/CompanyMessage'
// 公司下的设备
import DeviceHistoryStatus from '@/api/manageApi/DeviceHistoryStatus'
import BaseData from '@/assets/config/BaseData'
import EquipmentDetails from '@/components/manage/EquipmentDetails'

import deviceTypeAboutMap from "@/api/manageApi/DeviceTypeAboutMap.js";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import { BmlMarkerClusterer } from "vue-baidu-map";
import deviceTypeInfo from "@/api/manageApi/DeviceTypeManagement";
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  props: ['locations'],
  components: {
    BmlMarkerClusterer,
    EquipmentDetails,
    RightTop,
  },
  data() {
    return {
      dynaVal: {
        CCIDVal: '',
        IMSIVal: '',
        signalVal: '',
      },
      baseUrl: BaseData.baseUrl,
      show1: true,
      show2: false,
      deviceInfo: [],  // 点击的设备信息
      position: this.position,
      zoom: 10,
      // 所有设备类型
      deviceType: [],
      typeId: '',
      deviceId: '',
      deviceSta: '',
      deviceTypeChecked: "",

      // 设备坐标点
      markersOfDevice: [],
      //   公司坐标
      markersOfCompany: [],
      //  所有坐标
      allMarks: [],

      // 点击的公司信息
      companyInfo: [],
      // 控制弹框显示内容
      showCompanyInfo: false,
      showDeviceInfo: false,

      alarmNumList: [],

      // 公司图片
      imgurl: '',
      srcList: [],
      unitNameList: [],
      systemInfo: [],

      allList: 0,
      alarmList: 0,
      faultList: 0,
      lostList: 0,

      dataList: [],

      // 除总线设备外的其它设备指令集合
      deviceCommandList: [],
    };
  },


  created() {
    console.log(BmlMarkerClusterer);
    this.getAllUnitName()
    // console.log(this.baseUrl);
    this.getAddedDeviceInfoList();
    this.getAllDeviceInfo();

  },

  mounted() {

  },

  methods: {
    getdeviceStatus() {
      console.log(this.markersOfDevice);
      for (let i = 0; i < this.markersOfDevice.length; i++) {
        if (this.markersOfDevice[i].status) {
          if (this.markersOfDevice[i].status != '正常') {
            if (this.markersOfDevice[i].status == '报警') {
              this.markersOfDevice[i].iconurl = this.markersOfDevice[i].iconurl.split('/')[0] + '/' + 'alarm' + this.markersOfDevice[i].iconurl.split('/')[1]
            } else if (this.markersOfDevice[i].status == '故障') {
              this.markersOfDevice[i].iconurl = this.markersOfDevice[i].iconurl.split('/')[0] + '/' + 'fault' + this.markersOfDevice[i].iconurl.split('/')[1]
            } else if (this.markersOfDevice[i].status == '失联') {
              // 失联设备
              this.markersOfDevice[i].iconurl = this.markersOfDevice[i].iconurl.split('/')[0] + '/' + 'lostIn' + this.markersOfDevice[i].iconurl.split('/')[1]
            }
          }
        }
      }
    },

    baseInfo() {
      this.show1 = true
      this.show2 = false
      this.$refs.check1.style.color = "#81ecec"
      this.$refs.check2.style.color = "#dff9fb"
    },
    deviceDetails() {
      this.$refs.check1.style.color = "#dff9fb"
      this.$refs.check2.style.color = "#81ecec"
      this.show1 = false
      this.show2 = true
    },
    // 关闭弹框
    closeInfo() {
      this.showDeviceInfo = false
      this.showCompanyInfo = false
      this.show1 = true
      this.show2 = false
    },
    // 创建卫星地图
    createMap({ BMap, map }) {
      // map.addControl(BMap.NavigationControl())
      // map.setMapType(BMAP_HYBRID_MAP);
      // map.addEventListener("touchmove", function (e) {
      //   map.enableDragging();
      // });
      // 触摸结束时触发次此事件  此时开启禁止拖动
      // map.addEventListener("touchend", function (e) {
      //   map.disableDragging();
      // });
    },
    // 关闭
    close() {
      this.$emit('closeD', true)
    },
    // 查看设备报警信息
    lookMore(msg) {
      console.log(this.companyInfo);
      console.log(this.companyInfo.companyId);
      if (msg == "设备详情") {
        this.$router.push({
          path: '/AlarmAcceptance',
          query: {
            companyId: this.companyInfo.companyId,
          }
        })
      } else if ('查看设备') {
        this.$router.push({
          path: '/DeviceDisplay',
        })
      }
    },


    // 查所有公司
    getAllUnitName() {
      // 所有公司
      companySystem.getCompanyInfo().then(res => {
        this.unitNameList = res.data.data.list
      })
      // 所有系統
      noticeSysManagement.getNotifyBigSystemById().then(res => {
        this.systemInfo = res.data.data.list
      })

    },
    // 查询所有设备
    getAllDeviceInfo() {
      this.markersOfDevice = []
      deviceTypeAboutMap.getAllDeviceTypeAboutMap().then((res) => {
        this.markersOfDevice = res.data.data.list;
        console.log(this.markersOfDevice);
        CompanyMessage.getAllPositionCompany().then(res => {
          this.markersOfCompany = res.data.data.companyList
          let companyId = ''
          for (let i = 0; i < this.markersOfCompany.length; i++) {
            for (let j = 0; j < this.unitNameList.length; j++) {
              if (this.markersOfCompany[i].ciId == this.unitNameList[j].id) {
                companyId = this.markersOfCompany[i].ciId
                this.markersOfCompany[i]['companyId'] = companyId
                this.markersOfCompany[i].ciId = this.unitNameList[j].name
              }
            }
            this.markersOfCompany[i].iconurl = this.markersOfCompany[i].companyIcon
            this.markersOfCompany[i].typeName = this.markersOfCompany[i].ciId
          }

          for (const item of this.markersOfDevice) {
            this.allMarks.push(item)
          }
          for (const item of this.markersOfCompany) {
            item.lng = Number(item.lng)
            item.lat = Number(item.lat)
            item.value = []
            item.value.push(Number(item.lng))
            item.value.push(Number(item.lat))
            this.allMarks.push(item)
          }
          this.markersOfDevice = this.allMarks


          // 地图点位设备状态样式显示
          this.getdeviceStatus()
        })
      });
    },
    // 查询设备类型
    getAddedDeviceInfoList() {
      deviceTypeInfo.getDeviceTypeList(1, 999, { name: "" }).then((res) => {
        this.deviceType = res.data.data.rows;
      });
    },
    // 选择设备类型
    // checkedValue(item) {
    //   console.log(item);
    //   if (item.length != 0) {
    //     deviceTypeAboutMap.getDeviceTypeAboutMap(item).then((res) => {
    //       this.markersOfDevice = res.data.data.list;
    //       console.log(this.markersOfDevice);
    //     });
    //   } else {
    //     this.getAllDeviceInfo();
    //   }

    // },

    // 点击坐标弹出对应 id
    async markerClicked(marker) {
      console.log(marker);
      this.srcList = []
      // 判断如果点的单位是公司，显示的内容就是公司信息，如果是设备，就显示设备信息
      if (marker.ciId) {
        // 公司所有信息
        this.companyInfo = marker;
        this.showCompanyInfo = true;
        this.imgurl = BaseData.baseUrl + this.companyInfo.companyImg
        this.srcList.push(this.imgurl)

        console.log(marker.ciId);
        this.dataList = []
        // 根据公司id查设备
        DeviceHistoryStatus.getCompanyDevice(marker.companyId).then(res => {
          this.alarmNumList = res.data.data
          this.allList = this.alarmNumList.allList.length
          this.alarmList = this.alarmNumList.alarmList.length
          this.faultList = this.alarmNumList.faultList.length
          this.lostList = this.alarmNumList.lostList.length
          this.dataList.push(this.allList, this.alarmList, this.faultList, this.lostList)
          console.log(this.dataList);
          //   console.log(this.alarmNumList);
        })
      } else {
        this.deviceInfo = marker;

        this.showDeviceInfo = true;
        this.deviceSta = marker.status;
        this.typeId = marker.typeId;
        this.deviceId = marker.deviceId
        // 根据设备ID 查动态属性
        const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceId)

        // 总线设备单独处理
        if (this.typeId == 'IntelligentFireControlTerminal') {
          console.log(res);
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].id == "CCID") {
              this.dynaVal.CCIDVal = res.data.list[i].value
            }
            if (res.data.list[i].id == "IMSI") {
              this.dynaVal.IMSIVal = res.data.list[i].value
            }
            if (res.data.list[i].id == "信号值") {
              this.dynaVal.signalVal = res.data.list[i].value
            }
          }
        }
        //  else {
        //   // 查其它设备下的指令
        //   //获取该设备类型所有的指令
        //   Command.getDeviceCommand(marker.typeId).then(res => {
        //     console.log(res);
        //     this.deviceCommandList = res.data.data
        //   })

        // }

        for (let i = 0; i < marker.nameValues.length; i++) {
          //   公司
          for (let j = 0; j < this.unitNameList.length; j++) {
            if (marker.nameValues[i].name == "绑定公司") {
              if (marker.nameValues[i].value == this.unitNameList[j].id) {
                marker.nameValues[i].value = this.unitNameList[j].name
              }
            }
          }
          // 系统
          for (let j = 0; j < this.systemInfo.length; j++) {
            if (marker.nameValues[i].name == "所属系统") {
              if (marker.nameValues[i].value == this.systemInfo[j].id) {
                marker.nameValues[i].value = this.systemInfo[j].bigSystemName
              }
            }
          }
          if (marker.nameValues[i].name == "短信通知" || marker.nameValues[i].name == "微信通知" || marker.nameValues[i].name == "语音通知") {
            if (marker.nameValues[i].value == "1") {
              marker.nameValues[i].value = "通知"
            }
          }
          if (marker.nameValues[i].name == "布防状态") {
            marker.nameValues[i].value == "1" ? marker.nameValues[i].value = "布防" : marker.nameValues[i].value = "不布防"
          }
        }
      }


    },
  },
};
</script>

<style lang="scss" scoped>
.fullScreen {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(3, 7, 24, 0.6);
}
.map {
  width: 99%;
  height: 73vh;
  margin: 10px;
}
.serachinput {
  width: 240px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.container-box {
  font-size: 16px;
  margin: 15px;
  padding: 5px 10px;
  border-bottom: 1px dashed #ccc;
}
.item-box1 {
  box-sizing: border-box;
  display: inline-block;
  width: 35%;
  font-weight: 600;
  text-align: center;
}
.item-box2 {
  box-sizing: border-box;
  display: inline-block;
  width: 65%;
  text-align: center;
}

.back {
  position: absolute;
  top: 6%;
  right: 2.5%;
  cursor: pointer;
  i {
    font-size: 19px;
    letter-spacing: 3px;
    color: black;
  }
}
/deep/.anchorBL {
  display: none;
}
/deep/.BMapLabel {
  color: #fff !important;
  border: none !important;
  border-radius: 5px;
  padding: 3px 8px !important;
  background: rgba(0, 0, 0, 0.4) !important;
}

.info-box {
  clip: recp(20px, 20px, 20px, 20px);
  position: absolute;
  top: 10%;
  left: 17%;
  background: rgba(0, 7, 36, 0.8);
  width: 45vw;
  height: 480px;
  border-radius: 10px;
  z-index: 1;

  // 公司信息
  .cpmpany-info {
    font-size: 18px;
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    // border: 1px solid red;
    color: #fff;
    padding: 5px 10px;
    .content {
      width: 100%;
      height: 400px;

      display: flex;
      .img-content {
        width: 35%;
        height: 160px;
        box-sizing: border-box;
        div {
          width: 97%;
          padding: 7px 5px;
          overflow: hidden;

          /deep/img {
            width: 100%;
            height: 100%;
          }
        }
        .msgInfo {
          height: 210px;
          margin-top: 5px;
          //   border: 1px solid red !important;
          .seamless-warp {
            height: 190px;
            overflow: hidden;

            .lis {
              span {
                height: 35px;
                font-size: 15px;
                line-height: 35px;
                display: inline-block;
              }
              span:nth-child(1) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              span:nth-child(2) {
                float: right;
                color: aquamarine;
              }

              span:nth-child(2):hover {
                color: rgb(114, 250, 250);
                cursor: pointer;
              }
            }
          }
        }

        // border: 1px solid red;
      }
      .info-content {
        width: 65%;
        height: 400px;
        margin-left: 10px;

        // border: 1px solid red;
        .info {
          height: 320px;

          .title {
            font-size: 18px;
            color: rgb(16, 248, 248);
            font-weight: 600;
            font-family: "宋体";
            letter-spacing: 2px;
            margin: 5px;
          }
          .title::before {
            content: "丨";
          }
          .attrib {
            font-size: 16px;
            height: 25px;
            line-height: 25px;
            color: rgb(88, 219, 219);
            padding-left: 20px;
          }

          .charts {
            width: 100%;
            height: 220px;
            border: 1px solid red;
          }

          .btn {
            float: right;
            position: absolute;
            top: 235px;
            right: 10px;
            margin: 10px 20px;
            letter-spacing: 2px;
          }
        }
        .item {
          font-size: 16px;
          width: 100%;
          height: 50px;
          color: rgb(187, 253, 255);
          box-sizing: border-box;
          line-height: 50px;
          display: flex;
          cursor: pointer;
          div {
            // border: 1px solid red;
            width: 25%;
          }
        }
      }
    }
  }
  .title {
    font-size: 18px;
    color: rgb(16, 248, 248);
    font-weight: 600;
    font-family: "宋体";
    letter-spacing: 2px;
  }
  .title::before {
    content: "丨";
  }
  .attrib {
    font-size: 16px;
    height: 25px;
    line-height: 25px;
    color: rgb(88, 219, 219);
    padding-left: 20px;
  }
  // 设备信息
  .model-top {
    margin: 20px 0 0 20px;
    .title {
      font-size: 22px;
      letter-spacing: 2px;
      color: rgb(7, 228, 228);
      font-weight: 600;
      font-family: "宋体";
    }
    .title::before {
      content: "丨";
    }
    .close {
      position: absolute;
      top: 0px;
      right: 15px;
      font-size: 22px;
      font-weight: 600;
      color: rgb(252, 246, 246);
    }
  }

  .model-content {
    span {
      cursor: pointer;
    }
    .show-info {
      width: 100%;
      color: #fff;
      height: 350px;
      box-shadow: 1px 1px 5px rgb(0, 3, 23);
      scrollbar-width: none;
    }
    margin: 10px 20px 10px 20px;

    color: #222;
  }
}
// 基本信息样式
.attributeOfstatic {
  //   width: 40%;
  //   border: 1px solid red;
  display: flex;
  //   flex-wrap: wrap;
  .content1 {
    width: 35%;
    font-size: 16px;
    padding: 10px 0px;
  }
  .content2 {
    font-size: 16px;
    padding: 10px 5px;
  }
}
/deep/img {
  width: 45px;
  height: 45px;
}
.deviceStatus {
  position: absolute;
  top: 1%;
  right: 8%;
}

// 描述信息样式
/deep/.el-descriptions__body {
  background: none;
  color: aliceblue;
  font-size: 16px;
}
/deep/.el-descriptions-item__label.is-bordered-label {
  color: rgb(171, 248, 248);
  font-size: 16px;
  background: rgba(109, 227, 243, 0.2);
}

/deep/.BMap_Marker {
  //   box-shadow: 0px 0px 35px red;
  //   background: rgba(255, 9, 9, 0.35) !important;
  border-radius: 50%;
  animation: breathing 1s linear infinite alternate;
}
/deep/.img-error {
  color: rgba(62, 191, 196, 0.747);
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  line-height: 100px;
  user-select: none;
}
// .markers {
//   box-shadow: 0px 0px 35px red !important;
//   background: rgba(255, 9, 9, 0.35) !important;
//   border-radius: 50% !important;
//   animation: breathing 0.8s linear infinite alternate !important;
// }
@keyframes breathing {
  0% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}
/deep/::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div id="app">
    <span class="el-icon-circle-close dialog-close" @click="close"></span>
    <span type="primary" class="process-btn" plain size="mini" v-if="policeStatistics" @click="aKeyProcess">一键处理</span>
    <el-table :data="itemList" border style="width: 100%" :max-height="480" v-if="deviceStatistics" highlight-current-row> 
      <el-table-column v-if="false" type="selection" width="55"></el-table-column>
      <el-table-column fixed type="index" label="序号" width="58" align="center">
      </el-table-column>
      <el-table-column prop="id" label="设备号" align="center">
      </el-table-column>
      <el-table-column prop="type" label="设备类型" align="center" width="150px">
      </el-table-column>
      <el-table-column prop="company" label="单位" align="center" width="180px">
      </el-table-column>
      <el-table-column prop="address" label="安装位置" align="center" width="180px">
      </el-table-column>
      <el-table-column prop="system" label="系统" align="center" width="150px">
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="110px">
        <template slot-scope="scope">
          <el-button @click="checkCrt(scope.row.id)" type="success" size="mini" v-if="other">查看CRT</el-button>
          <el-button type="success" size="mini" @click="viewVideo(scope.row)" v-if="video">查看视频</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- v-if="policeStatistics" -->
    <el-table :data="itemList" border style="width: 100%" :max-height="480" v-if="policeStatistics" highlight-current-row @selection-change="handleSelectionChange($event)">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column fixed type="index" label="序号" width="58" align="center">
      </el-table-column>
      <el-table-column prop="deviceId" label="设备号" align="center" >
      </el-table-column>
      <el-table-column prop="typeName" label="设备类型" align="center" width="150px">
      </el-table-column>
      <el-table-column prop="companyId" label="单位" align="center" width="180px">
      </el-table-column>
      <el-table-column prop="valueName" label="状态类型" align="center" width="150px">
      </el-table-column>
      <el-table-column prop="value" label="设备状态" align="center" width="180px">
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="110px">
        <template slot-scope="scope">
          <el-button @click="toProcess" type="primary" size="mini" v-if="other">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- v-if="showPage" -->
    <el-pagination :current-page.sync="current" :page-size="limit" :total="total" background layout="prev, pager, next"
      class="pagination" @current-change="getDeviceInfoList"></el-pagination>
      <el-dialog title="处理异常设备" :visible.sync="visible" width="30%" style="background:rgba(0,0,0,0.5)">
              <div>
                <div style="width:100%">
                  <span style="font-size:16px">选择处理类型：</span>
                  <el-radio-group v-model="processRadio">
                    <el-radio :label="1" style="margin:10px">真实</el-radio>
                    <el-radio :label="2" style="margin:10px">误报</el-radio>
                    <el-radio :label="3" style="margin:10px">检修</el-radio>
                    <el-radio :label="4" style="margin:10px">测试</el-radio>
                  </el-radio-group>
                  <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
                  </el-input>
                </div>
              </div>
              <div slot="footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="handleClick">确 定</el-button>
              </div>
            </el-dialog>
  </div>
</template>

<script>
import HomePage from "@/api/manageApi/HomePage";
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import DeviceHistoryStatus from '../../api/manageApi/DeviceHistoryStatus';
export default {
  props: ['message'],
  name: "DeviceInfo",
  data() {
    return {
      other: true,
      video: false,
      // 设备统计
      deviceStatistics: true,
      // 报警数量
      policeStatistics: false,
      itemList: [],
      // 处理
      toDealWith: {},
      //   视频路径
      videoUrl: '',
      deviceName: this.message,
      dealWith: '',

      //分页相关数据
      current: 1, //当前页
      limit: 10, //每页显示条数
      total: 0, //数据总数
      //
      processRadio: '',
      visible: false,
      processList: [],
      showPage: false,
      symbol: '',
      idStr: '',
      textarea: '',
    };
  },
  created() {
    this.jurisSysName()
  },
  methods: {
    //复选框点击事件
    handleSelectionChange(value) {
      console.log(111);
      this.dealWith = value
      console.log(value);
    },
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNames = res.data.data.list;
        this.getWxygInfo()
      });
    },
    // 查询所有设备信息
    getWxygInfo() {
      if (true) {
        if (this.deviceName == '无线烟感' ||
          this.deviceName == '主机监测' ||
          this.deviceName == '水压水位' ||
          this.deviceName == '视频联动' ||
          this.deviceName == '安全用电' ||
          this.deviceName == '燃气监测' ||
          this.deviceName == '动环监测' ||
          this.deviceName == '其它设备'
        ) {
          if (this.deviceName == '视频联动') {
            this.video = true
            this.other = false
          }
          this.showPage = true;
          this.deviceStatistics = true;
          this.policeStatistics = false;
          console.log(this.deviceName);
          let classify = this.deviceName
          HomePage.pageDeviceInfoByTypeName(classify, this.current, this.limit).then((res) => {
            console.log(res);
            this.itemList = res.data.data.deviceInfoHomePageVoList;
            console.log(this.itemList);
            this.total = res.data.data.total
            this.dataTrans();
          });
        } else if (this.deviceName == "报警") {
          this.showPage = false;
          this.deviceStatistics = false;
          this.policeStatistics = true;

          // HomePage.getAllAlarmDevice().then((res) => {
          //   this.itemList = res.data.data.alarmDevice;
          //   this.dataTrans();
          // });
          HomePage.getAbnormalDeviceList('alarm', this.current, this.limit).then((res) => {
            console.log(res);
            this.itemList = res.data.data.list;
            this.total = res.data.data.total
            this.dataTrans();
          });

        } else if (this.deviceName == "故障") {
          this.showPage = false;
          this.deviceStatistics = false;
          this.policeStatistics = true;


          // HomePage.getAllfaultDevice().then((res) => {
          //   this.itemList = res.data.data.faultDevice;
          //   this.dataTrans();
          // });
          HomePage.getAbnormalDeviceList('fault', this.current, this.limit).then((res) => {
            console.log(res);
            this.itemList = res.data.data.list;
            this.total = res.data.data.total
            this.dataTrans();
          });
        } else if (this.deviceName == "失联") {
          this.showPage = false;
          this.deviceStatistics = false;
          this.policeStatistics = true;


          // HomePage.getAlllostInDevice().then((res) => {
          //   this.itemList = res.data.data.lostInDevice;
          //   this.dataTrans();
          // });
          HomePage.getAbnormalDeviceList('lostIn', this.current, this.limit).then((res) => {
            this.itemList = res.data.data.list;
            this.total = res.data.data.total
            this.dataTrans();
          });
        } else {
          HomePage.pageDeviceInfoByTypeName(this.deviceName, this.current, this.limit).then((res) => {
            this.showPage = true;
            this.deviceStatistics = true;
            this.policeStatistics = false;

            this.itemList = res.data.data.deviceInfoHomePageVoList;
            console.log(this.itemList);
            this.total = res.data.data.total
            this.dataTrans();
          });
        }
      }
    },

    // 分页查
    getDeviceInfoList() {
      this.getWxygInfo();
    },

    // 系统id转名称
    dataTrans() {
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].company == "" || this.itemList[i].company == null || this.itemList[i].company == undefined) {
          this.itemList[i].company = "暂无"
        }
        if (this.itemList[i].address == null || this.itemList[i].address == undefined) {
          this.itemList[i].address = "无"
        }
        if (this.itemList[i].createdTime == null) {
          this.itemList[i].createdTime = "--"
        }
        if (this.itemList[i].address == null || this.itemList[i].address == undefined) {
          this.itemList[i].address = "无"
        }
        for (let j = 0; j < this.systemNames.length; j++) {
          if (this.itemList[i].system == this.systemNames[j].id) {
            this.itemList[i].system = this.systemNames[j].bigSystemName
          }
        }
      }
    },

    // 处理当前设备  单个处理
    toProcess() {
      this.visible = true
      this.symbol = '处理'
    },

    // 一键处理
    aKeyProcess() {
      this.symbol = '一键处理'
      if (this.dealWith.length == 0) {
        this.$message.warning('请选择处理设备')
      } else {
        this.visible = true
        this.idStr = ''
        let arr = []
        for (let i = 0; i < this.dealWith.length; i++) {
          arr.push(this.dealWith[i].deviceId)
        }
        this.idStr = arr.toString()
        console.log(this.idStr);
      }
    //   let processKey = ""
    //   if (this.deviceName == "报警") {
    //     processKey = 'alarmDevice'
    //   } else if (this.deviceName == "故障") {
    //     processKey = 'faultDevice'
    //   } else {
    //     processKey = 'lostInDevice'
    //   }
    //   this.$confirm('此操作将永久处理全部设备, 是否继续?', '一键处理', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     let obj = {}
    //     obj.type = processKey;
    //     console.log(processKey);
    //     DeviceHistoryStatus.updateRecord(obj).then(res=>{
    //       if(res.data.message=="处理成功"){
    //         this.$message({
    //           message: "处理成功！",
    //           type: "success",
    //         });
    //       }else{
    //         this.$message({
    //           message: "处理失败！",
    //           type: "error",
    //         });
    //       }
    //       this.getWxygInfo();
    //     })
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消一键处理'
    //     });
    //   });
    },
    handleClick() {
      // 处理单个设备
      if (this.symbol == '处理') {
        this.visible = false;
        let isSolve = true;
        if (this.processRadio === 1) {
          this.processList.processType = "真实";
        } else if (this.processRadio === 2) {
          this.processList.processType = "误报";
        } else if (this.processRadio === 3) {
          this.processList.processType = "检修";
        } else if (this.processRadio === 4) {
          this.processList.processType = "测试";
        } else {
          isSolve = false;
          this.visible = true;
          this.$message.error("请选择处理类型！");
        }
        if (isSolve) {
          this.textarea == '' ? this.textarea = '无' : this.textarea
          this.processList.process = this.textarea
          this.processList.checkTime = ''

          console.log(this.processList)
          let obj = {}
          obj.deviceId = this.processList.deviceId

          if (this.processList.process != "" && this.processList.process != "无") {
            obj.process = this.processList.process
          }

          obj.processType = this.processList.processType
          DeviceHistoryStatus.updateRecord(obj).then(res => {
            if (res.data.message == "处理成功") {
              this.$message({
                message: "处理成功！",
                type: "success",
              });
              this.getAlldevice();
            } else {
              this.$message({
                message: "处理失败！",
                type: "error",
              });
            }
          })
        }
        // 处理完清空
        this.processRadio = ''
        this.textarea = ''
        this.jurisSysName()
      }
      // 复选框一键处理
      else {
        //对选择处理类型的提示
        if (this.processRadio == '' || this.textarea == '') {
          this.$message.warning('填写处理信息')
        } else {
          if (this.processRadio === 1) {
            this.processList.processType = "真实";
          } else if (this.processRadio === 2) {
            this.processList.processType = "误报";
          } else if (this.processRadio === 3) {
            this.processList.processType = "检修";
          } else if (this.processRadio === 4) {
            this.processList.processType = "测试";
          }
          // this.processList.process = this.textarea
          // this.processList.id = this.idStr
          let obj = {}
          obj.deviceId = this.idStr
          obj.process = this.textarea
          obj.processType = this.processList.processType
          console.log(obj);
          DeviceHistoryStatus.updateRecord(obj).then(res => {
            console.log(res);
            if (res.data.message == "处理成功") {
              this.$message({
                message: "处理成功！",
                type: "success",
              });
              this.getAlldevice();
            } else {
              this.$message({
                message: "处理失败！",
                type: "error",
              });
            }
          })
          // 处理完清空
          this.processRadio = ''
          this.textarea = ''
          this.visible = false
          this.jurisSysName()
        }
      }

    },
    // 查看详情按钮
    // checkDetails(id) {
    //   this.showDetails = true;
    //   HomePage.dynamicQuery(id).then((res) => {
    //     console.log(res);
    //     this.detilesList = res.data.data.detilesList
    //   });
    // },

    // 查看视频
    viewVideo(item) {
      console.log(item.id);
      HomePage.viewVideo(item.id).then((res) => {
        console.log(res);
        this.videoUrl = res.data.data.videoUrl;
        window.location.href = this.videoUrl;
      });
    },

    // 查看CRT按钮
    checkCrt(id) {
      crtDeviceCoordinate.getCoordinateByDeviceId(id).then((res) => {
        console.log(res);
        if (res.data.message != "失败") {
          console.log(res.data);
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
              address: 'showPage',
            },
          });
        } else {

          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },

    // 关闭弹框
    close() {
      this.$emit('closeWindow', false)
    },

    // 点击复制设备ID
    // copyInfo() {
    //   var clipboard = new Clipboard(".tag-read");
    //   clipboard.on("success", (e) => {
    //     // console.log("复制成功");
    //     this.$message.success("复制成功！");
    //     // 释放内存
    //     clipboard.destroy();
    //   });
    //   clipboard.on("error", (e) => {
    //     // 不支持复制
    //     this.$message.error("该浏览器不支持自动复制");
    //     // 释放内存
    //     clipboard.destroy();
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
// el-table 表格 透明样式
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}

/deep/::-webkit-scrollbar-track {
  background: #1f6873;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 18px;
  color: aqua;
}

/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 16px;
  color: rgb(63, 225, 247);
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

/deep/ th.el-table__cell.is-leaf {
  border: none;
}

/deep/.el-table__fixed-right-patch {
  background: none;
}

/deep/.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: none;
}

/deep/.el-table__fixed-right::before {
  background: none;
}

/deep/.el-table__fixed::before {
  background: none;
}

.dialog-close {
  position: absolute;
  top: 0;
  right: 10px;
  float: right;
  padding: 0 15px;
  font-size: 25px;
  cursor: pointer;
  color: #00ffff;
}

.dialog-close:hover {
  color: rgb(13, 176, 240);
}

.process-btn {
  position: absolute;
  top: 0;
  right: 100px;

  z-index: 1;
  background: linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) right bottom,
    linear-gradient(#00ffff, #00ffff) right bottom;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background-color: rgba(0, 66, 66, 0.4);
  color: #ffffff;
  font-family: "宋体";
  color: #00ffff;
  font-size: 15px;
  padding: 8px;
  animation: breathing 1s linear infinite alternate;
  cursor: pointer;
}

.process-btn:hover {
  background-color: rgba(6, 235, 235, 0.4);
}

.pagination {
  bottom: 5%;
}
// HOVER修改
/deep/.el-table__body .el-table__row.hover-row td{
background-color:rgba($color: #007a91, $alpha: 0.5) !important;
color: #72d4dc;
}

</style>
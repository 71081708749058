<template>
  <div style="margin-top: 20px; padding: 0px 15px">
    <div class="slide-title1" style="padding: 0px 5px">
      <span>设备名称</span>
      <span>报警类型</span>
      <span>处理状态</span></span>
    </div>
    <vue-seamless-scroll :data="listData" class="seamless-warp" v-if="listData.length!=0">
      <ul class="item">
        <li v-for="item in listData">
          <span :class="item.handleStatus.indexOf('未')!=-1?'red':'green'" v-text="item.typeName"></span>
          <span :class="item.handleStatus.indexOf('未')!=-1?'red':'green'" v-text="item.value"></span>
          <span :class="item.handleStatus.indexOf('未')!=-1?'red':'green'" v-text="item.handleStatus"></span>
        </li>
      </ul>
    </vue-seamless-scroll>
    <div class="alarm" v-else>暂无近7天报警记录</div>
  </div>
</template>

<script>
import HomePage from "@/api/manageApi/HomePage";

export default {
  data() {
    return {
      listData: [],
    };
  },
  created() {
    //先执行一次，之后每隔十秒获取一次
    // HomePage.getAllHistory().then((resp) => {
    //   if (resp) {
    //     this.listData = resp.data.data.list;
    //   }
    // });
    this.init();
    // var intervalId = window.setInterval(() => {
    //   setTimeout(() => {
    //     ///调取接口
    //   }, 0);
    // }, 10000);
    // this.$once('hook:beforeDestroy', () => {
    //   clearInterval(intervalId);
    // })
  },
  methods: {
    init() {
      HomePage.getAllHistory().then((resp) => {
        if (resp) {
          this.listData = resp.data.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-title1 {
  width: 100%;
  font-weight: bold;
  // color: #fff;
  line-height: 15px;
  display: flex;
  justify-content: space-around;
}
.slide-title1 span {
  width: 33.33333%;
  display: inline-block;
  text-align: center;
  letter-spacing: 2px;
  color: #7fffd4;
  font-size: 18px;
  padding-bottom: 20px;
}
.seamless-warp {
  overflow: hidden;
  .item {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    li span {
      display: inline-block;
      width: 33.33%;
      margin: 10px 0;
    }
  }
}
.alarm{
    width: 100%;
    text-align: center;
    line-height: 150px;
    letter-spacing: 3px;
    font-size: 18px;
    font-weight: 600;
    color: cadetblue;
}
.green {
  color: rgb(169, 247, 169);
}
.red {
  color: rgb(240, 148, 148);
}
</style>

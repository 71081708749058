<template>
  <el-container class="home-container showbg">
    <!-- @wheel.prevent="scroll($event)" -->
     <!-- v-drag -->
    <div id="overburden">

      <!-- <el-popover placement="top" width="300" trigger="click" v-for="item in indexPageCrtPointList" :id="item.crtPictureId" :style="{'left':(parseFloat(item.coordinatex)*showBgWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*showBgHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer','z-index':'8'}">
        <div v-for="item in infoList" :key="item.crtId">
            <div v-for="items in item.list">
                <div>{{items.name}}--{{items.status}}</div>
            </div>
        </div>
        <el-button slot="reference" class="iconButton" @click="getFloorCRTdata(item.crtPictureId)"></el-button>
      </el-popover> -->

      <span class="iconfont nodes" @click="showDeviceInfo(item)" v-for="item in indexPageCrtPointList" :id="item.crtPictureId" :style="{'left':(parseFloat(item.coordinatex)*showBgWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*showBgHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2'}">
        &#xe9bb;
      </span>

      <img id="showBg" :src="imgUrl" style="width:100%;height:100%;z-index:-1"></img>

      <el-dialog :title="id" :visible.sync="dialogVisible" width="30%" style="background:rgba(0,0,0,0.6)">
        <div></div>
        <div slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </div>
      </el-dialog>

    </div>

  </el-container>
</template>

<script>
import BaseData from '@/assets/config/BaseData'
import AccountManage from '@/api/manageApi/AccountManage'
import User from '@/api/manageApi/User'

import crtInfo from '@/api/manageApi/CrtInfo'
import indexPageCrtPoint from "@/api/manageApi/IndexPageCrtPoint";
import drag from "../../../utils/drag.js";

export default {
  data() {
    return {
      showBgHei: 0,
      showBgWid: 0,
      //已添加点位列表
      indexPageCrtPointList: [],

      infoList: [],


      AllFloorCRTLists: {},

      users: '',

      platformInfo: [],
      imgUrl: '',


      dialogVisible: false,
      id: '',

    };
  },


  created() {
    this.getUserInfo()

    this.getIndexPageCrtPointList();

  },

  mounted() {
    // 1920 * 1080
    this.showBgWid = showBg.width;
    this.showBgHei = showBg.height;
    console.log(this.showBgWid, this.showBgHei);
  },


  methods: {
    //获取已添加的点位信息
    getIndexPageCrtPointList() {
      indexPageCrtPoint.list().then((res) => {
        this.indexPageCrtPointList = res.data.data.list;
        console.log(this.indexPageCrtPointList);
        for (const item of this.indexPageCrtPointList) {
          // 修改默认图标大小
          // item.iconWidth = 20
        }
      });
    },

    showDeviceInfo(info) {
      console.log(info);
      this.dialogVisible = true
      this.id = info.id
    },


    async getUserInfo() {
      const { data: res } = await User.loginUserInfo()
      this.users = res.data.userName
      console.log(this.users);
      this.getTitleText()
    },

    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then(res => {
        this.platformInfo = res.data.data.data
        this.imgUrl = BaseData.baseUrl + this.platformInfo.picture
        console.log(this.imgUrl + this.platformInfo.picture);
      })
    },

    scroll(e) {
      console.log(e);
      // console.log(document.getElementById('showBg'));
      // let img = document.getElementById('showBg')
      // console.log(e);
      // let delta = (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1))

      // if (delta > 0) {//放大
      //   // 向上滚
      //   let oWidth = img.offsetWidth;//取得图片的实际宽度
      //   let oHeight = img.offsetHeight; //取得图片的实际高度

      //   img.style.width = (oWidth + 50) + "px"
      //   img.style.height = (oHeight + 50 / oWidth * oHeight) + "px"

      // } else if (delta < 0) {//缩小
      //   //向下滚
      //   let oWidth = img.offsetWidth; //取得图片的实际宽度
      //   let oHeight = img.offsetHeight; //取得图片的实际高度
      //   if (this.el.offsetWidth > 100 || this.el.offsetHeight > 75) {//判断如果图片缩小到原图大小就停止缩小(100和75分别为原图的宽高)

      //     img.style.width = oWidth - 50 + "px"
      //     img.style.height = oHeight - 50 / oWidth * oHeight + "px"
      //   }
      // }
    }



  },


  destroyed() {

  },
};
</script>

<style lang="scss" scoped>
@keyframes breathing {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.content {
  width: 100%;
  height: 100px;
}

.nodes {
  animation: breathing 1s linear infinite alternate;
  display: inline-block;
  //   background: url(../../assets/images/CRT点位.png) no-repeat;
  background-size: contain;
  //   height: 30px;
  color: rgb(98, 240, 33);
  font-size: 30px;
  font-weight: 800;
  cursor: pointer;
  z-index: 9;
}
.nodes:hover {
  // background: #000;
  color: #0a9add;
}

#overburden {
  width: 100%;
  height: 100%;
  position: absolute;
}
// #showBg {
//   width: 1920px;
//   position: relative;
//   overflow-y: hidden;
// }

.changeColor {
  background: green;
  animation: fade 600ms infinite;
  animation: fade 600ms infinite;
}

.equip_real_value {
  width: 86%;
  margin: 0 auto;
}
.equip_real_value span {
  width: 33.33%;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  color: #43f0d4;
  font-weight: 500;
}
.equip_real_content {
  width: 86%;
  margin: 0 auto;
  display: flex;
}
.equip_real_content .equipInfo {
  width: 33.33%;
}
.equip_real_content .equipInfo div {
  line-height: 32px;
}
.check:hover {
  color: blue;
}
// .iconButton {
//   background: url(../../assets/images/CRT点位.png) no-repeat;
//   background-size: contain;
//   width: 30px;
//   height: 30px;
//   border: none;
//   outline: none;
//   cursor: pointer;
// }
.deviceAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: nowrap;
}
.breath {
  font-size: 16px;
  animation: breathing1 1s linear infinite alternate;
}
@keyframes breathing1 {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(01);
  }
}
</style>

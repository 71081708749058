<template>
  <div>
    <!-- v-if="flag" -->
    <div v-for="(obj,i) in meshList" :key="i">
      <span>
        <three-tag @father="father" :obj="obj" :x="obj.position.x" :y="obj.position.y" :z="obj.position.z" :index="i" :name="obj.userData.name" v-if="obj.userData.name!=='船.001'&&obj.userData.name!='船'&&obj.userData.name!='附体'&&obj.userData.name!='G-__594408'&&obj.userData.name!='G-__584922'"></three-tag>
      </span>
    </div>
  </div>
</template>

<script>
// // 设备状态和设备
import HomePage from '@/api/manageApi/HomePage'
// 查所有公司
import Company from '@/api/managementApi/CompanyInfo'


import { renderer, scene, camera, cameraPosition } from "@/components/three/threejs/ThreeBuildings.js";

import TWEEN from '@tweenjs/tween.js';

import * as THREE from 'three'
import Bus from '@/components/three/HTMLTag/bus.js'
import ThreeTag from '@/components/three/HTMLTag/ThreeTag.vue'
export default {
  name: "MarkCoord",
  props: ['meshList'],
  components: {
    ThreeTag
  },
  data() {
    return {
      // list: this.meshList,
      // abnormalCompany: [],
      // allCompany: [],
      // flag: true,

      // 点击切换建筑视角
    };
  },

  created() {
    // console.log(this.list);

  },
  mounted() {
    // 查所有公司
    // this.getAllCompany()
    // 查异常设备单位
    // this.getCompanyAndDeviceStatus()


  },



  methods: {
    father(val) {
      this.$emit('yeye', val)
    },
    // 查所有公司
    async getAllCompany() {
      const res = await Company.getCompanyInfo()
      // console.log(res);
      this.allCompany = res.data.data.list;
    },
    // 查异常设备单位
    async getCompanyAndDeviceStatus() {
      const res = await HomePage.getAbnormalCiIdList()
      this.abnormalCompany = res.data.data
      // console.log(this.abnormalCompany);
      this.transformInfo()
    },
    transformInfo() {
      for (let i = 0; i < this.allCompany.length; i++) {
        // 报警单位id转文字
        for (let j = 0; j < this.abnormalCompany.alarmCiIdSet.length; j++) {
          if (this.abnormalCompany.alarmCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.alarmCiIdSet[j] = this.allCompany[i].name
          }
        }
        // 故障单位id转文字
        for (let j = 0; j < this.abnormalCompany.faultCiIdSet.length; j++) {
          if (this.abnormalCompany.faultCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.faultCiIdSet[j] = this.allCompany[i].name
          }
        }
        // 失联单位id转文字
        for (let j = 0; j < this.abnormalCompany.lostInCiIdSet.length; j++) {
          if (this.abnormalCompany.lostInCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.lostInCiIdSet[j] = this.allCompany[i].name
          }
        }
      }
      // 判断当前单位是否在异常单位列表里   如果在，在userData里添加status属性
      this.getResult()
    },
    getResult() {
      // for (let i = 0; i < this.list.length; i++) {
      //   console.log(this.list.userData.name);
      //   // if(this.list.userData.name){

      //   // }

      // }
    }

  }
};
</script>

<style scoped>
</style>

var provinces = {
  // 23个省
  台湾: "taiwan",
  河北: "hebei",
  山西: "shanxi",
  辽宁: "liaoning",
  吉林: "jilin",
  黑龙江: "heilongjiang",
  江苏: "jiangsu",
  浙江: "330000",
  安徽: "anhui",
  福建: "fujian",
  江西: "jiangxi",
  山东: "shandong",
  河南: "henan",
  湖北: "hubei",
  湖南: "hunan",
  广东: "guangdong",
  海南: "hainan",
  四川: "sichuan",
  贵州: "guizhou",
  云南: "yunnan",
  陕西: "shanxi1",
  甘肃: "gansu",
  青海: "qinghai",
  // 5个自治区
  新疆: "xinjiang",
  广西: "guangxi",
  内蒙古: "neimenggu",
  宁夏: "ningxia",
  西藏: "xizang",
  // 4个直辖市
  北京: "beijing",
  天津: "tianjin",
  上海: "shanghai",
  重庆: "chongqing",
  // 2个特别行政区
  香港: "xianggang",
  澳门: "aomen",
};

var cityMap = {
  北京市: "110100",
  天津市: "120100",
  上海市: "310100",
  重庆市: "500100",
  崇明县: "310200",
  湖北省直辖县市: "429000",
  铜仁市: "522200",
  毕节市: "522400",
  石家庄市: "130100",
  唐山市: "130200",
  秦皇岛市: "130300",
  邯郸市: "130400",
  邢台市: "130500",
  保定市: "130600",
  张家口市: "130700",
  承德市: "130800",
  沧州市: "130900",
  廊坊市: "131000",
  衡水市: "131100",
  太原市: "140100",
  大同市: "140200",
  阳泉市: "140300",
  长治市: "140400",
  晋城市: "140500",
  朔州市: "140600",
  晋中市: "140700",
  运城市: "140800",
  忻州市: "140900",
  临汾市: "141000",
  吕梁市: "141100",
  呼和浩特市: "150100",
  包头市: "150200",
  乌海市: "150300",
  赤峰市: "150400",
  通辽市: "150500",
  鄂尔多斯市: "150600",
  呼伦贝尔市: "150700",
  巴彦淖尔市: "150800",
  乌兰察布市: "150900",
  兴安盟: "152200",
  锡林郭勒盟: "152500",
  阿拉善盟: "152900",
  沈阳市: "210100",
  大连市: "210200",
  鞍山市: "210300",
  抚顺市: "210400",
  本溪市: "210500",
  丹东市: "210600",
  锦州市: "210700",
  营口市: "210800",
  阜新市: "210900",
  辽阳市: "211000",
  盘锦市: "211100",
  铁岭市: "211200",
  朝阳市: "211300",
  葫芦岛市: "211400",
  长春市: "220100",
  吉林市: "220200",
  四平市: "220300",
  辽源市: "220400",
  通化市: "220500",
  白山市: "220600",
  松原市: "220700",
  白城市: "220800",
  延边朝鲜族自治州: "222400",
  哈尔滨市: "230100",
  齐齐哈尔市: "230200",
  鸡西市: "230300",
  鹤岗市: "230400",
  双鸭山市: "230500",
  大庆市: "230600",
  伊春市: "230700",
  佳木斯市: "230800",
  七台河市: "230900",
  牡丹江市: "231000",
  黑河市: "231100",
  绥化市: "231200",
  大兴安岭地区: "232700",
  南京市: "320100",
  无锡市: "320200",
  徐州市: "320300",
  常州市: "320400",
  苏州市: "320500",
  南通市: "320600",
  连云港市: "320700",
  淮安市: "320800",
  盐城市: "320900",
  扬州市: "321000",
  镇江市: "321100",
  泰州市: "321200",
  宿迁市: "321300",
  杭州市: "330100",
  宁波市: "330200",
  温州市: "330300",
  嘉兴市: "330400",
  湖州市: "330500",
  绍兴市: "330600",
  金华市: "330700",
  衢州市: "330800",
  舟山市: "330900",
  台州市: "331000",
  丽水市: "331100",
  合肥市: "340100",
  芜湖市: "340200",
  蚌埠市: "340300",
  淮南市: "340400",
  马鞍山市: "340500",
  淮北市: "340600",
  铜陵市: "340700",
  安庆市: "340800",
  黄山市: "341000",
  滁州市: "341100",
  阜阳市: "341200",
  宿州市: "341300",
  六安市: "341500",
  亳州市: "341600",
  池州市: "341700",
  宣城市: "341800",
  福州市: "350100",
  厦门市: "350200",
  莆田市: "350300",
  三明市: "350400",
  泉州市: "350500",
  漳州市: "350600",
  南平市: "350700",
  龙岩市: "350800",
  宁德市: "350900",
  南昌市: "360100",
  景德镇市: "360200",
  萍乡市: "360300",
  九江市: "360400",
  新余市: "360500",
  鹰潭市: "360600",
  赣州市: "360700",
  吉安市: "360800",
  宜春市: "360900",
  抚州市: "361000",
  上饶市: "361100",
  济南市: "370100",
  青岛市: "370200",
  淄博市: "370300",
  枣庄市: "370400",
  东营市: "370500",
  烟台市: "370600",
  潍坊市: "370700",
  济宁市: "370800",
  泰安市: "370900",
  威海市: "371000",
  日照市: "371100",
  莱芜市: "371200",
  临沂市: "371300",
  德州市: "371400",
  聊城市: "371500",
  滨州市: "371600",
  菏泽市: "371700",
  郑州市: "410100",
  开封市: "410200",
  洛阳市: "410300",
  平顶山市: "410400",
  安阳市: "410500",
  鹤壁市: "410600",
  新乡市: "410700",
  焦作市: "410800",
  濮阳市: "410900",
  许昌市: "411000",
  漯河市: "411100",
  三门峡市: "411200",
  南阳市: "411300",
  商丘市: "411400",
  信阳市: "411500",
  周口市: "411600",
  驻马店市: "411700",
  省直辖县级行政区划: "469000",
  武汉市: "420100",
  黄石市: "420200",
  十堰市: "420300",
  宜昌市: "420500",
  襄阳市: "420600",
  鄂州市: "420700",
  荆门市: "420800",
  孝感市: "420900",
  荆州市: "421000",
  黄冈市: "421100",
  咸宁市: "421200",
  随州市: "421300",
  恩施土家族苗族自治州: "422800",
  长沙市: "430100",
  株洲市: "430200",
  湘潭市: "430300",
  衡阳市: "430400",
  邵阳市: "430500",
  岳阳市: "430600",
  常德市: "430700",
  张家界市: "430800",
  益阳市: "430900",
  郴州市: "431000",
  永州市: "431100",
  怀化市: "431200",
  娄底市: "431300",
  湘西土家族苗族自治州: "433100",
  广州市: "440100",
  韶关市: "440200",
  深圳市: "440300",
  珠海市: "440400",
  汕头市: "440500",
  佛山市: "440600",
  江门市: "440700",
  湛江市: "440800",
  茂名市: "440900",
  肇庆市: "441200",
  惠州市: "441300",
  梅州市: "441400",
  汕尾市: "441500",
  河源市: "441600",
  阳江市: "441700",
  清远市: "441800",
  东莞市: "441900",
  中山市: "442000",
  潮州市: "445100",
  揭阳市: "445200",
  云浮市: "445300",
  南宁市: "450100",
  柳州市: "450200",
  桂林市: "450300",
  梧州市: "450400",
  北海市: "450500",
  防城港市: "450600",
  钦州市: "450700",
  贵港市: "450800",
  玉林市: "450900",
  百色市: "451000",
  贺州市: "451100",
  河池市: "451200",
  来宾市: "451300",
  崇左市: "451400",
  海口市: "460100",
  三亚市: "460200",
  三沙市: "460300",
  成都市: "510100",
  自贡市: "510300",
  攀枝花市: "510400",
  泸州市: "510500",
  德阳市: "510600",
  绵阳市: "510700",
  广元市: "510800",
  遂宁市: "510900",
  内江市: "511000",
  乐山市: "511100",
  南充市: "511300",
  眉山市: "511400",
  宜宾市: "511500",
  广安市: "511600",
  达州市: "511700",
  雅安市: "511800",
  巴中市: "511900",
  资阳市: "512000",
  阿坝藏族羌族自治州: "513200",
  甘孜藏族自治州: "513300",
  凉山彝族自治州: "513400",
  贵阳市: "520100",
  六盘水市: "520200",
  遵义市: "520300",
  安顺市: "520400",
  黔西南布依族苗族自治州: "522300",
  黔东南苗族侗族自治州: "522600",
  黔南布依族苗族自治州: "522700",
  昆明市: "530100",
  曲靖市: "530300",
  玉溪市: "530400",
  保山市: "530500",
  昭通市: "530600",
  丽江市: "530700",
  普洱市: "530800",
  临沧市: "530900",
  楚雄彝族自治州: "532300",
  红河哈尼族彝族自治州: "532500",
  文山壮族苗族自治州: "532600",
  西双版纳傣族自治州: "532800",
  大理白族自治州: "532900",
  德宏傣族景颇族自治州: "533100",
  怒江傈僳族自治州: "533300",
  迪庆藏族自治州: "533400",
  拉萨市: "540100",
  昌都地区: "542100",
  山南地区: "542200",
  日喀则地区: "542300",
  那曲地区: "542400",
  阿里地区: "542500",
  林芝地区: "542600",
  西安市: "610100",
  铜川市: "610200",
  宝鸡市: "610300",
  咸阳市: "610400",
  渭南市: "610500",
  延安市: "610600",
  汉中市: "610700",
  榆林市: "610800",
  安康市: "610900",
  商洛市: "611000",
  兰州市: "620100",
  嘉峪关市: "620200",
  金昌市: "620300",
  白银市: "620400",
  天水市: "620500",
  武威市: "620600",
  张掖市: "620700",
  平凉市: "620800",
  酒泉市: "620900",
  庆阳市: "621000",
  定西市: "621100",
  陇南市: "621200",
  临夏回族自治州: "622900",
  甘南藏族自治州: "623000",
  西宁市: "630100",
  海东地区: "632100",
  海北藏族自治州: "632200",
  黄南藏族自治州: "632300",
  海南藏族自治州: "632500",
  果洛藏族自治州: "632600",
  玉树藏族自治州: "632700",
  海西蒙古族藏族自治州: "632800",
  银川市: "640100",
  石嘴山市: "640200",
  吴忠市: "640300",
  固原市: "640400",
  中卫市: "640500",
  乌鲁木齐市: "650100",
  克拉玛依市: "650200",
  吐鲁番地区: "652100",
  哈密地区: "652200",
  昌吉回族自治州: "652300",
  博尔塔拉蒙古自治州: "652700",
  巴音郭楞蒙古自治州: "652800",
  阿克苏地区: "652900",
  克孜勒苏柯尔克孜自治州: "653000",
  喀什地区: "653100",
  和田地区: "653200",
  伊犁哈萨克自治州: "654000",
  塔城地区: "654200",
  阿勒泰地区: "654300",
  自治区直辖县级行政区划: "659000",
  台湾省: "710000",
  香港特别行政区: "810100",
  澳门特别行政区: "820000"
};


// 区
var Mapian = {
  临安区: '330112',
  余杭区: '330110',
  临平区: '330113',
  拱墅区: '330105',
  上城区: '330102',
  钱塘区: '330114',
  萧山区: '330109',
  滨江区: '330108',
  西湖区: '330106',
  富阳区: '330111',
  桐庐县: '330122',
  建德市: '330182',
  淳安县: '330127',
  长兴县: '330522',
  吴兴区: '330502',
  南浔区: '330503',
  德清县: '330521',
  安吉县: '330523',
  海宁市: '330481',
  海盐县: '330424',
  南湖区: '330402',
  平湖市: '330482',
  嘉善县: '330421',
  秀洲区: '330411',
  桐乡市: '330483',
  嵊泗县: '330922',
  岱山县: '330921',
  普陀区: '330903',
  定海区: '330902',
  象山县: '330225',
  宁海县: '330226',
  北仑区: '330206',
  鄞州区: '330212',
  奉化区: '330213',
  海曙区: '330203',
  江北区: '330205',
  镇海区: '330211',
  余姚市: '330281',
  慈溪市: '330282',
  新昌县: '330624',
  嵊州市: '330683',
  上虞区: '330604',
  越城区: '330602',
  柯桥区: '330603',
  诸暨市: '330681',
  磐安县: '330727',
  东阳市: '330783',
  永康市: '330784',
  武义县: '330723',
  婺城区: '330702',
  金东区: '330703',
  义乌市: '330782',
  浦江县: '330726',
  兰溪市: '330781',
  江山市: '330881',
  龙游县: '330825',
  衢江区: '330803',
  柯城区: '330802',
  常山县: '330822',
  开化县: '330824',
  黄岩区: '331003',
  玉环市: '331083',
  温岭市: '331081',
  椒江区: '331002',
  路桥区: '331004',
  三门县: '331022',
  临海市: '331082',
  天台县: '331023',
  仙居县: '331024',
  泰顺县: '330329',
  苍南县: '330327',
  龙港市: '330383',
  平阳县: '330326',
  文成县: '330328',
  瑞安市: '330381',
  龙湾区: '330303',
  洞头区: '330305',
  瓯海区: '330304',
  鹿城区: '330302',
  乐清市: '330382',
  永嘉县: '330324',
  庆元县: '331126',
  龙泉市: '331181',
  景宁畲族自治县: '331127',
  云和县: '331125',
  青田县: '331121',
  缙云县: '331122',
  莲都区: '331102',
  松阳县: '331124',
  遂昌县: '331123',


  // 北京市
  东城区: '110101',
  西城区: '110102',
  朝阳区: '110105',
  丰台区: '110106',
  石景山区: '110107',
  海淀区: '110108',
  门头沟区: '110109',
  房山区: '110111',
  通州区: '110112',
  顺义区: '110113',
  昌平区: '110114',
  大兴区: '110115',
  怀柔区: '110116',
  平谷区: '110117',
  密云区: '110118',
  延庆区: '110119',

  // 天津市
  蓟县: '120119',
  宝坻区: '120115',
  武清区: '120114',
  宁河区: '120117',
  北辰区: '120113',
  西青区: '120111',
  红桥区: '120106',
  河北区: '120105',
  南开区: '120104',
  河东区: '120102',
  河西区: '120103',
  和平区: '120101',
  津南区: '120112',
  东丽区: '120110',
  滨海新区: '120116',
  静海县: '120118',

  // 上海
  黄浦区: '310101',
  徐汇区: '310104',
  长宁区: '310105',
  静安区: '310106',
  普陀区: '310107',
  虹口区: '310109',
  杨浦区: '310110',
  闵行区: '310112',
  宝山区: '310113',
  嘉定区: '310114',
  浦东新区: '310115',
  金山区: '310116',
  松江区: '310117',
  青浦区: '310118',
  奉贤区: '310120',
  崇明县: '310151',



  // 贵州省
  // 贵阳市
  南明区: '520102',
  云岩区: '520103',
  花溪区: '520111',
  乌当区: '520112',
  白云区: '520113',
  小河区: '520115',
  开阳县: '520121',
  息烽县: '520122',
  修文县: '520123',
  清镇市: '520181',
  // 六盘水市
  钟山区: '520201',
  六枝特区: '520203',
  水城县: '520221',
  盘县: '520281',

  // 遵义市
  红花岗区: '520302',
  汇川区: '520303',
  遵义县: '520304',
  桐梓县: '520322',
  绥阳县: '520323',
  正安县: '520324',
  道真仡佬族苗族自治县: '520325',
  务川仡佬族苗族自治县: '520326',
  凤冈县: '520327',
  湄潭县: '520328',
  余庆县: '520329',
  习水县: '520330',
  赤水市: '520381',
  仁怀市: '520382',

  // 安顺市
  西秀区: '520402',
  平坝区: '520403',
  普定县: '520422',
  镇宁布依族苗族自治县: '520423',
  关岭布依族苗族自治县: '520424',
  紫云苗族布依族自治县: '520425',

  // 毕节市
  七星关区: '520502',
  大方县: '520521',
  黔西县: '520522',
  金沙县: '520523',
  织金县: '520524',
  纳雍县: '520525',
  威宁彝族回族苗族自治县: '520526',
  赫章县: '520527',

  // 铜仁市
  碧江区: '520602',
  万山区: '520603',
  江口县: '520621',
  玉屏侗族自治县: '520622',
  石阡县: '520623',
  思南县: '520624',
  印江土家族苗族自治县: '520625',
  德江县: '520626',
  沿河土家族自治县: '520627',
  松桃苗族自治县: '520628',

  // 黔西南州
  兴义市: '522301',
  兴仁市: '522302',
  普安县: '522323',
  晴隆县: '522324',
  贞丰县: '522325',
  望谟县: '522326',
  册亨县: '522327',
  安龙县: '522328',

  // 黔东南州
  凯里市: '522601',
  黄平县: '522622',
  施秉县: '522623',
  三穗县: '522624',
  镇远县: '522625',
  岑巩县: '522626',
  天柱县: '522627',
  锦屏县: '522628',
  剑河县: '522629',
  台江县: '522630',
  黎平县: '522631',
  榕江县: '522632',
  从江县: '522633',
  雷山县: '522634',
  麻江县: '522635',
  丹寨县: '522636',

  // 黔南州
  都匀市: '522701',
  福泉市: '522702',
  荔波县: '522722',
  贵定县: '522723',
  瓮安县: '522725',
  独山县: '522726',
  平塘县: '522727',
  罗甸县: '522728',
  长顺县: '522729',
  龙里县: '522730',
  惠水县: '522731',
  三都水族自治县: '522732',


  // 新疆
  // 乌鲁木齐市： 650100
  天山区: '650102',
  沙依巴克区: '650103',
  新市区: '650104',
  水磨沟区: '650105',
  头屯河区: '650106',
  达坂城区: '650107',
  米东区: '650109',
  乌鲁木齐县: '650121',
  // 克拉玛依市： 650200
  独山子区: '650202',
  克拉玛依区: '650203',
  白碱滩区: '650204',
  乌尔禾区: '650205',
  // 吐鲁番市： 650400
  高昌区: '650402',
  鄯善县: '650421',
  托克逊县: '650422',
  // 哈密市： 650500
  伊州区: '650502',
  巴里坤县: '650521',
  伊吾县: '650522',
  新星市: '659011',
  // 昌吉州： 652300
  昌吉市: '652301',
  阜康市: '652302',
  呼图壁县: '652323',
  玛纳斯县: '652324',
  奇台县: '652325',
  吉木萨尔县: '652327',
  木垒县: '652328',
  // 博州： 652700
  博乐市: '652701',
  阿拉山口市: '652702',
  精河县: '652722',
  温泉县: '652723',
  // 巴州： 652800
  库尔勒市: '652801',
  轮台县: '652822',
  尉犁县: '652823',
  若羌县: '652824',
  且末县: '652825',
  焉耆县: '652826',
  和静县: '652827',
  和硕县: '652828',
  博湖县: '652829',
  // 阿克苏地区： 652900
  阿克苏市: '652901',
  温宿县: '652922',
  库车县: '652923',
  沙雅县: '652924',
  新和县: '652925',
  拜城县: '652926',
  乌什县: '652927',
  阿瓦提县: '652928',
  柯坪县: '652929',
  // 克州： 653000
  阿图什市: '653001',
  阿克陶县: '653022',
  阿合奇县: '653023',
  乌恰县: '653024',
  // 喀什地区： 653100
  喀什市: '653101',
  疏附县: '653121',
  疏勒县: '653122',
  英吉沙县: '653123',
  泽普县: '653124',
  莎车县: '653125',
  叶城县: '653126',
  麦盖提县: '653127',
  岳普湖县: '653128',
  伽师县: '653129',
  巴楚县: '653130',
  塔县: '653131',
  // 和田地区： 653200
  和田市: '653201',
  和田县: '653221',
  墨玉县: '653222',
  皮山县: '653223',
  洛浦县: '653224',
  策勒县: '653225',
  于田县: '653226',
  民丰县: '653227',
  // 伊犁州： 654000
  伊宁市: '654002',
  奎屯市: '654003',
  霍尔果斯市: '654004',
  伊宁县: '654021',
  察布查尔县: '654022',
  霍城县: '654023',
  巩留县: '654024',
  新源县: '654025',
  昭苏县: '654026',
  特克斯县: '654027',
  尼勒克县: '654028',
  // 塔城地区： 654200
  乌鲁木齐市: '650100',
  克拉玛依市: '650200',
  吐鲁番市: '650400',
  哈密市: '650500',
  昌吉州: '652300',
  博州: '652700',
  巴州: '652800',
  阿克苏地区: '652900',
  克州: '653000',
  喀什地区: '653100',
  和田地区: '653200',
  伊犁州: '654000',
  塔城地区: '654200',
  阿勒泰地区: '654300',
  直辖县级政区: '659000',
  // 阿勒泰地区： 654300
  阿勒泰市: '654301',
  布尔津县: '654321',
  富蕴县: '654322',
  福海县: '654323',
  哈巴河县: '654324',
  青河县: '654325',
  吉木乃县: '654326',
  // 直辖县级政区： 659000
  石河子市: '659001',
  阿拉尔市: '659002',
  图木舒克市: '659003',
  五家渠市: '659004',
  铁门关市: '659006',
  双河市: '659007',

  // 安徽
  // 合肥市行政区划代码： 3401
  瑶海区: '340102',
  庐阳区: '340103',
  蜀山区: '340104',
  包河区: '340111',
  长丰县: '340121',
  肥东县: '340122',
  肥西县: '340123',
  庐江县: '340124',
  巢湖市: '340181',

  // 芜湖市行政区划代码： 3402
  镜湖区: '340202',
  弋江区: '340203',
  鸠江区: '340207',
  三山区: '340208',
  繁昌区: '340212',
  繁昌县: '340222',
  南陵县: '340223',
  无为县: '340225',

  // 蚌埠市行政区划代码： 3403
  龙子湖区: '340302',
  蚌山区: '340303',
  禹会区: '340304',
  淮上区: '340311',
  怀远县: '340321',
  五河县: '340322',
  固镇县: '340323',

  // 淮南市行政区划代码： 3404
  大通区: '340402',
  田家庵区: '340403',
  谢家集区: '340404',
  八公山区: '340405',
  潘集区: '340406',
  凤台县: '340421',
  寿县: '340422',

  // 马鞍山市行政区划代码：3405
  花山区: '340503',
  雨山区: '340504',
  博望区: '340506',
  当涂县: '340521',
  含山县: '340522',
  和县: '340523',

  // 淮北市行政区划代码：3406
  杜集区: '340602',
  相山区: '340603',
  烈山区: '340604',
  濉溪县: '340621',

  // 铜陵市行政区划代码： 3407
  铜官区: '340705',
  义安区: '340706',
  郊区: '340711',
  枞阳县: '340722',

  // 安庆市行政区划代码： 3408
  迎江区: '340802',
  大观区: '340803',
  宜秀区: '340811',
  怀宁县: '340822',
  太湖县: '340825',
  宿松县: '340826',
  望江县: '340827',
  岳西县: '340828',
  桐城市: '340881',
  潜山市: '340882',

  // 黄山市行政区划代码： 3410
  屯溪区: '341002',
  黄山区: '341003',
  徽州区: '341004',
  歙县: '341021',
  休宁县: '341022',
  黟县: '341023',
  祁门县: '341024',

  // 滁州市行政区划代码： 3411
  琅琊区: '341102',
  南谯区: '341103',
  来安县: '341122',
  全椒县: '341124',
  定远县: '341125',
  凤阳县: '341126',
  天长市: '341181',
  明光市: '341182',

  // 阜阳市行政区划代码： 3412
  颍州区: '341202',
  颍东区: '341203',
  颍泉区: '341204',
  临泉县: '341221',
  太和县: '341222',
  阜南县: '341225',
  颍上县: '341226',
  界首市: '341282',

  // 宿州市行政区划代码： 3413
  埇桥区: '341302',
  砀山县: '341321',
  萧县: '341322',
  灵璧县: '341323',
  泗县: '341324',

  // 六安市行政区划代码： 3415
  金安区: '341502',
  裕安区: '341503',
  叶集区: '341504',
  霍邱县: '341522',
  舒城县: '341523',
  金寨县: '341524',
  霍山县: '341525',

  // 亳州市行政区划代码： 3416
  谯城区: '341602',
  涡阳县: '341621',
  蒙城县: '341622',
  利辛县: '341623',

  // 池州市行政区划代码：3417
  贵池区: '341702',
  东至县: '341721',
  石台县: '341722',
  青阳县: '341723',

  // 宣城市行政区划代码：3418
  郎溪县: '341821',
  泾县: '341823',
  绩溪县: '341824',
  旌德县: '341825',
  宁国市: '341881',
  广德县: '341882',
  宣州区: '341802',


  // 江苏省
  // 南京市行政区划代码：3201
  玄武区: '320102',
  秦淮区: '320104',
  建邺区: '320105',
  鼓楼区: '320106',
  浦口区: '320111',
  栖霞区: '320113',
  雨花台区: '320114',
  江宁区: '320115',
  六合区: '320116',
  溧水区: '320117',
  高淳区: '320118',
  // 无锡市行政区划代码：3202
  锡山区: '320205',
  惠山区: '320206',
  滨湖区: '320211',
  梁溪区: '320213',
  新吴区: '320214',
  江阴市: '320281',
  宜兴市: '320282',
  // 徐州市行政区划代码：3203
  鼓楼区: "320302",
  云龙区: "320303",
  贾汪区: "320305",
  泉山区: "320311",
  铜山区: "320312",
  丰县: '320321',
  沛县: '320322',
  睢宁县: "320324",
  新沂市: "320381",
  邳州市: "320382",
  // 常州市行政区划代码： 3204
  天宁区: '320402',
  钟楼区: '320404',
  新北区: '320411',
  武进区: '320412',
  金坛区: '320413',
  溧阳市: '320481',
  // 苏州市行政区划代码：3205
  虎丘区: '320505',
  吴中区: '320506',
  相城区: '320507',
  姑苏区: '320508',
  吴江区: '320509',
  常熟市: '320581',
  张家港市: '320582',
  昆山市: '320583',
  太仓市: '320585',
  // 南通市行政区划代码：3206
  崇川区: '320602',
  通州区: '320612',
  如东县: '320623',
  启东市: '320681',
  如皋市: '320682',
  海门市: '320684',
  海安县: '320685',
  // 连云港市行政区划代码：3207
  连云区: '320703',
  海州区: '320706',
  赣榆区: '320707',
  东海县: '320722',
  灌云县: '320723',
  灌南县: '320724',
  // 淮安市行政区划代码：3208
  淮安区: '320803',
  淮阴区: '320804',
  清江浦区: '320812',
  洪泽区: '320813',
  涟水县: '320826',
  盱眙县: '320830',
  金湖县: '320831',
  // 盐城市行政区划代码：3209
  亭湖区: '320902',
  盐都区: '320903',
  大丰区: '320904',
  响水县: '320921',
  滨海县: '320922',
  阜宁县: '320923',
  射阳县: '320924',
  建湖县: '320925',
  东台市: '320981',
  // 扬州市行政区划代码：3210
  广陵区: '321002',
  邗江区: '321003',
  江都区: '321012',
  宝应县: '321023',
  仪征市: '321081',
  高邮市: '321084',
  // 镇江市行政区划代码：3211
  京口区: '321102',
  润州区: '321111',
  丹徒区: '321112',
  丹阳市: '321181',
  扬中市: '321182',
  句容市: '321183',
  // 泰州市行政区划代码：3212
  海陵区: '321202',
  高港区: '321203',
  姜堰区: '321204',
  兴化市: '321281',
  靖江市: '321282',
  泰兴市: '321283',
  // 宿迁市行政区划代码：3213
  宿城区: '321302',
  宿豫区: '321311',
  沭阳县: '321322',
  泗阳县: '321323',
  泗洪县: '321324',


  // 江西省
  // 南昌市行政区划代码： 3601
  东湖区: 360102,
  西湖区: 360103,
  青云谱区: 360104,
  湾里区: 360105,
  青山湖区: 360111,
  新建县: 360112,
  红谷滩区: 360113,
  南昌县: 360121,
  安义县: 360123,
  进贤县: 360124,

  // 景德镇市行?政区划代码：3602
  珠山区: 360203,
  浮梁县: 360222,
  乐平市: 360281,
  昌江区: 360202,

  // 萍乡市行政区划代码：3603
  安源区: 360302,
  湘东区: 360313,
  莲花县: 360321,
  上栗县: 360322,
  芦溪县: 360323,

  // 九江市行政区划代码：3604
  濂溪区: 360402,
  浔阳区: 360403,
  柴桑区: 360404,
  武宁县: 360423,
  修水县: 360424,
  永修县: 360425,
  德安县: 360426,
  都昌县: 360428,
  湖口县: 360429,
  彭泽县: 360430,
  瑞昌市: 360481,
  共青城市: 360482,
  庐山市: 360483,

  // 新余市行政区划代码：3605
  渝水区: 360502,
  分宜县: 360521,

  // 鹰潭市行政区划代码：3606
  月湖区: 360602,
  余江区: 360603,
  贵溪市: 360681,

  // 赣州市行政区划代码：3607
  章贡区: 360702,
  南康区: 360703,
  赣县区: 360704,
  信丰县: 360722,
  大余县: 360723,
  上犹县: 360724,
  崇义县: 360725,
  安远县: 360726,
  龙南县: 360727,
  定南县: 360728,
  全南县: 360729,
  宁都县: 360730,
  于都县: 360731,
  兴国县: 360732,
  会昌县: 360733,
  寻乌县: 360734,
  石城县: 360735,
  瑞金市: 360781,

  // 吉安市行政区划代码：3608
  吉州区: 360802,
  青原区: 360803,
  吉安县: 360821,
  吉水县: 360822,
  峡江县: 360823,
  新干县: 360824,
  永丰县: 360825,
  泰和县: 360826,
  遂川县: 360827,
  万安县: 360828,
  安福县: 360829,
  永新县: 360830,
  井冈山市: 360881,

  // 宜春市行政区划代码：3609
  袁州区: 360902,
  奉新县: 360921,
  万载县: 360922,
  上高县: 360923,
  宜丰县: 360924,
  靖安县: 360925,
  铜鼓县: 360926,
  丰城市: 360981,
  樟树市: 360982,
  高安市: 360983,

  // 抚州市行政区划代码：3610
  临川区: 361002,
  东乡区: 361003,
  南城县: 361021,
  黎川县: 361022,
  南丰县: 361023,
  崇仁县: 361024,
  乐安县: 361025,
  宜黄县: 361026,
  金溪县: 361027,
  资溪县: 361028,
  广昌县: 361030,

  // 上饶市行政区划代码：3611
  信州区: 361102,
  广丰区: 361103,
  广信区: 361104,
  玉山县: 361123,
  铅山县: 361124,
  横峰县: 361125,
  弋阳县: 361126,
  余干县: 361127,
  鄱阳县: 361128,
  万年县: 361129,
  婺源县: 361130,
  德兴市: 361181,

  // 山西省行政区划代码： 14
  太原市: 140100,
  大同市: 140200,
  阳泉市: 140300,
  长治市: 140400,
  晋城市: 140500,
  朔州市: 140600,
  晋中市: 140700,
  运城市: 140800,
  忻州市: 140900,
  临汾市: 141000,
  吕梁市: 141100,
  // 太原市行政区划代码：1401
  小店区: 140105,
  迎泽区: 140106,
  杏花岭区: 140107,
  尖草坪区: 140108,
  万柏林区: 140109,
  晋源区: 140110,
  清徐县: 140121,
  阳曲县: 140122,
  娄烦县: 140123,
  古交市: 140181,
  // 大同市行政区划代码：1402
  新荣区: 140212,
  左云县: 140226,
  南郊区: 140213,
  南郊区: 140214,
  天镇县: 140222,
  阳高县: 140221,
  浑源县: 140225,
  广灵县: 140223,
  灵丘县: 140224,
  大同县: 140215,
  // 阳泉市行政区划代码：1403
  城区: 140302,
  矿区: 140303,
  郊区: 140311,
  平定县: 140321,
  盂县: 140322,
  // 长治市行政区划代码： 1404
  潞州区: 140403,
  上党区: 140404,
  屯留区: 140405,
  潞城区: 140406,
  襄垣县: 140423,
  平顺县: 140425,
  黎城县: 140426,
  壶关县: 140427,
  长子县: 140428,
  武乡县: 140429,
  沁县: 140430,
  沁源县: 140431,
  // 晋城市行政区划代码： 1405
  城区: 140502,
  沁水县: 140521,
  阳城县: 140522,
  陵川县: 140524,
  泽州县: 140525,
  高平市: 140581,
  // 朔州市行政区划代码：1406
  朔城区: 140602,
  平鲁区: 140603,
  山阴县: 140621,
  应县: 140622,
  右玉县: 140623,
  怀仁市: 140681,
  // 晋中市行政区划代码： 1407
  榆次区: 140702,
  太谷区: 140703,
  榆社县: 140721,
  左权县: 140722,
  和顺县: 140723,
  昔阳县: 140724,
  寿阳县: 140725,
  祁县: 140727,
  平遥县: 140728,
  灵石县: 140729,
  介休市: 140781,
  // 运城市行政区划代码： 1408
  盐湖区: 140802,
  临猗县: 140821,
  万荣县: 140822,
  闻喜县: 140823,
  稷山县: 140824,
  新绛县: 140825,
  绛县: 140826,
  垣曲县: 140827,
  夏县: 140828,
  平陆县: 140829,
  芮城县: 140830,
  永济市: 140881,
  河津市: 140882,
  // 忻州市行政区划代码：1409
  忻府区: 140902,
  定襄县: 140921,
  五台县: 140922,
  代县: 140923,
  繁峙县: 140924,
  宁武县: 140925,
  静乐县: 140926,
  神池县: 140927,
  五寨县: 140928,
  岢岚县: 140929,
  河曲县: 140930,
  保德县: 140931,
  偏关县: 140932,
  原平市: 140981,
  // 临汾市行政区划代码： 1410
  尧都区: 141002,
  曲沃县: 141021,
  翼城县: 141022,
  襄汾县: 141023,
  洪洞县: 141024,
  古县: 141025,
  安泽县: 141026,
  浮山县: 141027,
  吉县: 141028,
  乡宁县: 141029,
  大宁县: 141030,
  隰县: 141031,
  永和县: 141032,
  蒲县: 141033,
  汾西县: 141034,
  侯马市: 141081,
  霍州市: 141082,
  // 吕梁市行政区划代码： 1411
  离石区: 141102,
  文水县: 141121,
  交城县: 141122,
  兴县: 141123,
  临县: 141124,
  柳林县: 141125,
  石楼县: 141126,
  岚县: 141127,
  方山县: 141128,
  中阳县: 141129,
  交口县: 141130,
  孝义市: 141181,
  汾阳市: 141182,

  // 大连
  中山区: 210202,
  西岗区: 210203,
  沙河口区: 210204,
  甘井子区: 210211,
  旅顺口区: 210212,
  金州区: 210213,
  普兰店市: 210214,
  长海县: 210224,
  瓦房店市: 210281,
  庄河市: 210283,
  
  //河北唐山
  玉田县:130229,
  遵化市:130281,
  迁西县:130227000000,
  迁安市:130283000000,
  丰润区:130208,
  滦县:130223000000,
  开平区:130205,
  古冶区:130204000000,
  路北区:130203,
  路南区:130202,
  丰南区:130207,
  滦南县:130224,
  乐亭县:130225,
  曹妃甸区:130209000000,
  
  //西藏
  阿里地区:542500000000,
  那曲地区:540600000000,
  昌都市:540300000000,
  林芝市:540400000000,
  山南地区:540500000000,
  拉萨市:540100000000,
  日喀则市:540200000000,
  
  //黑龙江
  // 大兴安岭
  漠河县:232701,
  塔河县:232722000000 ,
  呼玛县:232721,
  
  //黑河市
  爱辉区:231102000000,
  嫩江区:231183000000,
  孙吴县:231124,
  逊克县:231123,
  五大连池市:231182000000,
  北安市:231181000000,




};

export default {
  provinces,
  cityMap,
  Mapian,
}
<template>
  <div id="app">
    <dv-charts :option="option" class="chart" />
  </div>
</template>

<script>

import alarmRecord from '@/api/manageApi/DeviceHistoryStatus'
export default {
  props: ["getAbnormalInfo"],
  data() {
    return {
      alarmInformation: [],
      userName: '',
      option: {
        legend: {
          data: [
            {
              name: "报警",
              color: "#ff5ca9",
            },
            {
              name: "故障",
              color: "#3de7c9",
            },
            {
              name: "失联",
              color: "#f5d94e",
            },
          ],
          textStyle: {
            fill: "#fff",
          },
        },
        xAxis: {
          // data: ["10/01", "10/02", "10/03", "10/04", "10/05", "10/06", "10/07"],
          data: this.getAbnormalInfo.stringSevenDate.reverse(),
          axisLine: {
            style: {
              stroke: "#eee",
            },
          },
          axisLabel: {
            style: {
              fill: "#eee",
              fontSize: 14,
              fontWeight:600,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          data: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            style: {
              stroke: "#eee",
            },
          },
          axisLabel: {
            style: {
              fill: "#eee",
              fontSize: 14,
              fontWeight:600,
            },
          },
          axisTick: {
            show: false,
          },
          min: 0,
        },
        series: [
          {
            name: "报警",
            data: this.getAbnormalInfo.alarmList.reverse(),
            type: "line",
            lineStyle: {
              stroke: "#ff5ca9",
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#ff5ca9",
                stroke: "transparent",
              },
            },
          },
          {
            name: "故障",
            data: this.getAbnormalInfo.faultList.reverse(),
            type: "line",
            smooth: true,
            lineArea: {
              show: true,
              gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
            },
            lineStyle: {
              lineDash: [5, 5],
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#00db95",
              },
            },
          },
          {
            data: this.getAbnormalInfo.lostList.reverse(),
            type: "line",
            name: "失联",
            lineArea: {
              show: true,
              gradient: ["rgba(245, 217, 79, 0.8)", "rgba(245, 217, 79, 0.2)"],
            },
            lineStyle: {
              stroke: "#f5d94e",
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#f5d94e",
                stroke: "transparent",
              },
            },
          },
        ],

      },
    };
  },
  created() {

  },

  methods: {

  }
};
</script>

<style lang="scss" scoped>
.title {
  // ***
  // padding: 4%;
  // ***
  // padding: 6% 0 2% 4%;
  font-size: 18px;
  color: aqua;
}
.chart {
  width: 100%;
  height: 80%;
}
</style>
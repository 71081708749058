<template>
  <div class="bottom-charts">
    <div class="bc-chart-item">
      <dv-active-ring-chart :config="deviceStatusL" />
      <Label-Tag :config="labelConfig" />
    </div>
  </div>
</template>

<script>
// 初始化结构
import LabelTag from "../LabelTag";

export default {
  name: "BottomCharts",
  components: {
    LabelTag,
  },
  props: ['leftCenter'],

  data() {
    return {
      show: false,
      deviceStatusL: {
        data: [
          {
            name: "烟感",
            value: this.leftCenter.YGNumber,
          },
          {
            name: "主机",
            value: this.leftCenter.HZNumber,
          },
          {
            name: "水系统",
            value: this.leftCenter.syswNumber,
          },
          {
            name: "视频",
            value: this.leftCenter.SPNumber,
          },
          {
            name: "用电",
            value: this.leftCenter.electricityNumber,
          },
          {
            name: "燃气",
            value: this.leftCenter.fuelGasNumber,
          },
          {
            name: "动环",
            value: this.leftCenter.dhjcNumber,
          },


          {
            name: "其他",
            value: this.leftCenter.QTNumber,
          },
        ],
        color: ["#00baff", "#3de7c9", "#fff", "#ffc530", "#00baff", "#3de7c9", "#fff", "#ffc530"],
        // color: ["#8c8c8c", "#ffd591", "#36cfc9", "#ff7875", "#d3adf7", "#5b8c00", "#ff85c0", "#469f4b"],

        radius: "60%",
        activeRadius: "75%",
      },
      labelConfig: {
        data: ["烟感", "主机", "水系统", "视频", "用电", "燃气", "动环", "其他"],
      },
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="less">
.bottom-charts {
  margin: 0px 40px;
  width: 400px;
  .bc-chart-item {
    width: 400px;
    height: 250px;
    .title {
      font-size: 18px;
      color: aqua;
    }
  }
  .dv-active-ring-chart {
    height: calc(~"100% - 70px");
  }
  .active-ring-name {
    font-size: 26px !important;
  }
  .decoration-1,
  .decoration-2,
  .decoration-3 {
    display: absolute;
    left: 0%;
  }
}
</style>
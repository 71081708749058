<template>
  <div id="app">
    <div class="left">
      <!-- 左上角提示 -->
      <div class="tipBox">
        <div class="item2">
          公司设备状态：
          正常
          <span></span>
          报警
          <span></span>
          故障
          <span></span>
        </div>
        <dv-decoration-3 style="width:300px;height:10px;margin-top:5px" />
      </div>

      <div class="chart-box">
        <div class="part1-item1">
          <div>异常单位统计</div>
          <div>Abnormal unit statistics</div>
        </div>
        <div class="charts" id="main"></div>
      </div>

      <div class="info-box">
        <div class="part1-item1">
          <div>单位信息列表</div>
          <div>Unit information list</div>
        </div>
        <div class="list-info">
          <el-collapse class="info-box-item" v-model="activeNames" @change="handleChange" accordion>
            <el-collapse-item :name="item.userData.name" v-for="(item, i) in meshList" class="rows" v-if="item.userData.name!='船.001'&&item.userData.name!='船'&&item.userData.name!='附体'&&item.userData.name!='G-__594408'&&item.userData.name!='G-__584922'">
              <template slot="title">
                <i class="icon iconfont icon-iotechgongsi" style="font-size:14px"></i>
                <span>
                  &nbsp;&nbsp;&nbsp;{{item.userData.name}}
                </span>
              </template>
              <div class="item-box">
                建筑物名称：
                <span class="item" :title="companyInfo.ciId">{{companyInfo.ciId}}</span>
              </div>
              <div class="item-box">
                管辖单位名称：
                <span class="item" :title="companyInfo.gxdwname==null?'无':companyInfo.gxdwname">{{companyInfo.gxdwname==null?'无':companyInfo.gxdwname}}</span>
              </div>
              <div class="item-box">
                责任人：
                <span class="item" :title="companyInfo.zrname==null?'无':companyInfo.zrname">{{companyInfo.zrname==null?'无':companyInfo.zrname}}</span>
              </div>
              <div class="item-box">
                联系电话：
                <span class="item" :title="companyInfo.zrphone==null?'无':companyInfo.zrphone">{{companyInfo.zrphone==null?'无':companyInfo.zrphone}}</span>
              </div>
              <div class="item-box">
                管理人：
                <span class="item" :title="companyInfo.glname==null?'无':companyInfo.glname">{{companyInfo.glname==null?'无':companyInfo.glname}}</span>
              </div>
              <div class="item-box">
                管理人电话：
                <span class="item" :title="companyInfo.glphone==null?'无':companyInfo.glphone">{{companyInfo.glphone==null?'无':companyInfo.glphone}}</span>
              </div>
              <div class="item-box">
                维保人：
                <span class="item" :title="companyInfo.zjzname==null?'无':companyInfo.zjzname">{{companyInfo.zjzname==null?'无':companyInfo.zjzname}}</span>
              </div>
              <div class="item-box">
                维保人电话：
                <span class="item" :title="companyInfo.zjzphone==null?'无':companyInfo.zjzphone">{{companyInfo.zjzphone==null?'无':companyInfo.zjzphone}}</span>
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>

      <!-- <div class="info-box">
        <el-collapse class="info-box-item" v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item :name="item.userData.name" v-for="(item, i) in meshList" class="rows" v-if="item.userData.name!='船.001'&&item.userData.name!='船'&&item.userData.name!='附体'&&item.userData.name!='G-__594408'">
            <template slot="title">
              <i class="icon iconfont icon-iotechgongsi" style="font-size:14px"></i>
              <span>
                &nbsp;&nbsp;&nbsp;{{item.userData.name}}
              </span>
            </template>
            <div class="item-box">
              建筑物名称：
              <span class="item" :title="companyInfo.ciId">{{companyInfo.ciId}}</span>
            </div>
            <div class="item-box">
              管辖单位名称：
              <span class="item" :title="companyInfo.gxdwname==null?'无':companyInfo.gxdwname">{{companyInfo.gxdwname==null?'无':companyInfo.gxdwname}}</span>
            </div>
            <div class="item-box">
              责任人：
              <span class="item" :title="companyInfo.zrname==null?'无':companyInfo.zrname">{{companyInfo.zrname==null?'无':companyInfo.zrname}}</span>
            </div>
            <div class="item-box">
              联系电话：
              <span class="item" :title="companyInfo.zrphone==null?'无':companyInfo.zrphone">{{companyInfo.zrphone==null?'无':companyInfo.zrphone}}</span>
            </div>
            <div class="item-box">
              管理人：
              <span class="item" :title="companyInfo.glname==null?'无':companyInfo.glname">{{companyInfo.glname==null?'无':companyInfo.glname}}</span>
            </div>
            <div class="item-box">
              管理人电话：
              <span class="item" :title="companyInfo.glphone==null?'无':companyInfo.glphone">{{companyInfo.glphone==null?'无':companyInfo.glphone}}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div> -->

    </div>
  </div>
</template>

<script>
// 查公司信息接口
import companyMessage from "@/api/managementApi/CompanyMessage";
// 查全部公司
import Company from '@/api/managementApi/CompanyInfo'

import * as echarts from 'echarts';
import { scene, camera, controls } from "@/components/three/threejs/ThreeBuildings.js";
import * as THREE from 'three'

import TWEEN from '@tweenjs/tween.js';
import HomePage from '@/api/manageApi/HomePage'

import Bus from '@/components/three/HTMLTag/bus.js'
export default {
  props: ['meshList'],
  data() {
    return {
      // 公司设备信息弹框
      dialogVisible: false,

      // 折叠面板
      activeNames: ['1'],
      // 公司信息
      companyInfo: [],
      // 公司列表
      companyList: [],
      // 公司设备列表
      companyDeviceList: [],

      // 弹框标题
      dialogTitle: '',

      companyId: '',
      current: 1,
      limit: 10,
      total: 0,

      abnormalCompany: [],


      current1: camera.position,
      current2: controls.target,
      target1: {},
      target2: {},
    }
  },

  created() {

    console.log(this.meshList);  // x、y、z 轴加20

    this.getAllCompany()
  },
  mounted() {
    this.getCompanyAndDeviceStatus()

  },
  methods: {
    async getCompanyAndDeviceStatus() {
      const res = await HomePage.getAbnormalCiIdList()
      this.abnormalCompany = res.data.data
      this.getChart()
    },

    getChart() {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: 'category',
          data: ['报警', '故障', '失联'],
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 14,
            }
          }
        },
        legend: {
          data: ['报警', '故障', '失联'],
          textStyle: {
            fontSize: 15,
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            data: [{
              value: this.abnormalCompany.alarmCiIdSet.length,
              itemStyle: {
                color: '#e74c3c'
              }
            }, {
              value: this.abnormalCompany.faultCiIdSet.length,
              itemStyle: {
                color: '#ffc851'
              }
            }, {
              value: this.abnormalCompany.lostInCiIdSet.length,
              itemStyle: {
                color: '#4bcffa'
              }
            }],
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: '#fff',
                    fontSize: 16
                  }
                }
              }
            },
          }
        ]
      };

      option && myChart.setOption(option, true);

    },

    // current1 相机当前的位置
    // target1 相机的目标位置
    // current2 当前的controls的target
    // target2 新的controls的target

    animateCamera(current1, target1, current2, target2) {
      // console.log(current1);
      // console.log(target1);
      // console.log(current2);
      // console.log(target2);
      let tween = new TWEEN.Tween({
        x1: current1.x, // 相机当前位置x
        y1: current1.y, // 相机当前位置y
        z1: current1.z, // 相机当前位置z
        x2: current2.x, // 控制当前的中心点x
        y2: current2.y, // 控制当前的中心点y
        z2: current2.z // 控制当前的中心点z
      });
      tween.to({
        x1: target1.x + 20, // 新的相机位置x
        y1: target1.y + 20, // 新的相机位置y
        z1: target1.z + 20, // 新的相机位置z
        x2: target2.x, // 新的控制中心点位置x
        y2: target2.y, // 新的控制中心点位置x
        z2: target2.z  // 新的控制中心点位置x
      }, 1000);
      tween.onUpdate(() => {
        camera.position.x = x1;
        camera.position.y = y1;
        camera.position.z = z1;
        orbit.target.x = x2;
        orbit.target.y = y2;
        orbit.target.z = z2;
        orbit.update();
      })
      tween.easing(TWEEN.Easing.Cubic.InOut);
      tween.start();
    },


    onDocumentMouseDown(event) {
      // 点击屏幕创建一个向量
      var vector = new THREE.Vector3((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window
        .innerHeight) * 2 + 1, 0.5);
      vector = vector.unproject(camera); // 将屏幕的坐标转换成三维场景中的坐标
      var raycaster = new THREE.Raycaster(camera.position, vector.sub(camera.position).normalize());
      var intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        // 随机坐标
        var x = Math.round((Math.random() * 100));
        var y = Math.round((Math.random() * 100));
        var z = 50;

        var x2 = Math.round((Math.random() * 50));
        var y2 = Math.round((Math.random() * 50));
        var z2 = 5;

        var pos = new THREE.Vector3(x, y, z);
        var pos2 = new THREE.Vector3(x2, y2, z2);

        // intersects[0].object.material.color.set("#00aa00");

        this.animateCamera(this.current1, this.target1, this.current2, this.target2)

        // animateCamera(camera.position, pos, orbit.target, pos2);
      }
    },


    // 点击切换建筑视角
    // viewBuilding(item) {
    //   console.log(item);
    //   // 'G-__675093'
    //   Bus.$emit('buildName', item)
    // },

    // 查所有公司
    async getAllCompany() {
      const res = await Company.getCompanyInfo()
      // console.log(res);
      this.companyList = res.data.data.list;
      // console.log(this.companyList);
    },

    // 查看设备信息
    // async deviceInfo(val) {
    //   console.log(val);
    //   this.dialogVisible = true
    //   const res = await HomePage.getDeviceListByCiId(this.companyId, this.current, this.limit)
    //   this.companyDeviceList = res.data.data.list
    //   console.log(res);
    //   this.total = res.data.data.total
    //   console.log(this.companyDeviceList);
    // },

    // // 关闭弹框
    // dialogBeforeClose() {
    //   this.dialogVisible = false
    // },

    // // 分页
    // pagination(val) {
    //   console.log(val);
    //   this.current = val
    //   this.deviceInfo()
    // },


    // 折叠面板  根据公司id查公司信息
    async handleChange(val) {
      // 获取点击的单位的的坐标
      // for (let i = 0; i < this.meshList.length; i++) {
      //   if (val == this.meshList[i].userData.name) {
      //     this.target1 = this.meshList[i].position
      //     this.target2 = this.meshList[i].position
      //     console.log(this.meshList[i]);
      //     console.log(window.innerWidth);
      //   }
      // }
      let obj = {}
      for (let i = 0; i < this.companyList.length; i++) {
        if (val == this.companyList[i].name) {
          this.companyId = this.companyList[i].id
          console.log(this.companyList[i]);
        }
      }
      obj.name = val
      obj.ciId = this.companyId
      const res = await companyMessage.getCompanyMessage(obj)
      this.companyInfo = res.data.data.companyMessage;
      for (let i = 0; i < this.companyList.length; i++) {
        if (this.companyList[i].id == this.companyInfo.ciId) {
          this.companyInfo.ciId = this.companyList[i].name
        }
      }


    },


  },

}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.left {
  box-sizing: border-box;
  margin-top: 72px;
  width: 21%;
  height: 100%;
  // background-image: linear-gradient(
  //   to right,
  //   rgba(65, 70, 75, 0.5),
  //   rgba(106, 106, 109, 0.5)
  // );
  background-image: linear-gradient(
    to right,
    rgba(49, 68, 137, 1),
    rgba(145, 187, 238, 0.2)
  );
  position: absolute;
  border-radius: 0 10px 10px 0;
  left: 0;

  // .rowInfo {
  //   background: rgb(212, 101, 101);
  //   box-sizing: border-box;
  //   height: 50px;
  //   line-height: 50px;
  //   font-size: 18px;
  //   font-family: "宋体";
  //   font-weight: 600;
  //   color: #00d2ff;
  //   cursor: pointer;
  //   .icon {
  //     color: #09a8e7;
  //     font-size: 18px;
  //   }
  // }
  // .rowInfo:hover {
  //   background: rgba(106, 223, 238, 0.3);
  //   font-size: 20px;
  //   transition: 0.5s;
  // }

  .tipBox {
    padding: 10px;
    height: 60px;
    box-sizing: border-box;
    color: aqua;
    font-size: 18px;
    font-family: "宋体";
    font-weight: 600;
    background-size: 2px 12px, 12px 2px;
    background-color: rgba(49, 114, 167, 0.2);
    .item2 {
      height: 30px;
      line-height: 30px;
      span:nth-child(1) {
        display: inline-block;
        width: 30px;
        height: 20px;
        background: rgba(25, 223, 223, 0.8);
        border-radius: 5px;
      }
      span:nth-child(2) {
        display: inline-block;
        width: 30px;
        height: 20px;
        background: rgba(241, 64, 64, 0.8);
        border-radius: 5px;
      }
      span:nth-child(3) {
        display: inline-block;
        width: 30px;
        height: 20px;
        background: rgba(245, 159, 61, 0.8);
        border-radius: 5px;
      }
    }
  }
  .part1-item1 {
    width: 100%;
    background: url("../../../assets/images/banner.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0 0 0 50px;

    div:nth-child(1) {
      font-size: 22px;
      font-family: "宋体";
      font-weight: 600;
      letter-spacing: 2px;
      color: #00d2ff;
    }
    div:nth-child(2) {
      margin-top: 5px;
      font-size: 14px;
      letter-spacing: 2px;
      color: #2bc1eb;
    }
  }
  .chart-box {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 35%;

    .charts {
      width: 100%;
      height: 250px;
    }
  }

  .info-box {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    .list-info {
      height: 75%;
      overflow: scroll;
    }
  }
}

.item-box {
  padding-left: 10px;
  color: rgb(177, 247, 206);
  height: 25px;
  line-height: 25px;
  font-weight: 600;
  border-radius: 5px;
  .item {
    float: right;
    display: inline-block;
    // border: 1px solid red;
    font-weight: 500;
    margin-right: 10px;
    width: 100px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.item-box:hover {
  background: rgba(90, 233, 209, 0.2);
}
/deep/.el-collapse-item__header {
  font-weight: 600;
  padding-left: 10px;
  color: aqua;
  background: none;
  border: none;
}
/deep/.el-collapse-item__wrap {
  background: rgba(204, 201, 201, 0.3);
  border: none;
}
/deep/.el-collapse-item__content {
  padding: 5px;
}
/deep/.el-collapse {
  border: none;
}
</style>
<template>
  <div id="app">
    <div class="container">
      <!-- <div class="btns">
        <span @click="suofang('fangda')" class="iconfont icon">&#xe602;</span>
        <span @click="suofang('suoxiao')" class="iconfont icon">&#xe601;</span>
      </div>
      <span @click="reset" class="iconfont icon reset">&#xe605;</span>
      <div class="btns1">
        <span @click="offsetOfMap('right')" class="iconfont icon">&#xe76e;</span></span>
        <span @click="offsetOfMap('top')" class="iconfont icon">&#xe626;</span>
        <span @click="offsetOfMap('bottom')" class="iconfont icon">&#xe629;</span>
        <span @click="offsetOfMap('left')" class="iconfont icon">&#xe771;</span>
      </div> -->
      <div v-if="toggle">
        <div size="mini" style="right: 30%;" type="primary" @click="viewMap()" class="btn">查看地图</div>
        <div size="mini" type="primary" @click="backMap()" class="btn">概览</div>
        <div id="mapcontainer"></div>
      </div>
      <div v-else>
        <dv-border-box-11 title="卫星地图" class="borders" :color="['#9bcffa', '#266484']">
          <maps :locations="locations" @closeD="closeMap" class="mapBox"></maps>
        </dv-border-box-11>
        <!-- 遮罩层 -->
        <div class="fullScreen"></div>
      </div>
    </div>

    <!-- 设备状态指示 -->
    <div class="deviceStatus">
      <span style="font-weight:600;">
        设备状态：&nbsp;
      </span>
      <span>正常</span>
      <span
        style="width:12px;height:12px;background:#32ff7e;display:inline-block;border-radius:50%;line-heidht:40px;margin: 0 10px 0 5px"></span>

      <span>报警</span>
      <span
        style="width:12px;height:12px;background:#ff3838;display:inline-block;border-radius:50%;line-heidht:40px;margin: 0 10px 0 5px"></span>

      <span>故障</span>
      <span
        style="width:12px;height:12px;background:#ff9f1a;display:inline-block;border-radius:50%;line-heidht:40px;margin: 0 10px 0 5px"></span>

      <span>失联</span>
      <span
        style="width:12px;height:12px;background:#aaa69d;display:inline-block;border-radius:50%;line-heidht:40px;margin: 0 10px 0 5px"></span>

      <span>单位</span>
      <span
        style="width:12px;height:12px;background:#748ffc;display:inline-block;border-radius:50%;line-heidht:40px;margin: 0 10px 0 5px"></span>
      <!-- return params.data.status == '正常' ? '#32ff7e' : params.data.status == '故障' ? '#ff9f1a' : params.data.status == '报警' ? '#ff3838' : params.data.status == '失联' ? '#aaa69d' : '#32ffe4'; -->
      <div>
        <span style="font-weight:600">当前位置：</span>
        <span class="address"> &nbsp;{{ currentAdd }}</span>
      </div>
      <div style="display:flex">
        <div style="font-weight:600">设备数量：
          <span class="num">{{ deviceNumber }} &nbsp;</span>
          <span style="font-weight:400">个</span>
        </div>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <div style="font-weight:600" v-if="companyNumber != 0">单位数量：
          <span class="num">{{ companyNumber }} &nbsp;</span>
          <span style="font-weight:400">个</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyMessage from '@/api/managementApi/CompanyMessage'

import companySystem from "@/api/managementApi/CompanyInfo";

import BaseData from '@/assets/config/BaseData'
// 导入卫星地图组件
import MapIocli from '@/components/cloudPlatform/Center/MapIocli'
// 导入城市列表
import mapCity from '@/assets/config/mapCity'

// 所有设备
import deviceTypeAboutMap from "@/api/manageApi/DeviceTypeAboutMap.js";

let echarts = require("echarts/lib/echarts");
import $ from "jquery";
import Maps from '../cloudPlatform/Center/Maps.vue'
require("echarts/lib/chart/map");
require("echarts/map/js/china");

export default {
  name: "mapChart",
  components: {
    maps: MapIocli
  },
  data() {
    return {
      // size: 90,
      // sizes: '',
      // lf: 50,
      // lfs: '50',
      // tb: 50,
      // tbs: '50',
      // zoom: 0.8,
      // 设备总数
      deviceNumber: 0,
      toggle: true,
      // 要跳转的地方名
      locations: '',
      currentAdd: '中国',
      // 所有设备 markers
      markersOfDevice: [],
      // 所有点位
      allMarks: [],
      //   公司点位
      markersOfCompany: [],
      //   公司点位数量
      companyNumber: 0,

      // 设备详情显示
      dynamicProperties1: [],

      iconList: {
        yangan: require("../../assets/img/icon/yangan.png"),
        wengan: require("../../assets/img/icon/wengan.png"),
        shoubao: require("../../assets/img/icon/shoubao.png"),
        shenguang: require("../../assets/img/icon/shenguang.png"),
        shuchu: require("../../assets/img/icon/shuchu.png"),
        default: require("../../assets/img/icon/default.png"),
        yali: require("../../assets/img/icon1/yali.png"),
        xinhao: require("../../assets/img/icon1/xinhao.png"),
        shebei: require("../../assets/img/icon1/shebei.png"),
        jingai: require("../../assets/img/icon1/jingai1.png"),
        gonglv: require("../../assets/img/icon1/gonglv.png"),
        dianya: require("../../assets/img/icon1/dianya.png"),
        dianliu: require("../../assets/img/icon1/dianliu.png"),
        dianchidianya: require("../../assets/img/icon1/dianchidianya.png"),
      },
      // 所有公司
      companySystemList: []
    };
  },
  created() {
    // console.log(Object.keys(mapCity.provinces));
  },
  mounted() {
    this.queryMapsEquipment()
  },
  methods: {
    // // 地图缩放
    // suofang(param) {
    //   if (param == 'fangda') {
    //     if (this.size < 200) {
    //       this.size += 10
    //       this.sizes = this.size.toString() + '%'
    //       this.mapChart()
    //     }
    //   } else {
    //     if (this.size > 80) {
    //       this.size -= 10
    //       this.sizes = this.size.toString() + '%'
    //       this.mapChart()
    //     }
    //   }
    // },
    // // 还原位置
    // reset() {
    //   this.lfs = '50'
    //   this.tbs = '50'
    //   this.mapChart()
    // },
    // // 地图偏移
    // offsetOfMap(param) {
    //   if (param == 'top') {
    //     this.lf += 10
    //     this.lfs = this.lf.toString()
    //     this.mapChart()
    //   } else if (param == 'bottom') {
    //     this.lf -= 10
    //     this.lfs = this.lf.toString()
    //     this.mapChart()
    //   } else if (param == 'left') {
    //     this.tb += 10
    //     this.tbs = this.tb.toString()
    //     this.mapChart()
    //   } else if (param == 'right') {
    //     this.tb -= 10
    //     this.tbs = this.tb.toString()
    //     this.mapChart()
    //   }
    // },

    //改变设备状态
    // changeDeviceStatus(data) {
    //   for (const i in this.markersOfDevice) {
    //     if (data.deviceId == this.markersOfDevice[i].deviceId) {
    //       let obj = JSON.parse(JSON.stringify(this.markersOfDevice[i]))
    //       obj.status = data.value
    //       this.$set(this.markersOfDevice, i, obj)
    //     }
    //   }
    // },
    // 查询所有设备
    queryMapsEquipment() {
      // 查设备点位
      deviceTypeAboutMap.getAllDeviceTypeAboutMap().then((res) => {
        this.markersOfDevice = res.data.data.list;
        this.deviceNumber = res.data.data.list.length
        // 查公司点位
        CompanyMessage.getAllPositionCompany().then(res => {
          this.markersOfCompany = res.data.data.companyList
          this.companyNumber = this.markersOfCompany.length

          companySystem.getCompanyInfo().then(res => {
            this.companySystemList = res.data.data.list
            for (const item of this.markersOfDevice) {
              this.allMarks.push(item)
            }
            for (const item of this.markersOfCompany) {
              item.lng = Number(item.lng)
              item.lat = Number(item.lat)
              item.value = []
              item.value.push(Number(item.lng))
              item.value.push(Number(item.lat))
              this.allMarks.push(item)
              // echart地图公司id转文字
              for (let i = 0; i < this.companySystemList.length; i++) {
                if (item.ciId == this.companySystemList[i].id) {
                  item.ciId = this.companySystemList[i].name;
                }
              }
            }
            this.markersOfDevice = this.allMarks

            this.mapChart();

          })
        })
      })
    },

    viewMap() {
      this.toggle = false
      this.locations = '大连市'
    },

    // 返回全国视图
    backMap() {
      this.currentAdd = "中国";
      this.$options.methods.mapChart(true);
      this.mapChart()
    },

    closeMap(data) {
      this.toggle = data;
      this.$nextTick(() => {
        this.mapChart()
        this.currentAdd = "中国";
      })
      this.queryMapsEquipment()
    },

    // 配置渲染map
    mapChart() {
      let _this = this;
      let myChart = echarts.init(document.getElementById("mapcontainer"));

      window.addEventListener("resize", () => {
        myChart.resize();
      });

      initEcharts('china')
      function initEcharts(map) {
        let option = {
          tooltip: {
            show: false,
            fontSize: 22,
            backgroundColor: "rgba(255,255,255, 1)",
          },
          geo: {
            // zoom: _this.zoom,
            zoom: 1,
            map: map,
            roam: true,
            layoutCenter: ['50%', '45%'],//位置  左右/上下
            layoutSize: '95%',//大小
            // layoutCenter: [_this.lfs + '%', _this.tbs + '%'],//位置
            // layoutSize: _this.sizes,//大小

            scaleLimit: {
              min: 0,
              max: 10,
            },
            //图形上的文本标签，可用于说明图形的一些数据信息
            label: {
              normal: {
                show: true,
                fontSize: "15",
                color: "rgb(200,200,200)",
              },
              emphasis: {
                show: true,
                color: "rgb(61, 231, 201)",
              }
            },
            //地图区域的多边形 图形样式，有 normal 和 emphasis 两个状态
            itemStyle: {
              //normal 是图形在默认状态下的样式；
              normal: {
                areaColor: 'rgba(23,28,51,0.5)',
                borderColor: 'rgba(46,133,243,0.5)',
                borderWidth: 1.3,
                shadowBlur: 5,
                // shadowColor: 'rgb(53, 78, 107)',
                // shadowOffsetX: 7,
                // shadowOffsetY: 6,

                shadowColor: '#000',
                shadowOffsetX: 0,
                shadowOffsetY: 15,
                // opacity: 0.2,
              },
              //emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
              emphasis: {
                areaColor: 'rgba(46,133,243,0.6)',
                borderWidth: 1.6,
                shadowBlur: 25,
              }
            }
          },
          series: [
            {
              data: _this.markersOfDevice,
              showAllSymbol: true,
              symbolSize: 10,

              // 点图片样式   
              // symbol: function (params, params1, params2) {
              //   return "image://" + BaseData.baseUrl + params1.data.iconurl;
              //   let myGeo = new BMapGL.Geocoder();
              //   myGeo.getLocation(new BMapGL.Point(params[0], params[1]), function (result) {
              //     if (result) {
              //       let ccc = result.addressComponents.province.slice(0, (result.addressComponents.province.length - 1))
              //     }
              //   })
              // },
              // symbol: 'pin',
              // symbol: 'image://' + require('@/assets/images/bgt.jpg'),

              type: 'effectScatter', // 点
              coordinateSystem: 'geo',
              legendHoverLink: false,
              zlevel: 8,
              rippleEffect: { // 涟漪特效
                period: 3, // 动画时间，值越小速度越快
                brushType: 'fill', // 波纹绘制方式 stroke, fill
                scale: 3 // 波纹圆环最大限制，值越大波纹越大
              },

              //   聚合标签样式
              label: {
                normal: {
                  show: false,
                  position: "top",
                  fontSize: "14",
                  color: '#fff',
                  offset: [0, 18], // 偏移设置
                  formatter: function (params) { // 圆环显示文字
                    if (params.data.ciId) {
                      return params.data.ciId
                    } else {
                      return params.data.typeName
                    }
                  },
                },
                emphasis: {
                  show: true,
                  fontSize: "14",
                  color: "#fed330",
                }
              },

              itemStyle: {
                normal: {
                  show: true,
                  //   根据设备状态显示点位颜色
                  color: function (params) {
                    if (params.data.status != null && params.data.status != undefined && params.data.status != "") {
                      let status = params.data.status == '正常' ? '#32ff7e' :
                        (params.data.status.indexOf("故障") != -1
                          && params.data.status.indexOf("无") == -1
                          && params.data.status.indexOf("解除") == -1) ? '#ff9f1a' :
                          (params.data.status.indexOf("报警") != -1
                            && params.data.status.indexOf("无") == -1
                            && params.data.status.indexOf("解除") == -1) ? '#ff3838' :
                            params.data.status == '失联' ? '#aaa69d' : '#748ffc';
                      return status;
                    } else {
                      return '#748ffc';
                    }
                  }
                }
              },
            },
          ]
        };
        myChart.setOption(option, true);
      }
      //定义全国省份的数组
      var provinces = mapCity.provinces;
      // 市
      var cityMap = mapCity.cityMap;
      // 区
      var Mapian = mapCity.Mapian;

      var that = this;

      // 指定显示省份
      showProvince('liaoning', '辽宁');
      myChart.on("click", (param) => {
        if (param.name in provinces) {

          // 判断点位是否在对应的省
          //   let myGeo = new BMapGL.Geocoder();
          //   myGeo.getLocation(new BMapGL.Point(116.405185, 39.911743), function (result) {
          //     if (result) {
          //       console.log(result);
          //     }
          //   })

          // 处理省模块
          let names = param.name;
          for (let key in provinces) {
            if (names == key) {
              showProvince(provinces[key], key);
              break;
            }
          }
        }
        else if (param.name in cityMap) {

          // 处理市模块
          let names = param.name;

          for (let key in cityMap) {
            if (names == key) {
              showCitys(cityMap[key], key);
              break;
            }
          }
        } else if (param.name in Mapian) {

          // 处理市模块
          let names = param.name
          this.locations = names;
          console.log(this.locations);
          this.toggle = false;
        }
      });
      //展示对应的省
      _this = this
      function showProvince(eName, param) {
        _this.currentAdd = param
        $.getJSON(`/map/province/${eName}.json`, data => {
          that.$echarts.registerMap(param, data);
          initEcharts(param);
        })
      }
      function showCitys(cName, param) {
        _this.currentAdd = param
        // 显示县级地图
        $.getJSON(`/map/city/${cName}.json`, data => {
          that.$echarts.registerMap(param, data);
          initEcharts(param);
        })
      }
      // showC('对应区/县的地级编号')
      function showC(cName, param) {
        // 显示县级地图
        $.getJSON(`/map/county/${cName}.json`, data => {
          that.$echarts.registerMap(param, data)
          initEcharts(param)
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 弹框遮罩
.fullScreen {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(3, 7, 24, 0.6);
  position: absolute;
  z-index: 3;
}

.container {
  width: 100vw;
  margin: 0 auto;
}

#mapcontainer {
  // *****
  box-sizing: border-box;
  width: 100%;
  height: 1000px;
  // *****
  // width: 100vw;
  // height: 100vh;
  // margin: 3vh auto 0;
  overflow: hidden;
}

.btn {
  position: absolute;
  //   top: 16%;
  top: 2%;
  right: 26%;
  z-index: 1;
  // border: solid #009999 1px;
  background: linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) right bottom,
    linear-gradient(#00ffff, #00ffff) right bottom;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background-color: rgba(0, 66, 66, 0.4);
  color: #ffffff;
  font-family: "宋体";
  color: #00ffff;
  font-size: 16px;
  padding: 8px 12px;
  animation: breathing 1s linear infinite alternate;

  cursor: pointer;
}

.btn:hover {
  background-color: rgba(0, 255, 255, 1);
  color: #333;
}

// 呼吸灯
.num {
  color: rgb(214, 245, 128);
  margin: 0 5px;
  font-size: 18px;
  font-weight: 600;
  animation: breathing 1s linear infinite alternate;
}

@keyframes breathing {
  0% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

.staticValBox {
  width: 100%;
  font-size: 18px;
  color: #73e5bf;

  span:nth-child(1) {
    display: inline-block;
    width: 33%;
    padding: 10px 10px;
  }
}

.device-settings {
  margin: 0% 5% 1% 2%;
}

.borders {
  width: 70vw;
  height: 75vh;
  padding: 45px 15px 10px 15px;
  margin: 5vh auto 0;
  z-index: 10;
  font-family: "宋体";
}

.mapBox {
  border-radius: 20px;
  width: 70vw;
}

.deviceStatus {
  line-height: 40px;
  position: absolute;
  top: 1%;
  left: 26%;
  padding: 5px;
  height: 120px;

  background: linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) right top,
    linear-gradient(#00ffff, #00ffff) left top,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) left bottom,
    linear-gradient(#00ffff, #00ffff) right bottom,
    linear-gradient(#00ffff, #00ffff) right bottom;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background-color: rgba(0, 66, 66, 0.4);
}

.reset {
  position: absolute;
  top: 16%;
  right: 31.8%;
  font-size: 32px;
  cursor: pointer;
  color: #86f7d1;
  z-index: 3;
}

.reset:hover {
  color: #68acec;
}

.btns1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  user-select: none;
  z-index: 2;
  position: absolute;
  top: 13%;
  right: 30%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  // border: 1px solid red;

  // 旋转角度
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);

  /* Opera */
  span {
    box-sizing: border-box;
    width: 50%;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    cursor: pointer;
    transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    /* IE 9 */
    -moz-transform: rotate(315deg);
    /* Firefox */
    -webkit-transform: rotate(315deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(315deg);
    /* Opera */
  }

  span:hover {
    border-radius: 50%;
    color: #68acec;
  }

  .icon {
    color: #86f7d1;
    font-size: 26px;
  }
}

.btns {
  // background: red;
  user-select: none;
  z-index: 2;
  position: absolute;
  top: 10%;
  right: 30.7%;

  span {
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }

  span:hover {
    color: #68acec;
  }

  .icon {
    color: #86f7d1;
    font-size: 26px;
  }
}
</style>

<template>
  <div id="app" v-loading="loading" :element-loading-text="'3D模型获取中：' + rate + '%'"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="header">
      <div class="rows">{{ headerInfo.title }}</div>
      <div class="rows">Virtual reality 3D scene</div>
    </div>

    <!-- 公司名称 -->
    <div class="showTag">
      <!-- 纯净模式 -->
      <el-tooltip effect="light" content="纯净模式" placement="bottom">
        <i class="icon iconfont icon-iotechquanping" style="right:20%" @click="closeOtherWin"></i>
      </el-tooltip>
      <!-- 打开地图 -->
      <!-- <el-tooltip effect="light" content="查看地图" placement="bottom">
        <i class="icon iconfont icon-iotechxiazai20" @click="openMap"></i>
      </el-tooltip> -->
      <!-- 动画 -->
      <el-tooltip effect="light" content="开启 / 关闭动画" placement="bottom">
        <i class="icon iconfont icon-iotechxuanzhuan" @click="openAnimate"></i>
      </el-tooltip>
      <!-- 关闭、打开标签 -->
      <el-tooltip effect="light" content="关闭 / 显示标签" placement="bottom">
        <i class="icon iconfont icon-iotech24gf-tags3" @click="showFlag"></i>
      </el-tooltip>
      <!-- 退出 -->
      <el-tooltip effect="light" content="退出" placement="bottom">
        <i class="icon iconfont icon-iotechfanhui" style="right:20%" @click="$router.push('select')"></i>
      </el-tooltip>
    </div>

    <!-- 查看地图弹框 -->
    <!-- <el-dialog :fullscreen="true" :visible.sync="showMap" width="width" :before-close="closeMap" class="maps" style="padding:0px;margin:0px">
      <div style="width:100%;height:100">
        <MapOf />
      </div>
    </el-dialog> -->

    <company-list :meshList="meshList" v-if="closeWin"></company-list>

    <div class="right" v-if="closeWin">
      <div class="part1">
        <div class="part1-item1">
          <div>七天异常记录</div>
          <div>Seven-day anomaly record.</div>
        </div>
        <div style="width:100%;height:260px;padding:5px 10px;box-sizing:border-box">
          <RightBottom :getAbnormalInfo="getAbnormalInfo" v-if="showRecord" />
        </div>
      </div>

      <div class="part1">
        <div class="part1-item1">
          <div>报警设备占比</div>
          <div>The proportion of alarm devices</div>
        </div>
        <!-- <div style="width:100%;height:260px;padding:5px 10px;box-sizing:border-box;">
          <div style="width:100%;height:100%;">
            <LeftBottom :alarmList="alarmList" v-if="showRite"></LeftBottom>
          </div>
        </div> -->
        <div style="width:100%;height:260px;padding:5px 10px;box-sizing:border-box">
          <Pie :alarmList="alarmList" v-if="showRite" />
        </div>
      </div>

      <div class="part1">
        <div class="part1-item1">
          <div>报警记录</div>
          <div>Alarm number statistics</div>
        </div>
        <Screen></Screen>

        <!-- <div style="width:100%;height:260px;padding:5px 10px;box-sizing:border-box">
          <Pie :alarmList="alarmList" v-if="showRite" />
        </div> -->
      </div>
    </div>

    <!-- 下面信息栏 -->
    <!-- <div class="bottom" v-if="closeWin">
      <div class="demo">
        <div class="part1-item1">
          <div>XXXX</div>
          <div>Alarm number statistics</div>
        </div>
      </div>
      <div class="scroll">
        <div class="part1-item1">
          <div>报警记录</div>
          <div>Alarm number statistics</div>
        </div>
        <Screen></Screen>
      </div>
    </div> -->

    <!-- 点位 -->
    <div ref="webgl">
      <!-- v-if="flag1" -->
      <div transition name="el-zoom-in-center">
        <mark-coord v-if="flag" :meshList="meshList" @yeye="openDialog"></mark-coord>
      </div>
    </div>

    <!-- 公司信息 -->
    <!-- <div class="dialogOfComp">
    </div> -->

    <el-dialog :title="companyName" :visible.sync="showCompanyInfo" width="45%" :before-close="closeDeviceInfo"
      style="background:rgba(0,0,0,0.5)">
      <div style="height:400px">
        <el-table :data="companyDeviceList" style="width: 100%" height="400">
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column prop="id" label="设备编号" align="center">
          </el-table-column>
          <el-table-column prop="company" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="address" label="安装位置" align="center">
          </el-table-column>
          <el-table-column prop="type" label="设备类型" align="center">
          </el-table-column>
          <!-- <el-table-column prop="system" label="所属系统" align="center">
          </el-table-column> -->
          <el-table-column fixed="right" label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="primary" size="mini">查看CRT</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :background="true" :current-page.sync="current" :total="total"
          layout="total, prev, pager, next, jumper" @current-change="pagination">
        </el-pagination>
      </div>
      <div slot="footer">
        <!-- <el-button @click="closeDeviceInfo">关 闭</el-button> -->
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 七天异常记录
import RightBottom from '@/components/cloudPlatform/Right/RightBottom'
import alarmRecord from "@/api/manageApi/DeviceHistoryStatus";
// 报警占比
import Pie from "@/components/echarts/pie.vue";
import deviceCachValue from "@/api/manageApi/DeviceCachValue";
// 标题栏
import user from "@/api/manageApi/User";
import AccountManage from '@/api/manageApi/AccountManage'
// echar地图
// import MapOf from '@/components/cloudPlatform/Center/MapOf'
// import MapChart from '@/components/echarts/mapChart.vue'
// 加载异常数据组件
import LeftBottom from '@/components/cloudPlatform/Left/LeftBottom'
// 异常设备记录滚动条
import Screen from "@/components/echarts/ScreenScroll.vue";

// 查所有公司
import Company from '@/api/managementApi/CompanyInfo'

import HomePage from '@/api/manageApi/HomePage'

import * as echarts from 'echarts';

// crt
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";

import { renderer, scene, camera, controls } from "@/components/three/threejs/ThreeBuildings.js";
import CompanyList from '@/components/three/HTMLTag/CompanyList'
import * as THREE from 'three'

import { LoadingManager } from 'three'

import MarkCoord from '@/components/three/HTMLTag/MarkCoord'
// 加载进度条
import {
  ColladaLoader
} from 'three/examples/jsm/loaders/ColladaLoader.js';
// 加载模型
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import BaseData from '@/assets/config/BaseData'

import {
  RGBELoader
} from "three/examples/jsm/loaders/RGBELoader"
import Bus from '@/components/three/HTMLTag/bus'
import Limits from '@/api/manageApi/Limits.js'

export default {
  components: {
    MarkCoord,
    CompanyList,
    RightBottom,
    Pie, LeftBottom,
    Screen
  },
  data() {
    return {
      mashName: '',
      loading: true,
      rate: 0,
      // 七天异常记录
      getAbnormalInfo: [],
      showRecord: false,
      // 报警占比
      alarmList: [],
      showRite: false,
      // 标题栏
      headerInfo: {},

      // 显示公司信息
      showCompanyInfo: false,
      companyName: '',

      // 显示地图弹框
      showMap: false,

      // 关闭其他窗口
      closeWin: true,

      // 查所有公司
      allCompany: [],

      baseUrl: BaseData.baseUrl,
      // 加载进度1
      // loadingProgress: null,

      flag: false,

      meshList: [],
      companyNameList: [],
      // 存在异常设备的公司
      abnormalCompany: [],

      companyId: '',
      current: 1,
      limit: 10,
      total: 0,
      // 公司下的设备集合
      companyDeviceList: [],
      // 单位下设备总数
      deviceNumberList: [],

      ids: '',

      group: null,
      socketInfo: [],
    }
  },
  watch: {
    socketInfo(newVal) {
      // 报警弹框刷新数据
      if (newVal) {
        console.log(newVal)
        // this.$refs.webgl.appendChild(renderer.domElement);
        this.loadData()   // 重新加载3D场景
        this.getAbnormal()    // 刷新异常数据
        this.showFlag()     // 显示标签
        this.openAnimate()  // 开启动画

      }
    }
  },
  created() {

    Bus.$on("socket", (data) => {
      this.socketInfo = data
    })

    this.getUserInfo()
    this.getAllCompany()
    this.getThreePath()
    this.getDevicenumber()
  },
  mounted() {
    this.getAbnormal()
    this.lodeThree()
  },
  methods: {
    // 查所有公司
    async getAllCompany() {
      const res = await Company.getCompanyInfo()
      this.allCompany = res.data.data.list;

      // this.showFlag()

    },
    // 七天异常记录
    async getAbnormal() {
      const { data: res } = await alarmRecord.getSevenHistoryCounts()
      // console.log(res);
      this.getAbnormalInfo = res.data;
      this.showRecord = true;
    },
    // 报警占比
    async getDevicenumber() {
      const { data: res } = await deviceCachValue.getDevicenumber()
      this.allDevice = res.data;
      this.showRite = true
      this.alarmList = [
        {
          title: "报警",
          value: res.data.baojin,
          deviceName: "报警",
          icon: 'icon-iotechalarm',
        },
        {
          title: "故障",
          value: res.data.guzhang,
          deviceName: "故障",
          icon: 'icon-iotechguzhangshenbao',
        },
        {
          title: "失联",
          value: res.data.shilian,
          deviceName: "失联",
          icon: 'icon-iotechshilianyujing',
        },
      ];
    },
    // 标题栏
    async getUserInfo() {
      const { data: res } = await user.loginUserInfo()
      this.users = res.data.userName
      if (this.users !== null || this.users !== undefined) {
        const { data: title } = await AccountManage.getShowsByUserName(this.users)
        this.platformInfo = title.data.data
        if (this.platformInfo.platformName == undefined) {
          this.headerInfo.title = '消防物联网监控平台';
        } else {
          this.headerInfo.title = this.platformInfo.platformName
        }
      }
    },
    // 查看CRT
    handleClick(param) {
      crtDeviceCoordinate.getCoordinateByDeviceId(param.id).then((res) => {
        if (res.data.message != "失败") {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
              address: 'showPage',
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },
    // 加载文件
    lodeThree() {
      // 渲染模型
      this.$refs.webgl.appendChild(renderer.domElement);

      // 设置环境光
      const light = new THREE.AmbientLight(0xffffff, 0.3); // soft white light
      scene.add(light);
      // 直线光  
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1.1);
      directionalLight.position.set(10, 10, 10)
      scene.add(directionalLight);
      // 点击事件
      // renderer.domElement.addEventListener('click', event => {
      //   // 实例化处理光线投射的类
      //   const raycaster = new THREE.Raycaster()
      //   // 获取鼠标坐标
      //   const {
      //     offsetX,
      //     offsetY
      //   } = event;
      //   const x = offsetX / renderer.domElement.clientWidth * 2 - 1
      //   const y = -(offsetY / renderer.domElement.clientHeight * 2) + 1;
      //   const moustPoint = new THREE.Vector2(x, y);
      //   raycaster.setFromCamera(moustPoint, camera)
      //   const intersects = raycaster.intersectObjects(scene.children)
      //   // 建筑物事件
      //   // console.log(this.companyNameList);
      //   // console.log(intersects[0]);
      //   if (this.companyNameList.includes(intersects[0].object.parent.name)) {
      //     if (intersects[0].object.parent.name !== '船.001' &&
      //       intersects[0].object.parent.name !== '船' &&
      //       intersects[0].object.parent.name !== '附体' &&
      //       intersects[0].object.parent.name !== 'G-__594408') {

      //       // 弹窗显示的单位名称
      //       this.companyName = intersects[0].object.parent.userData.name
      //       console.log(this.companyName);
      //       this.showCompanyInfo = true
      //       if (this.flag) {
      //         this.flag = !this.flag
      //       }
      //       console.log(this.companyName);
      //       // 根据公司id查设备列表
      //       // this.getDeviceListById(this.companyName)
      //     }
      //   }
      // })
    },
    // 关闭所有窗口
    closeOtherWin() {
      this.closeWin = !this.closeWin
    },
    // 打开地图
    openMap() {
      this.showMap = true
      if (this.flag) {
        this.flag = !this.flag
      }
    },
    // 打开、关闭动画
    openAnimate() {
      controls.autoRotate = !controls.autoRotate
    },
    // 关闭地图弹框
    closeMap() {
      this.showMap = false
      if (this.flag) {
        this.flag = !this.flag
      }
    },
    // 关闭公司设备信息弹框
    closeDeviceInfo() {
      this.current = 1
      this.showCompanyInfo = false
      if (this.flag) {
        this.flag = !this.flag
      }
      this.flag = !this.flag
    },
    // 显示隐藏标签
    showFlag() {
      this.flag = !this.flag
    },

    // 子组件标签点击传参
    openDialog(val) {
      if (val) {
        this.companyName = val
        this.showCompanyInfo = true
        if (this.showCompanyInfo) {
          this.flag = !this.flag
        }
        this.getDeviceListById(val)
      }
    },



    // 根据公司id查设备列表
    async getDeviceListById(ciId) {
      console.log(ciId);
      for (let i = 0; i < this.allCompany.length; i++) {
        if (ciId == this.allCompany[i].name) {
          this.ids = this.allCompany[i].id
        }
      }
      const res = await HomePage.getDeviceListByCiId(this.ids, this.current, this.limit)
      this.companyDeviceList = res.data.data.list
      this.total = res.data.data.total
    },
    // 分页
    pagination() {
      this.getDeviceListById()
    },

    //查询账号信息下的3D路径
    async getThreePath(){
      let username = this.$store.getters.userName
      const res = await Limits.getInformation(username)
      this.mashName = res.data.data.info.path
      console.log(this.mashName);
      this.loadData()
    },

    // 加载数据
    async loadData() {
      // 加载 hdr 图片
      let texturePath = 'files/image/sky3.hdr'
      const rgbaLoader = new RGBELoader(new LoadingManager())
      rgbaLoader.loadAsync(texturePath).then(texture => {
        texture.mapping = THREE.EquirectangularReflectionMapping
        scene.background = texture
        scene.environment = texture // 物体折射环境
      }, xhr => {
        console.log('百分比' + (xhr.loaded / xhr.total * 100) + '%');
        // let rate = (xhr.loaded / xhr.total * 100).toFixed(2)
      })


      // 加载 模型
      this.group = new THREE.Group()
      const mashPath = `files/mesh/${this.mashName}.glb`
      console.log(this.mashName);
      console.log(mashPath);
      // 根据模型设置角度
      // 大同一园
      if(this.mashName == 'DTYY'){
        camera.position.set(-700, 101, 300)
      }
      // 大同二园
      else if(this.mashName == 'DTEY'){
        camera.position.set(660, -30, -900)
      } 
      // 左岸公社
      else if(this.mashName == 'ZAGS'){
        camera.position.set(150, 20, -10)
      } 
      // 景泰小学
      else if(this.mashName == 'JTXX'){
        camera.position.set(50, 30, -100)
      } 
      // 河港六公司
      else if(this.mashName == 'LGS'){
        camera.position.set(-100, -120, 80)
      } 
      
      // 实例化 loader
      const loader = new GLTFLoader(new LoadingManager());
      // const loader = new GLTFLoader();

      const dracoLoader = new DRACOLoader();      // ***

      dracoLoader.setDecoderPath('/draco/');     // ***

      // dracoLoader.preload()  //初始化_initDecoder 解码器

      loader.setDRACOLoader(dracoLoader)

      // dracoLoader.setDecoderConfig({ type: 'js' })  //使用js方式解压

      loader.load(mashPath, gltf => {
        console.log(gltf);
        gltf.scene.castShadow = true
        this.meshList = gltf.scene.children
        // 获取模型名称
        // console.log(this.meshList);
        for (let i = 0; i < this.meshList.length; i++) {
          this.companyNameList.push(this.meshList[i].name)
        }
        this.group.add(gltf.scene)
        scene.add(gltf.scene);

        // 查单位下设备总数
        this.getAllDeviceNum()

      }, xhr => {
        // console.log('加载完成的百分比' + (xhr.loaded / xhr.total * 100) + '%');
        this.rate = (xhr.loaded / xhr.total * 100).toFixed(2)
        if (this.rate == 100) {
          this.loading = false
        }
      })
    },

    // 查询公司设备数量
    async getAllDeviceNum() {
      const res = await HomePage.getCiIdDeviceNumber()
      this.deviceNumberList = res.data.data.list

      for (let i = 0; i < this.allCompany.length; i++) {
        for (let j = 0; j < this.deviceNumberList.length; j++) {
          if (this.allCompany[i].id == this.deviceNumberList[j].ciId) {
            this.deviceNumberList[j].ciId = this.allCompany[i].name
          }
        }
      }

      // 查异常设备单位
      this.getCompanyAndDeviceStatus()

      // for (let i = 0; i < this.deviceNumberList.length; i++) {
      //   for (let j = 0; j < this.allCompany.length; j++) {
      //     if (this.deviceNumberList[i].ciId == this.allCompany[j].id) {
      //       this.deviceNumberList[i].ciId = this.allCompany[j].name
      //     }
      //   }
      //   for (let j = 0; j < this.meshList.length; j++) {
      //     if (this.deviceNumberList[i].ciId == this.meshList[j].userData.name) {
      //       this.meshList[j].userData.abnormalCount = this.deviceNumberList[i].abnormalCount
      //       this.meshList[j].userData.allCount = this.deviceNumberList[i].allCount
      //     }
      //   }
      // }
      // console.log(this.meshList);
    },

    // 查询异常设备
    async getCompanyAndDeviceStatus() {
      const res = await HomePage.getAbnormalCiIdList()
      this.abnormalCompany = res.data.data
      // console.log(this.abnormalCompany);
      this.transformInfo()
    },

    // id、汉字转换
    transformInfo() {
      for (let i = 0; i < this.allCompany.length; i++) {
        // 报警单位id转文字
        for (let j = 0; j < this.abnormalCompany.alarmCiIdSet.length; j++) {
          if (this.abnormalCompany.alarmCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.alarmCiIdSet[j] = this.allCompany[i].name
          }
        }
        // 故障单位id转文字
        for (let j = 0; j < this.abnormalCompany.faultCiIdSet.length; j++) {
          if (this.abnormalCompany.faultCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.faultCiIdSet[j] = this.allCompany[i].name
          }
        }
        // 失联单位id转文字
        for (let j = 0; j < this.abnormalCompany.lostInCiIdSet.length; j++) {
          if (this.abnormalCompany.lostInCiIdSet[j] == this.allCompany[i].id) {
            this.abnormalCompany.lostInCiIdSet[j] = this.allCompany[i].name
          }
        }

      }
      // console.log(this.abnormalCompany);
      // 判断当前单位是否在异常单位列表里   如果在，在userData里添加status属性
      this.getResult()
    },


    getResult() {
      // 报警
      // for(let i = 0; i < this.abnormalCompany.alarmCiIdSet.length; i++){
      //   for(let j = 0; j < this.meshList.length; j++){
      //     if(){

      //     }
      //   }
      // }
      for (let i = 0; i < this.meshList.length; i++) {
        if (this.abnormalCompany.alarmCiIdSet.includes(this.meshList[i].userData.name)) {
          this.meshList[i].userData.status = '1'    // 报警
        } else if (this.abnormalCompany.faultCiIdSet.includes(this.meshList[i].userData.name)) {
          this.meshList[i].userData.status = '2'    // 故障
        } else if (this.abnormalCompany.lostInCiIdSet.includes(this.meshList[i].userData.name)) {
          this.meshList[i].userData.status = '3'    // 失联
        }

        for (let j = 0; j < this.allCompany.length; j++) {
          if (this.meshList[i].userData.name == this.allCompany[j].name) {
            this.meshList[i].userData.ciId = this.allCompany[j].id
          }
        }

        for (let j = 0; j < this.deviceNumberList.length; j++) {
          if (this.meshList[i].userData.name == this.deviceNumberList[j].ciId) {
            this.meshList[i].userData.abnormalCount = this.deviceNumberList[j].abnormalCount
            this.meshList[i].userData.allCount = this.deviceNumberList[j].allCount
          }
        }
      }

      // console.log(this.deviceNumberList);
      // 传公司 id
      // for (let i = 0; i < this.allCompany.length; i++) {
      //   for (let j = 0; j < this.meshList.length; j++) {
      //     if (this.allCompany[i].name == this.meshList[i].userData.name) {
      //       this.meshList[i].userData.ciID = this.allCompany[i].id
      //     }
      //   }
      // }
      // console.log(this.meshList);
      // for (let i = 0; i < this.meshList.length; i++) {
      // }

      this.openAnimate()
      // this.showFlag()
    }


  },

  beforeDestroy() {
    controls.autoRotate = true;
    scene.clear();
    renderer.dispose();
  }

}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
}

.header {
  position: fixed;
  width: 100%;
  background: url("../../../assets/images/top.png") no-repeat;

  .rows {
    padding-left: 350px;
    color: #34f1d8;
    text-shadow: 1px 1px 1px #666;
  }

  .rows:nth-child(1) {
    margin: 10px 0;
    font-size: 26px;
    letter-spacing: 4px;
  }

  .rows:nth-child(2) {
    margin: 5px 0;
  }
}

.showTag {
  box-sizing: border-box;
  padding: 10px 0;
  position: absolute;
  top: 0;
  height: 42px;
  left: 65%;
  width: 345px;

  .icon {
    cursor: pointer;
    margin: 10px;
    font-size: 24px;
    color: #34e8d5;
  }

  .icon:hover {
    color: #8cffa9;
  }
}

.right {
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  padding: 0 15px 0 0;
  width: 24%;
  // width: 460px;
  height: 100%;
  background-image: linear-gradient(to left,
      rgba(49, 68, 137, 1),
      rgba(145, 187, 238, 0.2));
  position: absolute;
  top: 0;
  right: 0;

  .part1 {
    width: 100%;
    margin: 0 0 10px 0;
    height: 31%;
    padding: 5px 5px 5px 10px;

    .part1-item1 {
      width: 100%;
      background: url("../../../assets/images/banner.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
      box-sizing: border-box;
      padding: 0 0 0 50px;

      div:nth-child(1) {
        font-size: 22px;
        font-family: "宋体";
        font-weight: 600;
        letter-spacing: 2px;
        color: #00d2ff;
      }

      div:nth-child(2) {
        margin-top: 5px;
        font-size: 14px;
        letter-spacing: 2px;
        color: #2bc1eb;
      }
    }
  }
}

.bottom {
  width: 30%;
  height: 650px;
  position: absolute;
  bottom: 0;
  left: 0;

  .scroll {
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to right,
        rgba(49, 68, 137, 1),
        rgba(78, 79, 80, 0.2));

    .part1-item1 {
      width: 100%;

      div:nth-child(1) {
        padding: 10px 0 0 10px;
        font-size: 22px;
        font-family: "宋体";
        font-weight: 600;
        letter-spacing: 2px;
        color: #00d2ff;
      }

      div:nth-child(2) {
        padding: 0 0 0 10px;
        margin-top: 5px;
        font-size: 14px;
        letter-spacing: 2px;
        color: #2bc1eb;
      }
    }
  }

  .demo {
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to right,
        rgba(49, 68, 137, 1),
        rgba(78, 79, 80, 0.2));

    div:nth-child(1) {
      padding: 10px 0 0 10px;
      font-size: 22px;
      font-family: "宋体";
      font-weight: 600;
      letter-spacing: 2px;
      color: #00d2ff;
    }

    div:nth-child(2) {
      padding: 0 0 0 10px;
      margin-top: 5px;
      font-size: 14px;
      letter-spacing: 2px;
      color: #2bc1eb;
    }

    width: 100%;
    height: 50%;
    background: rgba(75, 73, 73, 0.4);
  }

  // background: url("../../../assets/images/banner.png") no-repeat;
  // background-image: linear-gradient(
  //   to right,
  //   rgba(49, 68, 137, 1),
  //   rgba(145, 187, 238, 0.5)
  // );
}

.loading {
  position: absolute;
  top: 80px;
  color: #000;
  font-size: 20px;
  border: 1px solid red;
}

.maps {
  background: url("../../../assets/images/dptBG.jpg") no-repeat;
  background-size: 100% 100%;
}

::-webkit-scrollbar {
  display: none;
}

.dialogOfComp {
  width: 600px;
  height: 500px;
  background: #000;
  z-index: 99;
}

// 加载背景
// /deep/.el-loading-mask{
//   background: url('../../../assets/images/loadingBG.jpg') no-repeat;
//   background-size: 100% 100%;
// }
</style>
<template>
  <div class="container-content">
    <!-- 背景图 -->

    <el-header class="header-bg bgclor" style="height: 80px" v-if="!showThree">
      <show-header></show-header>
    </el-header>

    <!-- 主页界面：默认显示的是地图或背景图片 -->

    <div v-if="!showThree">
      <transition name="el-fade-in-linear">
        <div id="overburden" v-if="platformInfo.pictureFlag == undefined">
          <MapChart />
        </div>
        <div v-else id="overburden">
          <MapChart v-if="platformInfo.pictureFlag == '0'" />
          <IndexCrtShow v-if="platformInfo.pictureFlag == '1'" />
        </div>
      </transition>
    </div>

    <!-- 点击切换按钮，切换为3D模型 -->
    <div v-else>
      <transition name="el-fade-in-linear">
        <three-buildings></three-buildings>
        <!-- 高德地图嵌入 three -->
        <!-- <VisualizationMap /> -->
      </transition>
    </div>

    <!-- 主页的菜单按钮 -->
    <div v-if="platformInfo.pictureFlag !== '1'">
      <div class="header-list" v-if="this.open == '1'">
        <div class="item">以科技前瞻</div>
        <div @click="toggleModules" class="three">{{ showName }}</div>
        <div class="item">赢安全未来</div>
      </div>
    </div>

    <el-container>
      <transition name="el-zoom-in-center" v-if="!showThree">
        <div class="box1">
          <div class="showtitilebg">
            <span class="icon"></span> 设备数量统计
          </div>
          <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
          <div class="deviceNum">
            <div
              class="item"
              v-for="(item, index) in msgList"
              :key="index"
              @click="bouncOfStat(item)"
            >
              {{ item.title }} <span>{{ item.num }}</span> 个
            </div>
          </div>
          <div style="margin-top: 20px">
            <div class="showtitilebg"><span class="icon"></span> 设备占比</div>
            <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
            <div style="height: 250px; width: 450px">
              <LeftCenter :leftCenter="leftCenter" v-if="showRate" />
            </div>
          </div>
        </div>
      </transition>

      <transition name="el-zoom-in-center" v-if="!showThree">
        <div class="box2">
          <div>
            <div class="showtitilebg"><span class="icon"></span>报警记录</div>
            <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
            <screen></screen>
          </div>
        </div>
      </transition>

      <transition name="el-zoom-in-center" v-if="!showThree">
        <div class="box3">
          <div class="showtitilebg"><span class="icon"></span>报警数量</div>
          <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
          <div title="点击查看详细设备信息">
            <div
              class="top-msg"
              style="padding: 0px 15px"
              v-for="(item, index) in alarmList"
              :key="index"
              @click="bouncOfStat(item)"
            >
              <span style="height: 10px; padding-left: 20px">
                <i
                  class="iconfont"
                  :class="item.icon"
                  style="font-size: 16px; font-weight: 600"
                ></i
                >&nbsp;
                {{ item.title }}
              </span>
              <span class="top-msg-value">{{ item.value }}</span>
              <span>个</span>
            </div>
          </div>
          <div style="margin-top: 60px">
            <div class="showtitilebg"><span class="icon"></span>报警占比</div>
            <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
            <div style="height: 300px">
              <Pie :alarmList="alarmList" />
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-zoom-in-center" v-if="!showThree">
        <div class="box5">
          <div class="showtitilebg">
            <span class="icon"></span>七天内异常记录
          </div>
          <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
          <RightBottom :getAbnormalInfo="getAbnormalInfo" v-if="showRecord" />
        </div>
      </transition>
    </el-container>
    <dv-border-box-10
      class="borders"
      v-if="closeWin"
      :color="['#3fe0da', '#3fe0da']"
    >
      <div class="dialog-title">
        <span v-text="dialogName + '设备'"></span>
      </div>
      <dv-decoration-3 style="width: 200px; height: 15px; margin-left: 20px" />
      <div>
        <DeviceInfo :message="currentDevice" @closeWindow="closeWindow" />
      </div>
    </dv-border-box-10>

    <!-- 遮罩层 -->
    <div class="fullScreen" v-if="closeWin"></div>
  </div>
</template>
<script>
import BaseData from "@/assets/config/BaseData";
import AccountManage from "@/api/manageApi/AccountManage";
import User from "@/api/manageApi/User";
import {
  ShowHeader,
  RightBottom,
  LeftCenter,
  DeviceInfo,
  MapChart,
  ThreeBuildings,
  IndexCrtShow,
  Pie,
  screen,
  VisualizationMap,
} from "@/components";
// 组件
// import ShowHeader from '@/components/deviceClass/ShowHeader'
// import RightBottom from '@/components/cloudPlatform/Right/RightBottom'
// import LeftCenter from '@/components/cloudPlatform/Left/LeftCenter'
// import DeviceInfo from "@/components/bounc/DeviceInfo";
// import MapChart from '@/components/echarts/mapChart.vue'
// import ThreeBuildings from '@/components/three/HTMLTag/ThreeBuildings'

// 首页crt点位展示
// import IndexCrtShow from '@/components/manage/IndexCrtShow'
// // import getTime from "../../utils/time.js";
// // 报警占比组件
// import Pie from "../components/echarts/pie.vue";
// // 报警记录组件
// import screen from "@/components/echarts/ScreenScroll.vue";
// // 可视化地图  three地图
// import VisualizationMap from '@/components/three/ThreeMap/VisualizationMap'

// 查设备数量
import deviceCachValue from "../api/manageApi/DeviceCachValue";
// 设备报警信息
import alarmRecord from "@/api/manageApi/DeviceHistoryStatus";
import Limits from '@/api/manageApi/Limits.js'

export default {
  name: "Show",
  props: [],
  components: {
    Pie,
    DeviceInfo,
    screen,
    MapChart,
    LeftCenter,
    RightBottom,
    ShowHeader,
    IndexCrtShow,
    ThreeBuildings,
    VisualizationMap,
  },

  data() {
    return {
      open:'',
      showName: "3D场景",
      activeId: 0,
      module1: true, // 默认显示四个模块
      showThree: false, // 显示 3D 图像
      drawer: true,
      platformInfo: [],
      closeWin: false,
      // 弹框标题
      dialogName: "",
      showRecord: false,
      showRate: false,
      // 根据这个查询当前点击的设备信息
      currentDevice: [],
      getAbnormalInfo: [],
      msgList: [],
      alarmList: [],
      allDevice: [],
      leftCenter: {},

      timer: null,
      users: "",

      moduleTypeList: [
        {
          id: 1,
          name: "模块",
        },
        {
          id: 2,
          name: "模块",
        },
        {
          id: 3,
          name: "3D场景",
        },
        {
          id: 4,
          name: "模块",
        },
        {
          id: 5,
          name: "模块",
        },
      ],
    };
  },
  created() {
    this.getUserInfo();
    this.$emit("routerIsShowFunction", true);
    this.getAbnormal();
    this.getHomeDeviceNumber(); //首页左上角设备数量显示
    this.getDevicenumber();

    this.timer = setInterval(() => {
      this.getDevicenumber();
    }, 5000);
  },
  mounted() {},

  methods: {
    // 主页模块切换
    toggleModules(value) {
      this.showThree = !this.showThree;
      if (this.showThree) {
        this.showName = "主页";
      } else {
        this.showName = "3D场景";
      }
    },

    async getUserInfo() {
      const { data: res } = await User.loginUserInfo();
      this.users = res.data.userName;
      this.getTitleText();
    },

    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then((res) => {
        this.platformInfo = res.data.data.data;
      });
      Limits.getInformation(this.users).then(res => {
        this.open = res.data.data.info.open
      })
    },

    turnTo(path) {
      this.$router.push({
        path: path,
        query: {
          way: "show",
        },
      });
    },
    // 七天异常记录
    async getAbnormal() {
      const { data: res } = await alarmRecord.getSevenHistoryCounts();
      this.getAbnormalInfo = res.data;
      this.showRecord = true;
    },
    // 设备统计 、 报警数量
    async getDevicenumber() {
      const { data: res } = await deviceCachValue.getDevicenumber();
      this.allDevice = res.data;
      this.alarmList = [
        {
          title: "报警",
          value: res.data.baojin,
          deviceName: "报警",
          icon: "icon-iotechalarm",
        },
        {
          title: "故障",
          value: res.data.guzhang,
          deviceName: "故障",
          icon: "icon-iotechguzhangshenbao",
        },
        {
          title: "失联",
          value: res.data.shilian,
          deviceName: "失联",
          icon: "icon-iotechshilianyujing",
        },
      ];
    },
    getHomeDeviceNumber() {
      deviceCachValue.getHomeDeviceNumber().then((res) => {
        //  设备统计列表
        this.msgList = [
          {
            title: "无线烟感",
            num: res.data.data.map.无线烟感,
            deviceName: "无线烟感",
          },
          {
            title: "主机监测",
            num: res.data.data.map.主机监测,
            deviceName: "主机监测",
          },
          {
            title: "水压水位",
            num: res.data.data.map.水压水位,
            deviceName: "水压水位",
          },
          {
            title: "视频联动",
            num: res.data.data.map.视频联动,
            deviceName: "视频联动",
          },
          {
            title: "安全用电",
            num: res.data.data.map.安全用电,
            deviceName: "安全用电",
          },
          {
            title: "燃气监测",
            num: res.data.data.map.燃气监测,
            deviceName: "燃气监测",
          },
          {
            title: "动环监测",
            num: res.data.data.map.动环监测,
            deviceName: "动环监测",
          },
          {
            title: "其它设备",
            num: res.data.data.map.其它设备,
            deviceName: "其它设备",
          },
        ];
        this.leftCenter.YGNumber = res.data.data.map.无线烟感;
        this.leftCenter.HZNumber = res.data.data.map.主机监测;
        this.leftCenter.syswNumber = res.data.data.map.水压水位;
        this.leftCenter.SPNumber = res.data.data.map.视频联动;
        this.leftCenter.electricityNumber = res.data.data.map.安全用电;
        this.leftCenter.fuelGasNumber = res.data.data.map.燃气监测;
        this.leftCenter.dhjcNumber = res.data.data.map.动环监测;
        this.leftCenter.QTNumber = res.data.data.map.其它设备;
        this.showRate = true;
      });
    },
    // 关闭弹框
    closeWindow(data) {
      this.closeWin = data;
    },
    //退出展示页
    exit() {
      this.$router.push("/Select");
    },
    // 弹框信息
    bouncOfStat(item) {
      this.currentDevice = item.deviceName;
      this.closeWin = true;
      this.dialogName = item.title;
    },
  },
  destroyed() {
    var that = this;
    that.$emit("routerIsIndexFunction", false);
    clearInterval(this.timer);
    this.$emit("routerIsShowFunction", false);
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "led regular";
  src: url("../assets/font/ds_digital/DS-DIGI.TTF");
}

// .header-list {
//   position: absolute;
//   width: 48%;
//   height: 100px;
//   bottom: 0px;
//   right: 510px;
//   z-index: 2;
//   border-bottom: 5px solid #042957;
//   border-radius: 5px;
//   user-select: none;
//   background-image: url("../assets/images/素材.png");
//   background-size: 100% 100%;
//   cursor: pointer;
//   letter-spacing: 3px;
//   span {
//     box-sizing: border-box;
//     display: inline-block;
//     width: 20%;
//     padding: 0 0 0 26px;
//     text-align: center;
//     line-height: 80px;
//     font-size: 18px;
//     font-family: "宋体";
//   }
//   span:nth-child(3) {
//     font-size: 22px;
//   }
//   span:hover {
//     color: #5decf1;
//   }
// }
.header-list {
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 100px;
  margin: 0 auto;
  background-image: url("../assets/images/素材.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;

  .item {
    width: 40%;
    height: 100px;
    color: #5decf1;
    font-family: "宋体";
    font-weight: 600;
    line-height: 140px;
    letter-spacing: 10px;
    font-size: 20px;
    text-align: center;
  }

  .three {
    width: 20%;
    height: 100px;
    font-size: 22px;
    color: #07e4e4;
    font-family: "宋体";
    text-align: center;
    line-height: 100px;
  }

  .three:hover {
    color: #7fffd4;
  }
}

.charts-model {
  position: absolute;
  top: 0;
}

.container-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

// 弹框遮罩
.fullScreen {
  width: 100vw;
  height: 100vh;
  background: rgba(3, 7, 24, 0.8);
  position: absolute;
  top: 0px;
  z-index: 1;
}

#overburden {
  width: 100%;
  height: 100%;
  position: absolute;
}

.box1 {
  position: absolute;
  top: 80px;
  width: 480px;
  height: 55%;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(8, 40, 77, 0.5);
  margin: 010px;
  border-radius: 35px;

  .deviceNum {
    display: flex;
    flex-wrap: wrap;

    .item {
      box-sizing: border-box;
      margin: 6px 0;
      font-size: 18px;
      letter-spacing: 1px;
      padding-left: 30px;
      color: #5decf1;
      width: 50%;
      cursor: pointer;

      span {
        font-size: 28px;
        display: inline-block;
        width: 80px;
        text-align: center;
        border-bottom: 1px dashed #439396;
        font-family: "led regular";
      }
    }

    .item:hover {
      color: #7fffd4;
      animation: breathing 1s linear infinite alternate;
    }
  }
}

.box2 {
  position: absolute;
  bottom: 0;
  width: 480px;
  height: 33%;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(8, 40, 77, 0.5);
  margin: 10px;
  border-radius: 35px;
}

.box3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 480px;
  height: 55%;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(8, 40, 77, 0.5);
  margin: 10px;
  border-radius: 35px;
}

.box5 {
  position: absolute;
  right: 0;
  top: 80px;
  width: 480px;
  height: 33%;
  overflow: hidden;
  box-sizing: border-box;
  background: rgba(8, 40, 77, 0.5);
  margin: 10px;
  border-radius: 35px;
}

.showtitilebg {
  font-size: 18px;
  padding: 18px 0 0 20px;
  font-weight: 700;
  color: rgb(129, 248, 242);
  text-shadow: 1px 5px 3px #000;
  letter-spacing: 5px;

  span {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    background: url("~@/assets/images/icon01.png") no-repeat;
    width: 30px;
    height: 25px;
  }
}

.top-msg {
  padding: 0 0 0 50px;
  margin: 3% 0;
  cursor: pointer;
}

.top-msg span {
  line-height: 30px;
  font-size: 1vw;
  color: aquamarine;
  text-shadow: 2px 2px 2px #000;
  letter-spacing: 2px;
  margin: 1px 0;
}

.top-msg span:nth-child(1) {
  display: inline-block;
  width: 55%;
}

.top-msg span:nth-child(2) {
  display: inline-block;
  width: 18%;
  height: 30px;
  text-align: center;
  border-bottom: 1px dashed #5decf1;
  margin-left: 20px;
  font-family: "led regular";
  font-size: 28px;
  color: #5decf1;
}

.top-msg:hover {
  animation: breathing 1s linear infinite alternate;
}

@keyframes breathing {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.top-msg span:nth-child(3) {
  margin-left: 30px;
}

.right-top-msg span {
  height: 35px;
  line-height: 35px;
  font-size: 20px;
  color: aquamarine;
}

.el-header {
  cursor: default;
}

.borders {
  position: absolute;
  box-sizing: border-box;
  top: 13vh;
  left: 23.2%;
  width: 53vw;
  height: 600px;
  z-index: 2;
  background: rgba(6, 6, 31, 0.8);
  border-radius: 20px;
  box-shadow: 1px 1px 10px #07e4e4;
}

.dialog-title {
  width: 20%;
  // text-align: center;
  font-size: 24px;
  margin: 20px 0 5px 25px;
  letter-spacing: 3px;
  color: #24f1e7;
}
</style>

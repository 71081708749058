<template>
  <div id="app">
    <!-- 标记 -->
    <!-- :class="
              abnormalCompany.alarmCiIdSet.includes(obj.name)?'alarm':
              abnormalCompany.faultCiIdSet.includes(obj.name)?'fault': 
              abnormalCompany.lostInCiIdSet.includes(obj.name)?'lost': 'tag'
              " -->

    <!-- :style="abnormalCompany.alarmCiIdSet.includes(obj.name)?'background: rgba(241, 64, 64, 0.5)':
    abnormalCompany.faultCiIdSet.includes(obj.name)?'background: rgba(245, 159, 61, 0.5)':
    abnormalCompany.lostInCiIdSet.includes(obj.name)?'background: rgba(78, 73, 73, 0.5)':'background: rgba(25, 223, 223, 0.2)'
    " -->

    <!-- :class="
              obj.alarm?'alarm':
              obj.fault?'fault': 
              obj.lost?'lost': 'tag'
              " -->

    <div :ref="index" :id="index" @click="handle(obj.userData.name)" @mousemove="over" @mouseleave="leave">
      <el-tooltip class="item" effect="light" placement="top-end">
        <div slot="content">
          <span>
            <b>{{obj.name}}</b>
          </span>
          <!-- <br>
          <span style="display:inline-block;margin-top:5px">
            设备状态：{{
              obj.userData.status == '1' ? '有报警设备':
              obj.userData.status == '2' ? '有故障设备':
              obj.userData.status == '3' ? '有失联设备': '设备正常'
            }}
          </span> -->
          <br>
          <span style="display:inline-block;margin-top:5px">
            设备总数：{{obj.userData.allCount}}
          </span>
          <br>
          <div style="display:flex">
            <span style="display:inline-block;margin:5px 20px 0 0">
              故障设备总数：{{obj.userData.abnormalCount}}
            </span>
            <span class="tip" style="display:inline-block;margin:5px 0 0 0" @click="toPage(obj.userData.ciId)">详情</span>
          </div>
        </div>

        <div style="font-weight:600;margin-bottom:5px" :class="
              obj.userData.status=='1'?'alarm':
              obj.userData.status=='2'?'fault': 
              obj.userData.status=='3'?'lost': 'tag'
              ">
          <span class="title">{{obj.name}}</span>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>

// 导入动画库
// import gsap from "gsap";
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { scene, controls } from '@/components/three/threejs/ThreeBuildings.js'
import Bus from '@/components/three/HTMLTag/bus'
// 设备状态和设备
import HomePage from '@/api/manageApi/HomePage'
// 查所有公司
import Company from '@/api/managementApi/CompanyInfo'
// 该组件多次复用标注三维场景中不同的对象
export default {
  props: ['x', 'y', 'z', 'index', 'name', 'obj'],
  data() {
    return {
      label: null,
      allCompany: [],
      abnormalCompany: [],
    }
  },
  created() {
  },

  mounted() {
    // 获取div元素(作为标签)
    var div = document.getElementById(this.index);
    // var div = this.$refs[this.index];
    //div元素包装为CSS2模型对象CSS2DRenderer
    this.label = new CSS2DObject(div);
    //避免HTML标签遮挡三维场景的鼠标事件 auto:可以点击标签, none 不可以点击标签
    div.style.pointerEvents = "auto";
    this.label.position.set(this.x, this.y, this.z)
    scene.add(this.label);//标签添加到场景中

  },


  methods: {
    handle(val) {
      // 触发绑定的事件，并向外传递参数。
      // alert(this.name)
      // Bus.$emit('openDialog', true)
      this.$emit('father', val)

    },

    over() {
      controls.autoRotate = false
    },

    leave() {
      controls.autoRotate = true
    },

    // 查所有公司
    // async getAllCompany() {
    //   const res = await Company.getCompanyInfo()
    //   console.log(res);
    //   this.allCompany = res.data.data.list;
    //   console.log(this.allCompany);
    //   for (let i = 0; i < this.allCompany.length; i++) {
    //     // 报警单位id转文字
    //     for (let j = 0; j < this.abnormalCompany.alarmCiIdSet.length; j++) {
    //       if (this.abnormalCompany.alarmCiIdSet[j] == this.allCompany[i].id) {
    //         this.abnormalCompany.alarmCiIdSet[j] = this.allCompany[i].name
    //       }
    //     }
    //     // 故障单位id转文字
    //     for (let j = 0; j < this.abnormalCompany.faultCiIdSet.length; j++) {
    //       if (this.abnormalCompany.faultCiIdSet[j] == this.allCompany[i].id) {
    //         this.abnormalCompany.faultCiIdSet[j] = this.allCompany[i].name
    //       }
    //     }
    //     // 失联单位id转文字
    //     for (let j = 0; j < this.abnormalCompany.lostInCiIdSet.length; j++) {
    //       if (this.abnormalCompany.lostInCiIdSet[j] == this.allCompany[i].id) {
    //         this.abnormalCompany.lostInCiIdSet[j] = this.allCompany[i].name
    //       }
    //     }
    //   }
    //   console.log(this.abnormalCompany);
    // },
    // 公司设备和设备状态
    // async getCompanyAndDeviceStatus() {
    //   const res = await HomePage.getAbnormalCiIdList()
    //   this.abnormalCompany = res.data.data
    //   // console.log(this.abnormalCompany);
    // },

    // // 公司设备数量
    // async getAllDeviceNum() {
    //   const res = await HomePage.getCiIdDeviceNumber()
    //   console.log(res);
    // },

    toPage(val) {
      this.$router.push({
        path: '/AlarmAcceptance',
        query: {
          companyId: val,
        }
      })
    },


    // processData() {
    // console.log(this.name);
    // console.log(this.abnormalCompany);

    // // 报警
    // console.log(this.abnormalCompany.alarmCiIdSet.includes(this.name));
    // // 故障
    // console.log(this.abnormalCompany.faultCiIdSet.includes(this.name));
    // // 失联
    // console.log(this.abnormalCompany.lostInCiIdSet.includes(this.name));

    // }
  },

  beforeDestroy() {
    // 销毁物体上面的标签元素
    this.label.visible = false
  },


};
</script>

<style scoped lang="scss">
.tag {
  border-radius: 2px;
  border: solid #009999 1px;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background-color: rgba(0, 66, 66, 0.4);
  font-size: 16px;
  line-height: 25px;
  padding: 0px 5px;
  color: #00ffff;
  cursor: pointer;
  .title {
    animation: breathing 1s linear infinite alternate;
  }
}

.tag:hover {
  background-color: rgba(136, 231, 98, 0.3);
}

// .infoBox {
//   // position: absolute;
//   // top: 0;
//   width: 100%;
//   height: 100%;
//   // background: rgba(12, 12, 12, 0.4);

//   .infoBox-item{
//     width: 800px;
//     height: 600px;
//     margin: 100px auto 0;
//     border-radius: 10px;
//     border: 1px solid red;
//     background: #000;
//   }
// }

// animation: breathing 1s linear infinite alternate;
@keyframes breathing {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.alarm {
  border-radius: 2px;
  border: solid #009999 1px;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background: rgba(241, 64, 64, 0.8);
  font-size: 16px;
  line-height: 25px;
  padding: 0px 5px;
  color: #f5fafa;
  cursor: pointer;
  .title {
    animation: breathing 1s linear infinite alternate;
  }
}
.fault {
  border-radius: 2px;
  border: solid #009999 1px;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background: rgba(245, 159, 61, 0.8);
  font-size: 16px;
  line-height: 25px;
  padding: 0px 5px;
  color: #eaf3f3;
  cursor: pointer;
  .title {
    animation: breathing 1s linear infinite alternate;
  }
}
.lost {
  border-radius: 2px;
  border: solid #009999 1px;
  background-repeat: no-repeat;
  background-size: 2px 12px, 12px 2px;
  background: rgba(78, 73, 73, 0.8);
  font-size: 16px;
  line-height: 25px;
  padding: 0px 5px;
  color: #00ffff;
  cursor: pointer;
  .title {
    animation: breathing 1s linear infinite alternate;
  }
}
.tip {
  color: #008c8c;
  cursor: pointer;
}
.tip:hover {
  color: #0cd185;
}
</style>

<template>
  <div id="app">
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { readFile } from "fs";
// import imgLIstFile from '../../../assets/imgLIstFile'
export default {
  name: "App",
  data() {
    return {
      map: null,
      AMaper: null
    };
  },
  async mounted() {
    await this.initMap()
    this.loadModel()
  },
  methods: {
    async initMap() {
      try {
        this.AMaper = await AMapLoader.load({
          // 这里写你的 web key
          key: "218b76228dfb339feaf6f4e30ac6fbc9",
          plugins: ['AMap.MoveAnimation', 'AMap.GraspRoad', 'AMap.Geocoder'],
        })
        this.map = new this.AMaper.Map("container", {
          rotateEnable: true,
          pitchEnable: true,
          rotation: -15,
          viewMode: '3D', //开启3D视图,默认为关闭

          showBuildingBlock: false,
          center: [116.472605, 39.992075],
          pitch: 55,
          zoom: 17,
        });
        // 初始化光线
        this.map.AmbientLight = new this.AMaper.Lights.AmbientLight([1, 1, 1], 1);
        this.map.DirectionLight = new this.AMaper.Lights.DirectionLight([1, 0, -0.5], [1, 1, 1], 1);
      } catch (e) {
        console.log(e)
      }
    },
    loadModel() {

      const img1 = 'files/mesh/无标题/Wood_Bamboo.jpg'
      const img2 = 'files/mesh/无标题/Wood_Floor_Dark.jpg'
      const img3 = 'files/mesh/无标题/Wood_Floor_Parquet.jpg'
      const img4 = 'files/mesh/无标题/Wood_Plywood_Knots.jpg'
      const img5 = 'files/mesh/无标题/Wood_Veneer_02.jpg'
      const imgList = [img1, img2, img3, img4, img5]

      // const mashPath = 'files/mesh/module.glb'
      let mtlFil = 'files/mesh/无标题.mtl'
      let objFil = 'files/mesh/无标题.obj'
      new MTLLoader().load(mtlFil, /**贴图回调 */(materials) => {
        console.log(materials);
        // 回调中加载 obj 模型
        new OBJLoader().setMaterials(materials).load(objFil, /**加载模型的回调 */(event) => {
          console.log(event);
          var object3Dlayer = new this.AMaper.Object3DLayer();
          var meshes = event.children;
          for (var i = 0; i < meshes.length; i++) {
            var vecticesF3 = meshes[i].geometry.attributes.position;
            var vecticesNormal3 = meshes[i].geometry.attributes.normal;
            var vecticesUV2 = meshes[i].geometry.attributes.uv;
            var vectexCount = vecticesF3.count;
            var mesh = new this.AMaper.Object3D.MeshAcceptLights();
            var geometry = mesh.geometry;
            console.log(geometry);
            var c, opacity;
            var material = meshes[i].material[0] || meshes[i].material;
            console.log(material);
            // material.color = {
            //   r: 0.1,
            //   g: 0.7,
            //   b: 0.5,
            // }
            // if (material.map)
            mesh.textures.push('https://a.amap.com/jsapi_demos/static/demo-center/model/1519/1519.bmp')
            
            // mesh.textures.push(img2)
            console.log(mesh);

            c = material.color;
            opacity = material.opacity
            for (var j = 0; j < vectexCount; j += 1) {
              var s = j * 3;
              geometry.vertices.push(vecticesF3.array[s], vecticesF3.array[s + 2], -vecticesF3.array[s + 1]);
              if (vecticesNormal3) {
                geometry.vertexNormals.push(vecticesNormal3.array[s], vecticesNormal3.array[s + 2], -vecticesNormal3.array[s + 1]);
              }
              if (vecticesUV2) {
                geometry.vertexUVs.push(vecticesUV2.array[j * 2], 1 - vecticesUV2.array[j * 2 + 1]);
              }
              geometry.vertexColors.push(c.r, c.g, c.b, opacity)
            }
            mesh.DEPTH_TEST = material.depthTest
            mesh.transparent = opacity < 1;
            mesh.scale(6, 6, 6)
            mesh.rotateZ(-48)
            mesh.position(new this.AMaper.LngLat(116.472605, 39.992075))
            object3Dlayer.add(mesh)
          }
          this.map.add(object3Dlayer)
        })
      })
    }
  }
};
</script>

<style>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
}
</style>


<template>
  <div id="app">
    <div class="container">
      <!-- 三项智能网关断路器 启动关闭 -->
      <div v-if="typeId == 'threePhaseGateWayBreaker' " class="content">
        <div style="width:36%;position: fixed;right:0">
          <el-button :disabled="userRightList.updateJuris=='0'" size="small" type="primary" @click="dlqDevice('关闭')">关 闭</el-button>
          <el-button :disabled="userRightList.updateJuris=='0'" size="small" type="success" @click="dlqDevice('打开')">启 动</el-button>
        </div>
      </div>

      <!-- *************** -->
      <div v-if="typeId == 'IntelligentFireControlTerminal'" class="content1">
        <div class="device-settings">
          <div style="margin:10px 5px;width:90px;line-height:23px">设备操作：</div>
          <div style="height:50px;width:100%" v-if="deviceCommands.length!=0">
            <el-button :disabled="userRightList.updateJuris=='0'" style="margin:5px" type="success" v-for="item in deviceCommands" v-if="item.contentType!='commandData'" size="small" @click="sendCommand(item)">{{item.commandName}}</el-button>
            <el-button :disabled="userRightList.updateJuris=='0'" style="margin:5px" type="primary" v-for="item in deviceCommands" v-if="item.contentType=='commandData'" size="small" @click="sendCommand(item)">{{item.commandName}}</el-button>
          </div>
          <el-dialog :title="commandTitle" :visible.sync="showCommand" width="25%" style="top:15%;background:rgba(0,0,0,0.4)">
            <div>
              <el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
            </div>
            <div slot="footer">
              <el-button @click="showCommand = false">取 消</el-button>
              <el-button type="primary" @click="submitCommand">确 定</el-button>
            </div>
          </el-dialog>
        </div>

        <div v-for="item in deviceList4G" class="dev-box1">
          <div class="device-img1">
            <img :src="item.name.indexOf('烟感')!=-1?iconList.yangan:
                             item.name.indexOf('温感')!=-1?iconList.wengan:
                             item.name.indexOf('手报')!=-1?iconList.shoubao:
                             item.name.indexOf('声光')!=-1?iconList.shenguang:
                             item.name.indexOf('输出')!=-1?iconList.shuchu: iconList.default">
          </div>
          <div class="device-name1">
            <div>
              <span>
                {{item.name}} &nbsp;&nbsp;
              </span>
              <span>

                <el-tag :class="item.status=='启动'?'tag':''" @click="deviceStatus(item)" effect="light" size="mini" :type="deviceSta=='失联'?'info':
                        item.status=='正常'?'success':
                        item.status=='故障'?'warning':
                        item.status=='报警'||item.status=='火警'?'danger':
                        item.status=='失联'?'info':''
                        ">{{deviceSta=='失联'?deviceSta:item.status}}</el-tag>

              </span>
            </div>
            <div v-if="item.name.indexOf('输出')!=-1||item.name.indexOf('声光')!=-1">
              <el-tag v-if="userRightList.updateJuris=='1'" effect="dark" size="mini" type="success" @click="sendCommand(item.name.substring(0,2)+'设备启动')">启动</el-tag>
              <el-tag v-if="userRightList.updateJuris=='1'" effect="dark" size="mini" type="primary" @click="sendCommand(item.name.substring(0,2)+'设备停止')" style="margin:0 18px">停止</el-tag>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <!-- 除总线设备外其它设备的指令显示 -->
        <div v-if="operationCommandList.commandNoData.length!=0||operationCommandList.commandData.length!=0">
          <div class="device-settings">
            <div style="margin:10px;width:90px;line-height:23px">设备操作：</div>
            <div style="height:50px;width:100%">
              <el-button type="success" :disabled="userRightList.updateJuris=='0'" v-for="item in deviceCommands" size="small" @click="sendCommand(item)">{{item.commandName}}</el-button>
            </div>
            <el-dialog :title="commandTitle" :visible.sync="showCommand" width="25%" style="top:15%;background:rgba(0,0,0,0.4)">
              <div>
                <el-input v-model="commandData" placeholder="请输入指令内容"></el-input>
              </div>
              <div slot="footer">
                <el-button @click="showCommand = false">取 消</el-button>
                <el-button type="primary" @click="submitCommand">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </div>

        <div class="video">
          <el-button type="primary" @click="showVideo" size="mini">查看视频</el-button>
        </div>
        <div v-if="deviceDynamicVoList.length==0" class="device-info">
          <div>
            <div class="device-info-item" v-if="deviceDynamicVo.deviceStatus!=''">设备状态</div>
            <div v-if='item.indexOf("报警")==-1&&item.indexOf("故障")==-1&&item.indexOf("失联")==-1&& item.indexOf("设备状态")==-1' class="device-info-item" v-for="(item,index) in deviceDynamicVo.nameList">{{item}}</div>
          </div>
          <div>
            <div class="device-info-item" v-if="deviceDynamicVo.deviceStatus!=''">{{deviceDynamicVo.deviceStatus}}</div>
            <div v-if='deviceDynamicVo.nameList[index].indexOf("报警")==-1&&deviceDynamicVo.nameList[index].indexOf("故障")==-1&&deviceDynamicVo.nameList[index].indexOf("失联")==-1&&deviceDynamicVo.nameList[index].indexOf("设备状态")==-1' class="device-info-item" v-for="(item,index) in deviceDynamicVo.valueList">{{item}}</div>
          </div>
        </div>

        <div v-else class="content">
          <!-- <div class="video">
            <el-button type="success" @click="showVideo" size="mini">查看视频</el-button>
          </div> -->
          <br>
          <div v-for="item in deviceDynamicVo.deviceDynamicVoList" class="dev-box">
            <div class="device-img">
              <img :src="item.deviceType.indexOf('烟感')!=-1?iconList.yangan:
              item.deviceType.indexOf('压力')!=-1?iconList.yali:
              item.deviceType.indexOf('功率')!=-1?iconList.gonglv:
              item.deviceType.indexOf('电压')!=-1?iconList.dianya:
              item.deviceType.indexOf('电流')!=-1?iconList.dianliu:
              item.deviceType.indexOf('烟感')!=-1?iconList.yangan:
              item.deviceType.indexOf('信号')!=-1?iconList.xinhao:
              item.deviceType.indexOf('电池')!=-1?iconList.dianchidianya:iconList.shebei
             ">
            </div>
            <div class="device-name">
              <div :title="item.deviceId">
                ID：{{item.deviceId}}
              </div>

              <div :title="item.deviceType">
                名称：{{item.deviceType}}
              </div>

              <div :title="item.deviceStatus">
                状态：
                <el-tag :type="item.deviceStatus.indexOf('正常')!=-1||item.deviceStatus.indexOf('无')!=-1||item.deviceStatus.indexOf('自动解除')!=-1?'success':
                  item.deviceStatus.indexOf('故障')!=-1?'danger':
                  item.deviceStatus.indexOf('失联')!=-1?'info':''" size="mini">{{item.deviceStatus}}</el-tag>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block" v-if="total>5">
        <!-- <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" class="pagination"></el-pagination> -->
        <el-pagination :current-page.sync="current" :page-size="limit" :total="total" background layout="prev, pager, next" @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import Command from '@/api/mapBuidingApi/Command'

import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import homePage from '@/api/manageApi/HomePage'
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
export default {
  props: ['typeId', 'deviceId', 'deviceSta'],
  data() {
    return {
      deviceCommands: [],  // 设备指令
      deviceCommand: {},//设备指令信息
      command: {},
      commandData: '',
      commandTitle: '',
      showCommand: false,       // 有数据的指令下发
      timer: null,
      dynamicProperties4G: [],
      deviceList4G: [
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', },
        { name: '', status: '', }],

      current: 1, // 当前页
      limit: 4, // 条数
      total: 0,
      deviceInfo: [],
      // 心跳周期
      //   cycleValue: 50,
      aa: 1,// 心跳周期只执行一次

      iconList: {
        yangan: require("../../assets/img/icon/yangan.png"),
        wengan: require("../../assets/img/icon/wengan.png"),
        shoubao: require("../../assets/img/icon/shoubao.png"),
        shenguang: require("../../assets/img/icon/shenguang.png"),
        shuchu: require("../../assets/img/icon/shuchu.png"),
        default: require("../../assets/img/icon/default.png"),
        yali: require("../../assets/img/icon1/yali.png"),
        xinhao: require("../../assets/img/icon1/xinhao.png"),
        shebei: require("../../assets/img/icon1/shebei.png"),
        jingai: require("../../assets/img/icon1/jingai1.png"),
        gonglv: require("../../assets/img/icon1/gonglv.png"),
        dianya: require("../../assets/img/icon1/dianya.png"),
        dianliu: require("../../assets/img/icon1/dianliu.png"),
        dianchidianya: require("../../assets/img/icon1/dianchidianya.png"),

      },

      //   ************
      deviceDynamicVoList: [],
      deviceDynamicVo: [],
      deviceInfoList: [],
      deviceInfoList1: [],

      obj: {},

      userRightList: [],

    }
  },

  created() {
    this.getUsers()
    console.log(this.typeId);

    if (this.typeId == 'IntelligentFireControlTerminal') {
      this.getDeviceInfo4G();
      this.timer = setInterval(() => {
        this.getDeviceInfo4G();
      }, 3000)
    } else {
      this.getDeviceInfo();
    }
    this.getDeviceCommand();
  },
  methods: {
    //获取该设备类型所有的指令,进行处理显示
    async getDeviceCommand() {
      const { data: res } = await Command.getDeviceCommand(this.typeId)
      this.deviceCommands = res.data.deviceCommands
    },
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "show") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    // 有数据的指令下发
    submitCommand() {
      if (this.commandData == '') {
        this.$message.warning("指令内容不能为空！")
      } else {
        this.deviceCommand.paramValue = this.commandData
        this.command['deviceId'] = this.deviceId;
        this.command['deviceCommand'] = this.deviceCommand
        this.showCommand = false;
        this.commandSend(this.command)
        this.commandData = '';
      }
    },

    //   指令下发
    async sendCommand(item) {
      this.deviceCommand = item
      this.commandTitle = item.commandName;
      this.command['deviceId'] = this.deviceId;
      if (this.typeId == "IntelligentFireControlTerminal" && typeof item == "string" && item.constructor == String) {
        let obj = {}
        obj.contentType = "commandNoData"
        obj.paramValue = item
        this.command['deviceCommand'] = obj
      } else {
        this.command['deviceCommand'] = item
      }
      if (item.contentType == "commandData") {
        this.showCommand = true;
      } else {
        this.commandSend(this.command)
      }
    },

    async commandSend(command) {
      const { data: res } = await Command.sendCommand(command);
      if (res.message == "指令发送成功") {
        this.$message({
          message: '指令发送成功！',
          type: 'success'
        })
      } else if (res.message == "无此设备的指令下发权限") {
        this.$message({
          message: '无此设备的指令下发权限',
          type: 'error'
        })
      }
    },

    //视频
    async showVideo() {
      const { data: res } = await homePage.getVideoUrl(this.deviceId)
      if (res.data.VideoUrl == null || res.data.VideoUrl == "") {
        this.$message({
          type: "error",
          message: "该设备未绑定任何摄像头！",
        });
      } else {
        window.location.href = res.data.VideoUrl;
      }
    },

    // 三相智能网关断路器
    async dlqDevice(item) {
      if (item == '打开') {
        const { data: res } = await HMCat1Controller.dlqDevice(item, this.deviceName.deviceId);
      } else if (item == '关闭') {
        const { data: res } = await HMCat1Controller.dlqDevice(item, this.deviceName.deviceId);
      }
    },

    // ***********************************************
    //   根据设备id查设备动态属性
    async getDeviceInfo4G() {
      const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceId)
      this.dynamicProperties4G = res.data.list;

      if (this.typeId == "IntelligentFireControlTerminal") {
        for (let i = 0; i < this.dynamicProperties4G.length; i++) {
          let num = Number.parseInt(this.dynamicProperties4G[i]["id"].substring(0, 1));
          num--;
          if (this.dynamicProperties4G[i]["id"].indexOf("设备类型") != -1) {
            this.deviceList4G[num].name = (++num) + "号" + this.dynamicProperties4G[i]["value"]
          }
          if (this.dynamicProperties4G[i]["id"].indexOf('报警状态') != -1) {
            this.deviceList4G[num].status = "火警";
          } else if (this.dynamicProperties4G[i]["id"].indexOf('故障状态') != -1) {
            this.deviceList4G[num].status = "故障";
          } else if (this.dynamicProperties4G[i]["id"].indexOf('设备状态') != -1) {
            if (this.dynamicProperties4G[i]["value"].indexOf("解除") != -1 ||
              this.dynamicProperties4G[i]["value"].indexOf("正常") != -1) {
              this.deviceList4G[num].status = "正常"
            } else if (this.dynamicProperties4G[i]["value"].indexOf("警") != -1) {
              this.deviceList4G[num].status = "火警"
            } else if (this.dynamicProperties4G[i]["value"].indexOf("故障") != -1) {
              this.deviceList4G[num].status = "故障"
            } else if (this.dynamicProperties4G[i]["value"].indexOf("暂无") != -1) {
              this.deviceList4G[num].status = "暂无"
            }
          }
        }
      }
    },

    // 分页
    handleCurrentChange() {
      this.getDeviceInfo()
    },


    getDeviceInfo() {
      //根据设备id查询设备下的设备并返回设备信息
      deviceInfoManagement.getDeviceInfoByDeviceId(this.deviceId, this.current, this.limit).then(res => {
        this.total = res.data.data.total;
        //data内写一个对象接收res.data.data.deviceDynamicVo,暂用let定义显示
        this.deviceDynamicVo = JSON.parse(JSON.stringify(res.data.data.deviceDynamicVo))
        // let deviceStatus = ""//设备状态（或为主机）
        // let deviceValue = ""//设备值等（或为主机）
        this.deviceDynamicVoList = this.deviceDynamicVo["deviceDynamicVoList"]
        // let objNameList = [];
        if (this.deviceDynamicVoList.length != 0) {
          for (const i in this.deviceDynamicVoList) {
            this.deviceDynamicVoList[i]["deviceStatus"] = this.solveData(this.deviceDynamicVoList[i]["nameList"], this.deviceDynamicVoList[i]["valueList"])
          }
        }
        //其它类型设备走下面正常处理
        this.deviceDynamicVo["deviceStatus"] = this.solveData(this.deviceDynamicVo["nameList"], this.deviceDynamicVo["valueList"])


        for (let i = 0; i < this.deviceDynamicVo.nameList.length; i++) {
          let obj = {};
          obj.name = this.deviceDynamicVo.nameList[i];
          obj.value = this.deviceDynamicVo.valueList[i];
          this.deviceInfoList.push(obj);
        }
      })
    },

    // 普通设备处理
    solveData(objNameList, objValueList) {
      let isShebei = "";
      let deviceStatus = "正常"
      for (const i in objNameList) {
        if (objNameList[i] == "设备状态") {
          isShebei = objValueList[i]
        }
        if (objNameList[i].indexOf("状态") != -1) {
          if (objValueList[i] != "正常" &&
            objValueList[i] != "" &&
            objValueList[i] != null) {
            deviceStatus = objValueList[i]
          }
        }
        if (objNameList[i].indexOf("失联") == -1 &&
          objNameList[i].indexOf("报警") == -1 &&
          objNameList[i].indexOf("故障") == -1 &&
          (objValueList[i] == "" || objValueList[i] == null)) {
          objValueList[i] = "暂无"
        }
      }
      if (isShebei != "") {
        deviceStatus = isShebei;
      }
      return deviceStatus;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

}
</script>

<style lang="scss" scoped>
.container {
  scrollbar-width: none;
  width: 100%;
  height: 350px;
  overflow: scroll;
}
.video {
  position: absolute;
  top: 50px;
  // top: 100px;
  right: 20px;
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .dev-box {
    display: flex;
    width: 46%;
    cursor: pointer;
    box-sizing: border-box;
    height: 120px;
    margin: 10px;
    padding: 15px 5px;

    box-shadow: 0 2px 4px rgba(116, 222, 230, 0.3);

    .device-img img {
      margin: 5px;
      width: 80px;
      height: 80px;
    }
    .device-name {
      margin-left: 10px;
      div {
        width: 95%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 30px;
      }
      .tag {
        cursor: pointer;
      }
    }
  }
}

.content1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .dev-box1 {
    display: flex;
    width: 30%;
    cursor: pointer;
    box-sizing: border-box;
    // height: 90px;
    margin: 20px 12px;
    padding: 15px 5px;
    box-shadow: 0 2px 4px rgba(116, 222, 230, 0.3);

    .device-img1 img {
      width: 60px;
      height: 60px;
    }
    .device-name1 {
      margin-left: 20px;
      div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 30px;
      }
      .tag {
        cursor: pointer;
      }
    }
  }
}

.device-settings1 {
  height: 40px;
}
.device-settings {
  width: 100%;

  display: flex;
}

::-webkit-scrollbar {
  display: none;
}
.device-info {
  display: flex;
  .device-info-item {
    width: 260px;
    height: 30px;
    line-height: 30px;
    margin: 0 10px;
  }
}
.block {
  width: 95%;
  height: 60px;
  position: absolute;
  bottom: 25px;
  background: none;
}
/deep/.el-dialog__title {
  font-weight: 600;
  color: rgb(2, 73, 50);
}
/deep/ .el-dialog__title::before {
  content: "丨";
  font-weight: 600;
  color: rgb(2, 73, 50);
}
</style>
<template>
	<el-container class="home-container visual-base">

		<el-header>
			<div>
				<audio id="audio" loop="loop" :src="thisAudioUrl" />
			</div>
			<el-menu :router="true" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
				background-color="#000" text-color="#fff" active-text-color="#ffd04b">

				<el-menu-item v-for="item in menuList" :index="item.index + ''" v-if="item.type == tempRoute">
					<div>{{ item.label }}</div>
				</el-menu-item>

				<div class="time">{{ time }}</div>
				<div class="el-icon-bell volume" v-if="startaudio != -1" @click="initPlayaudio"></div>
				<div class="el-icon-close-notification volume1" v-if="startaudio == -1" @click="initPlayaudio"></div>
				<div class="el-icon-menu goMenu" @click="goMenu"></div>
			</el-menu>
		</el-header>

		<el-main id="temp">
			<!-- 路由出口 -->
			<router-view ref="childFunction" @routerIsHistoryFunction="routerIsHistoryFunction"
				@routerIsIndexFunction="routerIsIndexFunction"
				@routerIsmapBuidingHomeTFFunction="routerIsmapBuidingHomeTFFunction"
				@routerIsShowFunction="routerIsShowFunction"></router-view>
		</el-main>
	</el-container>
</template>

<script>
	import AccountManage from '@/api/manageApi/AccountManage'

	import getTime from "../../utils/time.js";
	import dataStorage from "@/../utils/dataStorage.js";

	import history from "@/views/history/History";
	import show from "@/views/Show";
	import BaseData from "@/assets/config/BaseData.js";
	import crtDeviceCoordinate from "../api/manageApi/CrtDeviceCoordinate";
	import user from "@/api/manageApi/User";

	import Bus from '@/components/three/HTMLTag/bus'
	export default {
		name: "Home",
		components: {
			history,
			show
		},
		created() {
			this.jurisProcess()

			this.thisAudioUrl = BaseData.baseUrl + "crtPicture/fault.mp3";
			window.toCRT = this.toCRT;
			window.showVideo = this.showVideo;

			if (this.$route.params.msg == "主页") {
				this.$router.push({
					path: "/Show"
				});
			}

			var arr = this.$route.path.split("/");
			this.activeIndex = arr[1];
			if (this.$route.params.msg != null) {
				this.tempRoute = this.$route.params.msg;
			} else {
				for (var item of this.menuList) {
					if ("/" + item.index == this.$route.path) {
						this.tempRoute = item.type;
						break;
					}
				}
			}
			this.timeTable();
		},
		watch: {
			socketMsg(newVal, oldVal) {
				if (newVal) {
					Bus.$emit('socket', newVal)
				}
			},

			startaudio: function() {
				if (this.startaudio != -1) {
					if (this.startaudio == 1) {
						this.playaudio();
					} else {
						this.stopAudio();
					}
				}
			},
			$route(to, from) {
				if (to.params.msg != null) {
					this.tempRoute = to.params.msg;
				} else {
					for (var item of this.menuList) {
						if ("/" + item.index == to.path) {
							this.tempRoute = item.type;
							break;
						}
					}
				}
			},
		},
		data() {
			return {
				users: '',
				time: "yyyy-MM-dd HH:mm:ss",
				thisAudioUrl: "",
				// 如果是客户端版本的 这需要为-1 交互才能播放
				// startaudio:-1,
				// 如果是网页版本的 这需要为0 不交互自动播放
				startaudio: 0,
				tempRoute: "",
				activeIndex: "",
				platformInfo: [],
				menuList: [{
						label: "设备管理",
						index: "DeviceInfoManagement",
						type: "我的设备",
					},
					{
						label: "设备类型管理",
						index: "DeviceTypeManagement",
						type: "我的设备",
					},
					// 在 timeTable 方法里做了判断，，如果是 admin 账号  显示 设备类型分类
					{
						label: "设备类型分类",
						index: "ClassificationOfEquipment",
						type: "我的设备",
					},
					{
						label: "设备联动关系",
						index: "DeviceRelationManagement",
						type: "我的设备",
					},
					{
						label: "二维码管理",
						index: "ErCodeManage",
						type: "我的设备",
					},
					{
						label: "用传设备编码管理",
						index: "YcDeviceTypeManage",
						type: "我的设备",
					},
					{
						label: "库存管理",
						index: "MangeDeviceCount",
						type: "库存管理",
					},
					{
						label: "公司设备展示",
						index: "CompanyEq",
						type: "我的设备",
					},
					{
						label: "CRT点位展示",
						index: "CRTShow",
						type: "CRT管理"
					},
					{
						label: "CRT图片管理",
						index: "CRTManagement",
						type: "CRT管理",
					},
					{
						label: "一键报警",
						index: "AKeyAlarm",
						type: "一键报警",
					},
					{
						label: "CRT点位管理",
						index: "CRTCoordinate",
						type: "CRT管理",
					},
					// {
					//   label: "主页CRT点位管理",
					//   index: "indexCRTCoordinate",
					//   type: "CRT管理",
					// },
					{
						label: "报警历史记录",
						index: "History",
						type: "系统日志",
					},
					// {
					//   label: "告警受理",
					//   index: "AlarmAcceptance",
					//   type: "历史记录",
					// },
					// 操作日志
					{
						label: "历史记录",
						index: "HistoryValue",
						type: "系统日志",
					},
					{
						label: "操作日志",
						index: "OperationLog",
						type: "系统日志",
					},

					{
						label: "查看信息",
						index: "MapBuiding",
						type: "数据可视化"
					},
					{
						label: "创建建筑物",
						index: "SelectLoLa",
						type: "数据可视化",
					},
					{
						label: "订阅管理",
						index: "SubscribeManagement",
						type: "订阅管理",
					},
					// 权限管理
					{
						label: " 权限管理",
						index: "JurisManagement",
						type: "权限管理",
					},
					// 通知管理
					{
						label: " 通知管理",
						index: "NotificationManagement",
						type: "权限管理",
					},
					// 账号管理
					{
						label: " 账号管理",
						index: "AccountManagement",
						type: "权限管理",
					},


					// 设备设施管理
					{
						label: "设备信息",
						index: "DeviceInfo",
						type: "设备设施管理",
					},
					{
						label: "设备类型",
						index: "DeviceType",
						type: "设备设施管理",
					},
					{
						label: "异常设备",
						index: "AbnormalDevice",
						type: "设备设施管理",
					},
					{
						label: "检修记录",
						index: "MaintenanceRecord",
						type: "设备设施管理",
					},
					{
						label: "通知记录",
						index: "NotifyRecord",
						type: "设备设施管理",
					},
					{
						label: "报废设备",
						index: "ScrapDevice",
						type: "设备设施管理",
					},
					{
						label: "访客出入",
						index: "VisitorsAccess",
						type: "访客记录",
					},
					{
						label: "申请记录",
						index: "VisitorsToRecord",
						type: "访客记录",
					},
					{
						label: "巡检巡查记录",
						index: "InspectionRecord",
						type: "巡检巡查记录",
					},
					{
						label: "移动端用户管理",
						index: "MoveManagement",
						type: "移动端用户管理",
					},
					{
						label: "有限空间任务信息",
						index: "WorkPlace",
						type: "有限空间",
					},
					{
						label: "用水监控",
						index: "WaterElectricityDisplayShow",
						type: "用水监控",
					},
					// {
					//   label: "水电展示",
					//   index: "WaterAndElectricityDisplay",
					//   type: "水电展示",
					// },
					// {
					//   label: "执法仪记录",
					//   index: "VideoRecord",
					//   type: "有限空间",
					// }
				],
				//看当前界面是否显示的是地图
				routerIsmapBuidingHome: false,
				show: true,
				timer: null,
				routeIndexHome: false,
				routerIsHistory: false,
				routerIsIndex: false,
				routerIsShow: false,

				path: "ws://" +
					BaseData.serverIP +
					":" +
					BaseData.serverPort +
					"/websocket/" +
					dataStorage.getBaseData("socketUserName"),

				activeDeviceId: '',

				//一键报警弹窗跳转传id高亮显示对应行
				aKeyAlarmId: '',
				//二维码报警弹窗跳转传id高亮显示对应行
				scanCodeId: '',
				//二维码报警跳转路由名称
				scanCodeRouterName: '',
				//任务开始/头盔报警弹窗跳转传id高亮显示对应行
				jobNumberId: '',


				// socket 数据
				socketMsg: '',
			};
		},
		beforeDestroy() {
			this.closeWebsocket();
		},


		methods: {
			// 处理权限页面展示
			jurisProcess() {
				let arr = []
				for (const item of this.menuList) {
					if (item.type == '权限管理') {
						arr.push(item)
					}
				}
			},
			timeTable() {
				let timeObj = getTime.getTime();
				this.time = timeObj.dayTime();
				let ils = 0;
				this.timer = setInterval(() => {
					ils++;
					this.time = timeObj.dayTime();
				}, 1000);

				user.loginUserInfo().then((res) => {
					this.users = res.data.data.userName;
					// console.log(this.users);
					// if (this.users == "admin") {
					//   let obj = {
					//     label: "设备类型分类",
					//     index: "ClassificationOfEquipment",
					//     type: "我的设备",
					//   }
					//   this.menuList.push(obj)
					// }
					// this.getTitleText()
				});
			},
			// getTitleText() {
			//   AccountManage.getShowsByUserName(this.users).then(res => {
			//     this.platformInfo = res.data.data.data
			//   })
			// },

			initPlayaudio() {
				if (this.startaudio == -1) {
					var audio = document.getElementById("audio");
					audio.play(); //播放一段音频
					audio.pause(); //暂停一段音频
					this.startaudio = 0;
				} else {
					this.startaudio = -1;
					var audio = document.getElementById("audio");
					audio.pause(); //暂停一段音频
				}
			},
			playaudio() {
				var audio = document.getElementById("audio");
				audio.play(); //播放一段音频
				//audio.pause()  //暂停一段音频
			},
			stopAudio() {
				var audio = document.getElementById("audio");
				audio.pause(); //暂停一段音频
			},
			startplay() {
				if (this.startaudio != -1) {
					this.startaudio = 1;
					if (this.startaudio == 1) {} else {
						this.startaudio = 1; //改变值
					}
				}
			},
			stopPlay() {
				if (this.startaudio != -1) {
					if (this.startaudio == 0) {} else {
						this.startaudio = 0; //改变值
					}
				}
			},
			goMenu() {
				this.$router.push("/Select");
			},
			closeWebsocket() {
				this.socket.close();
			},

			goSelect() {
				// window.history.back();
				this.$router.push({
					path: "/Select"
				});
			},

			init() {
				if (typeof WebSocket === "undefined") {
					alert("您的浏览器不支持socket");
				} else {
					// 实例化socket
					this.socket = new WebSocket(this.path);
					// 监听socket连接
					this.socket.onopen = this.open;
					// 监听socket错误信息
					this.socket.onerror = this.error;
					// 监听socket消息
					this.socket.onmessage = this.getMessage;

					// this.$refs.childFunction.dataHandlerIndex();
				}
			},

			routerIsmapBuidingHomeTFFunction(tf) {
				this.routerIsmapBuidingHome = tf;
			},
			routerIsHistoryFunction(tf) {
				this.routerIsHistory = tf;
			},

			routerIsIndexFunction(tf) {
				this.routerIsIndex = tf;
			},
			//判断是否在Show页面
			routerIsShowFunction(data) {
				this.routerIsShow = data;
			},
			open() {
				console.log("socket连接成功");
			},
			error() {
				console.log("连接失败");
				this.socket = new WebSocket(this.path);
				// 监听socket连接
				this.socket.onopen = this.open;
				// 监听socket错误信息
				this.socket.onerror = this.error;
				// 监听socket消息
				this.socket.onmessage = this.getMessage;
			},
			getMessage(msg) {
				console.log("收到的消息===============");
				this.socketMsg = msg
				console.log(this.socketMsg);

				var jsonMsg = JSON.parse(msg.data);

				//指令下发反馈提示
				if (jsonMsg.orderMsg != undefined && jsonMsg.orderMsg != "" && jsonMsg.orderMsg != null) {
					if (jsonMsg.orderMsg.indexOf('操作成功') != -1) {
						jsonMsg.status = true
					} else {
						jsonMsg.status = false
					}
					this.commandMessage(jsonMsg);
					// if (jsonMsg.orderMsg.indexOf('操作成功') != -1) {
					//   this.$message.success(jsonMsg.orderMsg)
					// } else {
					//   this.$message.error(jsonMsg.orderMsg)
					// }
				} else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'aKeyAlarm') {
					//一键报警弹窗
					this.aKeyAlarmFrame(jsonMsg);
				} else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'scanCode') {
					//扫描二维码报警弹窗
					this.scanCodeRouterName = jsonMsg.routerName //用于判断跳转路径
					this.scanCodeFrame(jsonMsg);
				} else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'workStart') {
					//任务开始通知
					this.workStartFrame(jsonMsg);
				} else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'helmetAKey') {
					//头盔报警弹窗
					this.helmetAKeyFrame(jsonMsg);
				} else if (jsonMsg.type != undefined && jsonMsg.type != "" && jsonMsg.type == 'helmetAKey') {
					//任务结束通知
					this.workFinishFrame(jsonMsg);

				} else if (jsonMsg.type == "normalDevice") {
					//接收设备正常数据
					//改变主页点位状态显示
					jsonMsg.value = "正常"
					this.changeShow(jsonMsg)
				} else {

					// 大连美宝需要放开下面的 if ！！！

					if (this.users == 'admin' || (jsonMsg.value.indexOf('故障') == -1 && jsonMsg.value.indexOf('失联') == -1 &&
							jsonMsg.value.indexOf('防拆') == -1 && jsonMsg.value.indexOf('反馈') == -1)) {
						// 此方法为弹框
						this.springFrame(jsonMsg);
						// 此方法为地图界面的跳转报警数据push
						this.pushMapBuidingAlamDate(jsonMsg);
						//报警失联故障刷新历史记录页面
						this.refreshHistory(jsonMsg);
						//报警失联故障如果再首页   高亮显示crt
						this.blingblingIndexCRTIcon(jsonMsg);
						//改变主页点位状态显示
						this.changeShow(jsonMsg)
						}

					}
				},
				//改变主页点位状态显示
				changeShow(data) {
						if (this.routerIsShow) {
							this.$refs.childFunction.changeDeviceStatus(data)
						}
					},
					send() {
						this.socket.send("params");
					},
					close() {
						console.log("socket已经关闭");
					},
					//生成指令消息弹框
					commandMessage(jsonMsg) {
						let message = jsonMsg.orderMsg
						// 指令下发信息弹框
						if (jsonMsg.status) {
							this.$notify.success({
								title: "指令下发",
								dangerouslyUseHTMLString: true,
								message: message,
								duration: 0,
								position: "bottom-right",
								onClick: null,
								onClose: null,
							});
						} else {
							this.$notify.error({
								title: "指令下发",
								dangerouslyUseHTMLString: true,
								message: message,
								duration: 0,
								position: "bottom-right",
								onClick: null,
								onClose: null,
							});
						}
					},
					//一键报警弹窗
					aKeyAlarmFrame(msg) {
						this.aKeyAlarmId = msg.id
						this.$notify({
							title: "一键报警",
							dangerouslyUseHTMLString: true,
							customClass: "notify-warning",
							message: `<h4 style='color:#000'>${msg.message}</h4>`,
							duration: 0,
							iconClass: "el-icon-warning",
							position: "bottom-right",
							onClick: this.aKeyAlarmJump,
							onClose: null,
						});
					},
					//二维码报警弹窗
					scanCodeFrame(msg) {
						this.scanCodeId = msg.id
						this.$notify({
							title: msg.routerName,
							dangerouslyUseHTMLString: true,
							customClass: "notify-warning",
							message: `<h4 style='color:#000'>${msg.message}</h4>`,
							duration: 0,
							iconClass: "el-icon-warning",
							position: "bottom-right",
							onClick: this.scanCodeJump,
							onClose: null,
						});
					},
					//任务开始弹窗
					workStartFrame(msg) {
						this.jobNumberId = msg.id
						this.$notify({
							title: "任务开始通知",
							dangerouslyUseHTMLString: true,
							customClass: "notify-info",
							message: `<h4 style='color:#000' >工单${msg.jobNumber}开始任务，位置在${msg.location}</h4>`,
							duration: 0,
							iconClass: "el-icon-info",
							position: "bottom-right",
							onClick: this.workPlaceJump,
							onClose: null,
						});

					},
					//头盔按键报警弹窗
					helmetAKeyFrame(msg) {
						this.jobNumberId = msg.id
						this.$notify({
							title: "头盔按键报警",
							dangerouslyUseHTMLString: true,
							customClass: "notify-warning",
							message: `<h4 style='color:#000'>${msg.message}</h4>`,
							duration: 0,
							iconClass: "el-icon-warning",
							position: "bottom-right",
							onClick: this.workPlaceJump,
							onClose: null,
						});
					},
					//任务开始弹窗
					workFinishFrame(msg) {
						this.jobNumberId = msg.id
						this.$notify({
							title: "任务结束通知",
							dangerouslyUseHTMLString: true,
							customClass: "notify-info",
							message: `<h4 style='color:#000' >工单${msg.jobNumber}完成任务，位置在${msg.location}</h4>`,
							duration: 0,
							iconClass: "el-icon-info",
							position: "bottom-right",
							onClick: this.workPlaceJump,
							onClose: null,
						});
					},
					springFrame(msg) {
						let message =
							"【" +
							msg.typeName +
							"-" +
							msg.deviceId +
							"】发生了----------" +
							msg.value;

						if (msg.videoUrl == "") {} else {
							if (msg.value.indexOf("警") != -1) {
								this.showVideo(msg.videoUrl);
							}
						}
						this.getForFrame({
							deviceId: msg.deviceId,
							title: msg.type,
							message: message,
							videoUrl: msg.videoUrl,
							typeObject: msg.value.indexOf("警") != -1 ?
								{
									className: "notify-warning",
									icon: "el-icon-warning",
									color: "#000",
								} :
								msg.value.indexOf("故障") != -1 ?
								{
									className: "notify-fault",
									icon: "el-icon-info",
									color: "#000",
								} :
								{},
						});
					},

					showVideo(videoUrl) {
						if (videoUrl) {
							window.location.href = videoUrl;
						} else {
							this.$message({
								type: "error",
								message: "该设备未绑定任何摄像头！",
							});
						}
					},

					// 只有当为地图界面的时候才会执行 否则报错 回头 弄下那个通知 只有当前是地图界面才会 执行这个方法
					pushMapBuidingAlamDate(jsonStr) {
						if (this.routerIsmapBuidingHome) {
							this.$refs.childFunction.dataHandler(jsonStr);

							// this.$refs.childFunction.dataHandler(
							//   JSON.parse(
							//     '{"CRTId":"774877487748666","deviceId":"774877487748666","报警":"报警"}'
							//   )
							// );
						}
					},
					blingblingIndexCRTIcon(jsonMsg) {
						if (
							jsonMsg.value.indexOf("警") > -1 ||
							jsonMsg.value.indexOf("失联") > -1 ||
							jsonMsg.value.indexOf("故障") > -1
						) {
							if (this.routerIsIndex) {
								this.$refs.childFunction.dataHandlerIndex(jsonMsg);
							}
						}
					},

					//刷新历史记录页面
					refreshHistory(jsonMsg) {
						if (
							jsonMsg.value.indexOf("警") > -1 ||
							jsonMsg.value.indexOf("失联") > -1 ||
							jsonMsg.value.indexOf("故障") > -1
						) {
							if (this.routerIsHistory) {
								// this.$refs.childFunction.dataHandlerHistory();
							}
						}
					},
					getForFrame(msg) {
						var msgString = "<h4 style='color:" +
							msg.typeObject.color +
							"'>" +
							msg.message +
							"</h4><button onclick='toCRT(\"" +
							msg.deviceId +
							"\")'>CRT</button>" +
							(msg.videoUrl == "" ?
								"" :
								"<button style='margin-left:10px' onclick='showVideo(\"" +
								msg.videoUrl +
								"\")'>查看视频</button>");
						// 报警信息弹框
						this.activeDeviceId = msg.deviceId
						this.$notify({
							title: msg.title,
							dangerouslyUseHTMLString: true,
							customClass: msg.typeObject.className,
							message: msgString,
							duration: 0,
							position: "bottom-right",
							iconClass: msg.typeObject.icon,
							onClick: this.jump,
							onClose: this.closeNotify,
						});

						this.startplay();
					},
					toCRT(deviceId) {
						crtDeviceCoordinate.getCoordinateByDeviceId(deviceId).then((res) => {

							if (res.data.data.data == undefined) {
								this.$message({
									type: "warning",
									message: "该设备尚未添加点位！",
								});
							} else if (res.data.data.data.id) {
								this.$router.push({
									name: "CRTShow",
									params: {
										CRTId: res.data.data.data.crtPictureId,
										deviceId: res.data.data.data.deviceInfoId,
									},
								});
							} else {
								this.$message({
									type: "warning",
									message: "该设备尚未添加点位！",
								});
							}
						});
						this.stopPlay();
					},
					jump() {
						//跳转到状态历史记录(告警受理)页面
						this.$router.replace({
							path: "/AlarmAcceptance",
							query: {
								deviceId: this.activeDeviceId,
								randomId: Math.floor((Math.random() * 10000) + 1)
							}
						}).catch(err => err)
						this.stopPlay();
					},
					aKeyAlarmJump() {
						this.$router.replace({
							path: "/AKeyAlarm",
							query: {
								id: this.aKeyAlarmId,
								randomId: Math.floor((Math.random() * 10000) + 1)
							}
						}).catch(err => err)
					},
					workPlaceJump() {
						this.$router.replace({
							path: "/WorkPlace",
							query: {
								id: this.jobNumberId,
								randomId: Math.floor((Math.random() * 10000) + 1)
							}
						}).catch(err => err)
					},
					//二维码报警弹框点击跳转
					scanCodeJump() {
						if (this.scanCodeRouterName == "火警确认") {
							this.$router.replace({
								path: "/AlarmAcceptance",
								query: {
									id: this.scanCodeId,
									randomId: Math.floor((Math.random() * 10000) + 1)
								}
							}).catch(err => err)
						} else {
							this.$router.replace({
								path: "/AKeyAlarm",
								query: {
									id: this.scanCodeId,
									randomId: Math.floor((Math.random() * 10000) + 1)
								}
							}).catch(err => err)
						}
					},

					closeNotify() {
						this.stopPlay();
					},
			},
			mounted() {
				// 初始化socket
				this.init();
			},

			destroyed() {
				clearInterval(this.timer);
				this.socket.onclose = this.close;
			},
		};
</script>

<style lang="scss" scoped>
	#temp {
		padding: 0px;
		overflow: hidden;
	}

	.home-container {
		height: 100vh;
	}

	.el-main {
		flex-basis: 0px;
	}

	.notify-warning {
		background: rgba(177, 58, 60, 0.979) !important;
		box-shadow: 3px 3px 2px rgb(235, 106, 106) !important;
	}

	.el-icon-warning {
		color: #fe2e2e !important;
	}

	.notify-fault {
		background: rgba(238, 217, 33, 0.5) !important;
		box-shadow: 3px 3px 2px rgb(208, 238, 138) !important;
	}

	.el-icon-info {
		color: #f3f781 !important;
	}

	.v-modal {
		display: none;
	}

	.goMenu {
		font-size: 22px;
		display: inline-block;
		line-height: 70px;
		position: absolute;
		right: 20px;
	}

	.goMenu:hover {
		color: #f3f781;
	}

	.volume {
		font-size: 22px;
		display: inline-block;
		line-height: 70px;
		position: absolute;
		right: 60px;
	}

	.volume1 {
		font-size: 22px;
		display: inline-block;
		line-height: 70px;
		position: absolute;
		right: 60px;
	}

	.time {
		font-size: 19px;
		position: absolute;
		right: 100px;
		line-height: 70px;
		cursor: pointer;
	}

	.time:hover {
		color: palegoldenrod;
	}

	.user {
		position: absolute;
		right: 22%;
		line-height: 60px;
		font-size: 18px;
		font-weight: 500;
	}

	// *******************

	.el-header {
		height: 70px !important;
		padding: 0;
		background: #fff;

		.el-menu-item {
			// background: #b4b4ca !important;

			height: 70px;
			font-size: 16px;
			line-height: 70px;
		}
	}

	.notify {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 99999;
		width: 400px;
		height: 300px;
		// background: rgb(233, 149, 149);
	}
</style>
<template>
  <div class="cityGreenLand-charts" id="device"></div>
</template>

<script>
import "echarts-gl";
import deviceCachValue from "../../api/manageApi/DeviceCachValue";
export default {
  props: ["alarmList"],
  data() {
    return {
      // allNum,
    };
  },
  created() {
    console.log(this.alarmList);

    this.drawPie();


  },
  methods: {
    drawPie() {
      deviceCachValue.getDevicenumber().then((res) => {
        let pieData = [
          {
            name: "报警 ：",
            value: res.data.data.baojin,
          }, {
            name: "失联 ：",
            value: res.data.data.shilian,
          }, {
            name: "故障 ：",
            value: res.data.data.guzhang,
          },
        ];

        let myChart = this.$echarts.init(document.getElementById("device"));
        let option;

        option = {
          title: {
            left: "center",
            font: "#fff",
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            // orient: "vertical",
            left: "10%",
            top: "10%",
            textStyle: {
              color: "#fff", // 图例文字颜色
              fontSize: "16",
            },
          },
          color: ['#e74c3c', '#4bcffa', '#FFC851'],
          series: [
            {
              type: "pie",
              radius: "55%",
              center: ["55%", "60%"],
              data: pieData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                normal: {
                  textStyle: {
                    fontSize: 14, //文字的字体大小
                  },
                  formatter: "{d}%",
                },
              },
            },
          ],
        };

        option && myChart.setOption(option);
      });
    },
  },
  created() { },
  mounted() {
    // this.init();
    this.drawPie();
  },
};
</script>
<style lang="less" scoped>
.cityGreenLand-charts {
  //   height: 90%;
  height: 250px;
  width: 90%;
  padding-left: 0px;
}
</style>